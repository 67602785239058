import { gql } from "@apollo/client";

export const GET_VEHICLE = gql`
  query Vehicle($id: String!) {
    vehicle(id: $id) {
      isGhostVehicle
      insurerCode
      airConditioner
      assetTag
      brakeHorsePower
      bluetooth
      bodyType
      vehicleUniqueCode
      vehicleUniqueId
      co2Emissions
      isCarShare
      startDate
      colour
      cylinderCapacity
      description
      fuelType
      imported
      infoSource
      insuranceGroup
      licencePlate
      v5c
      ukVehicle
      vin
      make
      model
      manufactureDate
      year
      registrationDate
      refrigeratorHours
      isRefrigerator
      vehicleTest
      vehicleTestDetails
      vehicleTestDueDate
      vehicleTaxAmount
      numberOfDoors
      numberOfPreviousOwners
      numberOfSeats
      premium
      price
      msrp
      grossVehicleWeight
      satNav
      sixMonthRate
      status
      steering
      taxDetails
      taxed
      taxStatus
      telemetryDeviceId
      teltonikaDeviceId
      telemetryDevicesData {
        status
        medium
        disabledAt
        enabledAt
        enabledBy
        disabledBy
        active
        telemetryDeviceId
        deviceId
        latitude
        longitude
      }
      immobiliser
      transmission
      twelveMonthRate
      typeApproval
      tyreFitments
      wheelPlan
      acrissCode
      branch
      canTransfer
      dateCreated
      residualValue,
      holdingCostPerDay
      vehicleType
      branchDetails {
        id
        name
      }
      vehicleOwners {
      firstName
      lastName
      ownershipNumber
      email
      address {
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      phoneNumber {
        phone
        country
      }
      }
      imageUrl
      deleted
      id
      interiorColour
      pcoNumber
      pcoExpiry
      nextPMIDate
      nextBRTDate
      frogDiagram
      notes {
        id
        description
        createdBy
        createdDate
      }
      batteries {
        voltage
        capacity
        coldTestCurrent
        length
        width
        height
        postPositions
        terminalType
        holdDownType
      }
      frontTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      rearTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
      }
      vehicleDocuments {
        title
        documentName
        documentType
        expiryDate
        url
        createdAt
      }
      bookingSchedules {
        booking {
          id
        }
        endDate
        id
        startDate
        status
      }
      features
      vehicleState {
        exterior {
          front {
            description
            images
          }
          rear {
            description
            images
          }
          top {
            description
            images
          }
          right {
            description
            images
          }
          left {
            description
            images
          }
        }
        interior {
          frontSeat {
            clean
            controls
            seatBelt
          }
          rearSeat {
            clean
            controls
            seatBelt
          }
          lockAndSwitch {
            frontDoor
            rearDoor
            frontWindow
            rearWindow
            childLock
          }
          images
        }
        generalChecks {
          name
          status
          images
        }
        defaultAccesories {
          name
          status
          images
        }
        bookedAccesories {
          name
          type
          quantity
          description
        }
        tyre {
          frontLeft {
            condition
            pressure
            description
          }
          frontRight {
            condition
            pressure
            description
          }
          rearLeft {
            condition
            pressure
            description
          }
          rearRight {
            condition
            pressure
            description
          }
        }
        id
        odometerReading
        fuelStatus
        batteryStatus
        updatedAt
        createdAt
        updatedBy {
          firstName
          lastName
        }
        createdBy {
          firstName
          lastName
        }
      }
      services {
        id
        status
        serviceType
        serviceProviderName
        serviceProviderAddress {
          fullAddress
        }
        odometerReading
        appointmentDate
        startDate
        completionDate
        description
        totalExpenses
        documents {
          expiryDate
          documentName
          documentType
          title
        }
      }
      activeVehicleDamages {
        id
        frogDiagram
        referenceNumber
        approvalStatus
        branchId
        reportDate
        damages {
          title
          descriptions
          damageType
          damageKind
          damageArea
          images
          location {
            x
            y
          }
        }
      }
      damages {
        id
        vehicleId
        circumstance
        referenceNumber
        bookingRef
        reportDate
        incidentDate
        reportedBy
        frogDiagram
        approvalStatus
        estimatedCost
        status
        damageSeverity
        estimatedRepairDate
        damages {
          title
          descriptions
          damageType
          damageKind
          damageArea
          images
          location {
            x
            y
          }
        }
      }
      tankCapacity
      subscriptions {
        deposit
        subscription {
          id
          name
        }
        pricings {
          duration
          enrollmentAmount
          mileage
          subscriptionAmount
          pricePerExtraMile
          unlimitedMileage
          swapsAllowed
        }
      }
      smartCarVehicleID
      deFleetParameters{
        byDistanceDriven
        byDate
        alertBeforeDistance
        alertBeforeDays
      }
      regularServiceSchedule{
        byDistance
        byMonth
        alertBefore
      }
      trailerType
      bodyCode
      axles
      length
      height
      palletCapacity
      pmiCycle
    }
  }
`;
