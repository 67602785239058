export default {
  primary: {
    light: "#FFB950",
    main: "#FFC700",
    dark: "#EA7000",
    contrastText: "#ffffff"
  },
  secondary: {
    light: "#EDEDED",
    main: "#002F6E",
    dark: "#EDEDED",
    contrastText: "#ffffff"
  },
  tertiary: {
    light: "#EDEDED",
    dark: "#EDEDED"
  },
  info: {
    main: "#2A9CB7"
  },
  success: {
    main: "#479F4B",
    dark: "#EDEDED"
  },
  error: {
    light: "#EDEDED",
    main: "#D13135",
    dark: "#EDEDED"
  }
};
