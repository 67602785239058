import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { reverse, cloneDeep } from "lodash";
import { DateTime as d } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";

import { GET_BOOKING } from "../../../graphql/bookings/getBookingQuery";
import { GET_VEHICLE_SERVICE } from "../../../graphql/fleet/vehicleServices/getVehicleService";
import {
  GET_ACTION_BY_ID,
  GET_JOB,
  GET_USERS_IN_BRANCH
} from "../../../graphql/jobSchedular/getJobsQuery";
import {
  ADD_USER_JOB_NOTE,
  CANCEL_JOB,
  CHANGE_JOB_STATUS,
  CREATE_JOB,
  UPDATE_JOB
} from "../../../graphql/jobSchedular/jobMutation";
import { IBooking } from "../../../reducers/bookings/types";
import { IVehicleService } from "../../../reducers/fleet/types";
import { jobInitialState } from "../../../reducers/jobSchedular/const";
import { IDateTime, IUserJobWrite } from "../../../reducers/jobSchedular/types";
import { IUser } from "../../../reducers/user/types";
import { IAppState } from "../../../store";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { SimpleDateTimePicker } from "../../common/SimpleDateTimePicker";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage
} from "../../common/utils";
import { UserRoles } from "../../hoc/Authorization";
import { IPendingAction } from "../DashboardNew/UpcomingEvents";
import { UserJobTypes, userJobStatus } from "./JobUtils";
import { captureErrorException } from "../../../utils/sentry";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1
    }
  })
);

export enum JobStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELLED = "CANCELLED"
}

export const UserJob: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IUserJobWrite>(jobInitialState);
  const [users, setUsers] = useState<IUser[]>([]);
  const [pendingAction, setPendingAction] = useState<IPendingAction>();
  const [initialNoteValue, setInitialNoteValue] = useState({ note: "" });
  const [notes, setNotes] = useState([]);
  const { country } = userState.currentOrganisation.address;
  const [booking, setBooking] = useState<IBooking>();
  const [service, setService] = useState<IVehicleService>();
  const [bookingRef, setBookingRef] = useState("");
  const [vehicleRef, setVehicleRef] = useState("");
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [
    getUsersInBranch,
    { loading: branchUsersLoading, data: branchUsersData }
  ] = useLazyQuery(GET_USERS_IN_BRANCH, {
    fetchPolicy: "network-only"
  });

  const [fetchJob, { loading: jobDataLoading, data: jobData }] = useLazyQuery(
    GET_JOB,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.userJobById) {
          navigate("/user-jobs");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        navigate("/user-jobs");
      }
    }
  );

  const [
    fetchPendingActionById,
    { loading: pendingActionLoading, data: pendingActionData }
  ] = useLazyQuery(GET_ACTION_BY_ID, {
    fetchPolicy: "network-only"
  });

  const [createJob, { loading: saveJobLoading }] = useMutation(CREATE_JOB, {
    onCompleted: () => {
      snackbar({
        message: "Job Created successfully",
        variant: SnackBarVariant.SUCCESS
      });
      navigate(`/user-jobs`);
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: (data) => {
      if (data) {
        snackbar({
          message: "Job Updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(`/user-jobs`);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [changeUserJobStatus] = useMutation(CHANGE_JOB_STATUS, {
    onCompleted: (data) => {
      if (data) {
        snackbar({
          message: "Job Updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(`/user-jobs`);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [addNote, { data: jobNote }] = useMutation(ADD_USER_JOB_NOTE, {
    onCompleted: (data: any) => {
      if (data) {
        snackbar({
          message: "Note added successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    }
  });

  const [
    fetchBooking,
    { data: bookingData, loading: bookingLoading }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    loadVehicleService,
    { loading: serviceLoading, data: vehicleServiceData }
  ] = useLazyQuery(GET_VEHICLE_SERVICE, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [cancelUserJob] = useMutation(CANCEL_JOB, {
    onCompleted: (data) => {
      if (data) {
        snackbar({
          message: "Job cancelled successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(`/user-jobs`);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (userState) {
      getUsersInBranch();
    }
  }, [userState]);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const pendingActionId = params.get("pendingAction");
      const jobId = params.get("jobId");
      const bookingId = params.get("booking");
      const vehicleServiceId = params.get("vehicleservice");
      if (pendingActionId) {
        fetchPendingActionById({
          variables: {
            id: pendingActionId
          }
        });
      }
      if (jobId) {
        fetchJob({
          variables: {
            id: jobId
          }
        });
      }
      if (bookingId) {
        fetchBooking({
          variables: {
            id: bookingId
          }
        });
      }
      if (vehicleServiceId) {
        loadVehicleService({
          variables: {
            id: vehicleServiceId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (pendingActionData && pendingActionData.getActionById) {
      const data: IPendingAction = pendingActionData.getActionById;
      setPendingAction(data);
      setValues({
        ...values,
        actionsLinked: [data.id],
        subCategory: data.subCategory,
        description: data.description,
        jobDate: data.actionDate
      });
    }
  }, [pendingActionData]);

  useEffect(() => {
    if (bookingData && bookingData.booking) {
      const booking: IBooking = bookingData.booking;
      setBooking(booking);
      setValues({
        ...values,
        booking: booking.id,
        jobDate: booking.pickupDateTime
      });
    }
  }, [bookingData]);

  useEffect(() => {
    if (vehicleServiceData && vehicleServiceData.vehicleService) {
      const vehicleService: IVehicleService = vehicleServiceData.vehicleService;
      setService(vehicleService);
      setValues({
        ...values,
        vehicle: vehicleService.vehicleId,
        jobDate: vehicleService.appointmentDate
      });
    }
  }, [vehicleServiceData]);

  useEffect(() => {
    if (branchUsersData && branchUsersData.getUsersInBranch?.length) {
      setUsers(branchUsersData.getUsersInBranch);
    }
  }, [branchUsersData]);

  useEffect(() => {
    if (jobData && jobData.userJobById) {
      if (
        jobData.userJobById.status &&
        (jobData.userJobById.status === JobStatus.CANCELLED ||
          jobData.userJobById.status === JobStatus.COMPLETE)
      ) {
        setStatusDisabled(true);
      }
      setValues({
        ...jobData.userJobById,
        assignedTo: jobData.userJobById.assignedTo.id,
        booking: jobData.userJobById?.booking?.id,
        vehicle: jobData.userJobById?.vehicle?.id
      });
      if (jobData.userJobById.booking && jobData.userJobById.booking.id) {
        setBookingRef(jobData.userJobById.booking.referenceNumber);
      } else if (
        jobData.userJobById.vehicle &&
        jobData.userJobById.vehicle.id
      ) {
        setVehicleRef(jobData.userJobById.vehicle.licencePlate);
      }
      const jobNotes = cloneDeep(jobData.userJobById.notes)
      setNotes(reverse(jobNotes));
    }
  }, [jobData]);

  const handleFormSubmit = (values: IUserJobWrite) => {
    const currentDate = d
      .now()
      .toUTC()
      .toISO();
    if (!values.id) {
      delete values.status;
      if (values.jobDate < currentDate) {
        snackbar({
          message: "Time cannot be in the past. Please select a valid Time.!",
          variant: SnackBarVariant.ERROR
        });
      } else {
        createJob({
          variables: {
            userJob: values
          }
        });
      }
    } else {
      const actionId = values.actionsLinked.map((key: any) => {
        return key.id;
      });
      values.actionsLinked = actionId;
      const { notes, ...rest } = values;
      if (!values.jobDate) {
        snackbar({
          message: "Please select date and time !",
          variant: SnackBarVariant.ERROR
        });
      } else {
        updateJob({
          variables: {
            id: values.id,
            userJob: rest
          }
        });
      }
    }
  };

  const jobValidationSchema = Yup.object().shape({
    subCategory: Yup.string().required("Task Type is required."),
    assignedTo: Yup.string().required("User is required."),
    description: Yup.string().required("Please add the task description")
  });

  if (
    jobDataLoading ||
    branchUsersLoading ||
    pendingActionLoading ||
    bookingLoading ||
    serviceLoading ||
    serviceLoading
  ) {
    return <CircularProgress />;
  }

  const changeJobStatus = (status: string) => {
    changeUserJobStatus({
      variables: {
        id: values.id,
        status: status
      }
    });
  };

  const cancelJob = () => {
    cancelUserJob({
      variables: {
        id: values.id
      }
    });
  };
  const onNoteSubmit = async () => {
    try {
      const result = await addNote({
        variables: {
          id: values.id || "",
          note: initialNoteValue
        }
      });
      let newNotes = reverse(result.data.addJobNote.notes);
      setNotes(newNotes);
      setInitialNoteValue({ note: "" });
    } catch (error) {
      captureErrorException(error)
      console.error("Error adding note:", error);
    }
  };

  const getDate = (dateInput: string) => {
    const date = d.fromISO(dateInput);
    return d.now() > date ? date.toUTC().toISO(): d.now().toUTC().toISO();
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6} alignItems="center">
        <Typography variant="h1" color="primary">
          Task
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {!values.id ? (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Task
        </Typography>
      </Grid>
      {values.id ? (
        <Grid container item xs={6} justifyContent="flex-end">
          <Typography>
            {values?.status === JobStatus.PENDING ? (
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                className="addButton"
                onClick={() => {
                  changeJobStatus(JobStatus.IN_PROGRESS);
                }}
              >
                Mark as In-Progress
              </Fab>
            ) : values?.status === JobStatus.IN_PROGRESS ? (
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                className="addButton"
                onClick={() => {
                  changeJobStatus(JobStatus.COMPLETE);
                }}
              >
                Mark as Complete
              </Fab>
            ) : (
              ""
            )}
            {values.status && values.status === JobStatus.PENDING && (
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                className="addButton"
                onClick={() => {
                  cancelJob();
                }}
                style={{ marginLeft: 15 }}
              >
                Cancel
              </Fab>
            )}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          {pendingAction && (
            <Grid container xs={12} style={{ marginBottom: "1rem" }}>
              <Typography variant={"h3"}>
                Pending Action: {pendingAction.description}
              </Typography>
            </Grid>
          )}
          <Grid container xs={12}>
            <Typography variant={"h3"} style={{ marginBottom: "1rem" }}>
              {booking && (
                <span>
                  {getLocalizedBookingSyntex(country)} Reference : {booking.referenceNumber || "NA"}
                </span>
              )}
              {bookingRef && (
                <span>{getLocalizedBookingSyntex(country)} Reference : {bookingRef || "NA"}</span>
              )}
            </Typography>
          </Grid>
          <Grid container xs={12}>
            <Typography variant={"h3"} style={{ marginBottom: "1rem" }}>
              {service && (
                <span>
                  Service Reference : {service.referenceNumber || "NA"}
                </span>
              )}
              {vehicleRef && (
                <span>Vehicle Reference : {vehicleRef || "NA"}</span>
              )}
            </Typography>
          </Grid>
          <Formik
            enableReinitialize
            validationSchema={jobValidationSchema}
            initialValues={values}
            onSubmit={(data, { setSubmitting }) => {
              handleFormSubmit(data);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      placeholder="Task Type"
                      label="Task Type"
                      name={"subCategory"}
                      onChange={(e: any) => {
                        props.setValues({
                          ...props.values,
                          subCategory: e.target.value
                        });
                      }}
                      fullWidth
                      required
                      select
                      value={props.values.subCategory}
                    >
                      {UserJobTypes.map((key: any, index: number) => {
                        return (
                          <MenuItem key={index} value={key.value}>
                            {key.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  {booking && (
                    <Grid container item xs={12} sm={6} md={3}>
                      <Field
                        component={TextField}
                        placeholder="Vehicle"
                        label="Select Vehicle"
                        name={"vehicle"}
                        value={props.values.vehicle}
                        onChange={(e: any) => {
                          props.setValues({
                            ...props.values,
                            vehicle: e.target.value
                          });
                        }}
                        fullWidth
                        required
                        select
                      >
                        {booking.currentBookingSchedules.map(
                          (key: any, index: number) => {
                            return (
                              <MenuItem key={index} value={key.vehicle.id}>
                                {key.vehicle.licencePlate}
                              </MenuItem>
                            );
                          }
                        )}
                      </Field>
                    </Grid>
                  )}
                  <Grid container item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      placeholder="Assign To"
                      label="Assign To"
                      name={"assignedTo"}
                      value={props.values.assignedTo}
                      onChange={(event: any) => {
                        props.setValues({
                          ...props.values,
                          assignedTo: event.target.value
                        });
                      }}
                      fullWidth
                      required
                      select
                    >
                      {users.map((option: any, index: number) => {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  <Grid container item xs={12} sm={6} md={3}>
                    <SimpleDateTimePicker
                      date={props.values?.jobDate}
                      handleChange={(date: IDateTime) => {
                        if (date && date.date) {
                          props.setValues({
                            ...props.values,
                            jobDate: date.date
                          });
                        }
                      }}
                      required={true}
                      name={"jobDate"}
                      dateTitle={"Task Date"}
                      timeTitle={"Task Time"}
                      timeToShow={"before"}
                      {...(!props.values.id
                        ? {
                            minDate: d
                              .now()
                              .toUTC()
                              .toISO()
                          }
                        : {
                            minDate: getDate(values.jobDate)
                          })}
                    />
                  </Grid>
                  {values.id && (
                    <Grid item xs={12} sm={6} md={3}>
                      <Field
                        component={TextField}
                        placeholder="Status"
                        label="Status"
                        name={"status"}
                        onChange={(event: any) => {
                          props.setValues({
                            ...props.values,
                            status: event.target.value
                          });
                        }}
                        fullWidth
                        required
                        select
                        disabled={statusDisabled}
                        value={props.values.status}
                      >
                        {userJobStatus.map((key: any, index: number) => {
                          return (
                            <MenuItem key={index} value={key.value}>
                              {key.label}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field
                      component={TextField}
                      label={"Description"}
                      name={"description"}
                      inputProps={{ maxLength: 500 }}
                      fullWidth
                      multiline
                      rows={"3"}
                      value={props.values.description}
                      onChange={(e: any) => {
                        props.setValues({
                          ...props.values,
                          description: e.target.value
                        });
                      }}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                        disabled={statusDisabled || saveJobLoading}
                      >
                        {saveJobLoading && (
                          <CircularProgress
                            size={14}
                            style={{ color: "white", marginRight: "10px" }}
                          />
                        )}
                        Save
                      </Fab>
                    </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

      {values.id ? (
        <Grid container item xs={12}>
          <Paper className={classes.root}>
            <Typography variant="h3" style={{ marginBottom: 15 }}>
              {" "}
              Notes{" "}
            </Typography>
            <Formik
              enableReinitialize
              initialValues={initialNoteValue}
              onSubmit={(data, { setSubmitting }) => {
                onNoteSubmit();
                setSubmitting(false);
              }}
            >
              {() => (
                <Form className={classes.form}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field
                      component={TextField}
                      label={"Note"}
                      name={"note"}
                      inputProps={{ maxLength: 500 }}
                      fullWidth
                      multiline
                      rows={"3"}
                      onChange={(e: any) => {
                        setInitialNoteValue({
                          ...initialNoteValue,
                          note: e.target.value
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={!initialNoteValue.note}
                    >
                      Add
                    </Fab>
                  </Grid>
                </Form>
              )}
            </Formik>

            {notes && notes.length ? (
              <TableContainer>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{ maxWidth: 300, minWidth: 200 }}
                      >
                        <Typography>Notes</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Added By</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography> Date & Time</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notes.map((key: any) => {
                      return (
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 300, minWidth: 200 }}
                          >
                            {" "}
                            {key?.note || "NA"}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {key?.createdBy?.firstName}{" "}
                            {key?.createdBy?.lastName}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {getLocalizedDateFormat(
                              country,
                              `${key?.createdAt}`,
                              DATE_TYPE.EXPANDED
                            )}{" "}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ""
            )}
          </Paper>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};
