import { gql } from "@apollo/client";

export const GET_EXPENSE = gql`
  query expense($expenseId: String!) {
    expense(expenseId: $expenseId) {
      assetType
      vehicle {
        id
        licencePlate
      }
      addon{
        id
        name
        displayName
      }
      expenseType
      authority
      itemType
      unit
      amountPerUnit
      cycle
      insuranceExpiryDate
      expenseDate
      fuelType
      fuelCardNumber
      vendor
      odometerReading
      fuelQuantity
      reference
      amount
      driver
      document {
        documentName
        url
      }
      description
      branch{
        id
        name
      }
    }
  }
`;

export const GET_VEHICLE_DASHBOARD_DATA = gql`
  query getVehicleDashboardData($vehicleId: String!) {
    getVehicleDashboardData(vehicleId: $vehicleId) {
      acquisitionCost
      vehicleExpenses{
        fuelCost,
        fineCost,
        serviceCost,
        damageCost,
        insuranceCost,
        maintenanceCost
      }
      vehicleRevenue{
        totalEarnings
        totalRevenueLoss
        residualValue
      }
      renewalAndReminders{
        motRenewalDate
        insuranceRenewalDate
        nextServiceDate
      }
    }
  }
`;