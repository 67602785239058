import { Button, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { IVehiclePriceGroup } from "../../../../reducers/vehiclePriceGroups/types";

interface IProps {
  count: number;
  maxCount: number;
  vehicleGroup: IVehiclePriceGroup;
  updateCounter: (data: any, isIncrease: boolean, vehicleId?: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    counterButtonRoot: {
      border: "solid",
      borderWidth: "1px",
      borderColor: "black",
      padding: "2px 5px 2px 5px",
      cursor: "pointer",
      minWidth: 20,
      borderRadius: "0px !important"
    }
  })
);
const VehicleCounter: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const { count, maxCount, vehicleGroup, updateCounter } = props

  const increase = () => {
    if (count < props.maxCount) {
      updateCounter(vehicleGroup, true);
    }
  };

  const decrease = () => {
    if (count > 0) {
      updateCounter(vehicleGroup, false);
    }
  };

  return (
    <Grid container>
      <Typography
        variant={"body1"}
        display="inline"
        style={{
          marginRight: "10px",
          marginTop: "3px"
        }}
      >
        Vehicles Required
      </Typography>
      <Button
        onClick={decrease}
        className={classes.counterButtonRoot}
      >
        -
      </Button>
      <Typography
        variant={"body1"}
        display="inline"
        style={{
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          padding: "2px 5px 2px 5px"
        }}>
        {count}
      </Typography>
      <Button
        onClick={increase}
        className={classes.counterButtonRoot}
      >
        +
      </Button>
    </Grid>
  );
};

export default VehicleCounter;



// import { Grid, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { IVehiclePriceGroup } from "../../../../reducers/vehiclePriceGroups/types";

// interface IProps {
//   count: number;
//   maxCount: number;
//   vehicleGroup: IVehiclePriceGroup;
//   updateCounter: (data: any, count: number) => void;
// }

// const VehicleCounter: React.FC<IProps> = (props) => {
//   const [count, setCount] = useState<number>(0);

//   useEffect(() => {
//     if (props.count >= 0) {
//       setCount(props.count);
//     }
//   }, [props.count]);

//   const increase = () => {
//     if (count < props.maxCount) {
//       setCount(count + 1);
//       props.updateCounter(props.vehicleGroup, count + 1);
//     }
//   };

//   const decrease = () => {
//     if (count > 0) {
//       setCount(count - 1);
//       props.updateCounter(props.vehicleGroup, count - 1);
//     }
//   };

//   return (
//     <Grid container>
//       <Typography
//         variant={"body1"}
//         display="inline"
//         style={{
//           marginRight: "10px",
//           marginTop: "3px"
//         }}
//       >
//         Vehicles Required
//       </Typography>
//       <Typography
//         onClick={decrease}
//         variant={"body1"}
//         display="inline"
//         style={{
//           border: "solid",
//           borderWidth: "1px",
//           borderColor: "black",
//           padding: "2px 5px 2px 5px",
//           cursor: "pointer"
//         }}>
//         -
//       </Typography>
//       <Typography
//         variant={"body1"}
//         display="inline"
//         style={{
//           border: "solid",
//           borderWidth: "1px",
//           borderColor: "black",
//           padding: "2px 5px 2px 5px"
//         }}>
//         {count}
//       </Typography>
//       <Typography
//         onClick={increase}
//         variant={"body1"}
//         display="inline"
//         style={{
//           border: "solid",
//           borderWidth: "1px",
//           borderColor: "black",
//           padding: "2px 5px 2px 5px",
//           cursor: "pointer"
//         }}>
//         +
//       </Typography>
//     </Grid>
//   );
// };

// export default VehicleCounter;
