import { gql } from "@apollo/client";

export const GET_APP_SETTINGS = gql`
  query getConsumerAppSettings {
    getConsumerAppSettings {
      id
      appName
      organisationId
      branchIds
      privacyPolicyURL
      provider
      callbackUris
      clientId
      clientSecret
      firstParty
      companyWebsite
      termsAndConditionsURL
      organisation {
        name
      }
      branding {
        brandColors
        buttonColor
        accentColor
      }
      webAppSetting {
        websiteTitle
        websiteIcon
        websiteLogo
        domain
        websiteHeader
        websiteDescription
        logoSize
        htmlDescription
        hasCarousel
        carousel
        hasGallery
        gallery
        websiteAddress
      }
      mobileAppSetting {
        appIcon
        companyIcon
        straplineIcon
        landingPageIcon
      }
    }
  }
`;

export const GET_APP_SETTING = gql`
  query getConsumerAppSetting($appId: ID!) {
    getConsumerAppSetting(appId: $appId) {
      id
      allowedRateTypes
      allowedBillingFrequencies
      appName
      organisationId
      branchIds
      privacyPolicyURL
      provider
      callbackUris
      clientId
      clientSecret
      firstParty
      companyWebsite
      termsAndConditionsURL
      organisation {
        name
      }
      branding {
        brandColors
        buttonColor
        accentColor
      }
      isActive
      bookingTypes
      webAppSetting {
        websiteTitle
        websiteIcon
        websiteLogo
        domain
        websiteHeader
        websiteDescription
        logoSize
        htmlDescription
        hasCarousel
        carousel
        hasGallery
        gallery
        websiteAddress
        enableBusinessCustomerSignup
        paymentGateway
      }
      mobileAppSetting {
        appIcon
        companyIcon
        straplineIcon
        landingPageIcon
      }
    }
  }
`;

export const GET_ORG_APP_SETTING = gql`
  query getAppSettingForOrganisation {
    getAppSettingForOrganisation {
      id
      webAppSetting {
        enableBusinessCustomerSignup
      }
    }
  }
`;

export const GET_ORGANISATIONS = gql`
  query Organisations {
    organisations {
      id
      branches {
        id
        name
      }
      name
      allowSubscriptionBookings
    }
  }
`;
