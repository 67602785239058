import { gql } from "@apollo/client";

export const GET_VEHICLE_SERVICES = gql`
  query vehicleServices($filter: [FilterInput]) {
    vehicleServices(filter: $filter) {
      id
      serviceType
      serviceProviderName
      status
      vehicleId
      appointmentDate
      completionDate
      totalExpenses
      licencePlate
    }
  }
`;
