import "flatpickr/dist/themes/material_green.css";
import "./index.scss";
import React from "react";
import Flatpickr from "react-flatpickr";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import { IUserState } from '../../../reducers/user/types';
import { DateTime as d, Settings } from "luxon"
import { MONTH_SELECTOR_TYPE } from "../utils";
interface IProps {
  handleDateChange: (value: Date) => void;
  identifier?: string;
  defaultDateTime?: any;
  now?: string;
  enableTime?: boolean;
  placeholderValue?: string;
  onBlur?: any;
  name?: string | undefined;
  value?: string;
  required?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  disabled?: boolean;
  type?: string;
  label?: string;
  userReducer: IUserState;
  disabledDates?: (number | string)[];
  monthSelectorType?: MONTH_SELECTOR_TYPE;
  country: string;
  render?: any;
  onOpenCalendar?(): void;
  onCloseCalendar?(): void
}
class FlatPickerBar extends React.Component<IProps> {
  public static defaultProps = {
    enableTime: true
  };
  private fp = React.createRef<any>();
  public clearDate = () => {
    if (this.fp.current) {
      this.fp.current.flatpickr.clear();
    }
  };
  public validateDate(date: string) {
    const dateTimePattern = new RegExp(
      /^^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/
    );
    return dateTimePattern.test(date);
  }
  public render() {
    const {
      identifier,
      enableTime,
      placeholderValue,
      name,
      handleDateChange,
      minDate,
      maxDate,
      type,
      label,
      required,
      disabledDates,
      monthSelectorType,
      country,
      now,
      onOpenCalendar,
      onCloseCalendar
    } = this.props;
    return (
      <div
        className={
          `${identifier === "fleet-calendar"
            ? `fleet-calendar-container`
            : `flatpickr-container ${required ? "required-date" : ""}`}
            ${type === "simple-time-picker" ?
            "simple-time-picker"
            : type === "date-time-picker" ?
              "date-time-picker"
              : type === "replacement-reason-date-time" ? "replacement-reason-date-time" : ""
          }`
        }
      >
        <span className={"flatpicker-date-label"}>{label ? label : ""}{required ? <sup className="flat-picker-required">*</sup> : ""}</span>
        <Flatpickr
          options={{
            disableMobile: true,
            enableTime,
            static: true,
            minuteIncrement: 1,
            dateFormat: country === "United States" ? "m/d/Y" : "d/m/Y",
            defaultDate: this.props.defaultDateTime,
            minDate,
            maxDate,
            now,
            disable: disabledDates && disabledDates.length > 0 ? [function (date) {
              const dateObj = d.fromFormat(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, "d/M/yyyy", { setZone: false })
              return disabledDates.includes(dateObj.toFormat("dd/MM/yyyy")) || disabledDates.includes(dateObj.weekday);
            }] : [],
            monthSelectorType: monthSelectorType ? monthSelectorType : MONTH_SELECTOR_TYPE.DROPDOWN,
            onOpen: () => onOpenCalendar && onOpenCalendar(),
            onClose: () => onCloseCalendar && onCloseCalendar()
          }}
          disabled={this.props.disabled ? this.props.disabled : false}
          className={
            identifier === "fleet-calendar"
              ? "fleet-calendar"
              : "flatpickr-input"
          }

          placeholder={placeholderValue}
          value={this.props.value || ""}
          name={name}
          onChange={(date) => {
            if (date && date.length > 0 && date[0]) {
              handleDateChange(d.fromFormat(`${date[0].getFullYear()}/${date[0].getMonth() + 1}/${date[0].getDate()}`, "yyyy/M/d", { zone: Settings.defaultZone }).toJSDate());
            } else {
              handleDateChange(new Date(''));
            }
          }}
          ref={this.fp}
          required={this.props.required}
          render={this.props.render}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: IAppState) => ({ userReducer: state.userReducer });

export default connect(mapStateToProps, {})(FlatPickerBar);