import React, { useEffect, useState } from 'react'
import { IVehicle } from '../../../../../reducers/fleet/types';
import { Dialog, DialogContent, DialogTitle, Fab, Grid, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { TransitionProps } from '@mui/material/transitions/transition';
import { IAddons, ISubscription, ISubscriptionPricing } from '../../../../../reducers/Subscription/types';
import { NuvvenDivider } from '../../Summary/Components/Divider';
import CloseIcon from "@mui/icons-material/Close";
import { getVehicleTypeIcon, toCurrency } from '../../../../common/utils';
import { RATE_TYPES, capitalizeFirstLetter } from '../../utils';
import { IAppState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { ISubscriptionArgs } from '../../../../../reducers/bookings/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(3)
    },
    iconStyle: {
      padding: 2
    },
    avatarStyle: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    paperStyle: {
      flexGrow: 1
    },
    table: {
      minWidth: 700,
    },
    dialogTitle: {
      padding: '10px 0px 0px 15px'
    },
    dialogContent: {
      padding: "20px 30px 30px 30px"
    }
  })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 10,
      fontSize: 14,
    },
    head: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff !important",
      fontWeight: 600
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

interface IProps {
  open: boolean;
  onSelectSubscription(selectedSubscription: ISubscriptionArgs, vehicle?: IVehicle): void;
  onCancel(): void;
  vehicle?: IVehicle;
  bookingDurationInMonths: number;
  bookingDurationInWeeks: number;
}

interface ISelectSubscriptionPricing {
  subscription: ISubscription;
  pricing: ISubscriptionPricing;
  deposit: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectSubscriptionPricingModal: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const [subscriptionPlans, setSubscriptionPlans] = useState<ISelectSubscriptionPricing[]>([])

  useEffect(() => {
    if (props.vehicle && props.vehicle.subscriptions?.length) {
      const plans: ISelectSubscriptionPricing[] = []
      props.vehicle.subscriptions.forEach(subscription => {
        if (subscription.subscription.isActive) {
          let pricings = subscription.pricings || []
          pricings = pricings.sort((a, b) => b.duration - a.duration)
          if (pricings.length) {
            const pricing = pricings.find((pricing) => (subscription.subscription.rateTypeName === RATE_TYPES.MONTHLY && pricing.duration <= props.bookingDurationInMonths) || (subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY && pricing.duration <= props.bookingDurationInWeeks))
            if (pricing) {
              plans.push({
                subscription: subscription.subscription as any,
                pricing,
                deposit: subscription.deposit
              })
            }
          }
        }
      });
      setSubscriptionPlans(plans)
    }
  }, [props.vehicle])

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onCancel}
        aria-labelledby="select-subscription-dialog"
        aria-describedby="pricing-edit-fields"
        maxWidth={"md"}
        style={{ flexGrow: 1 }}
        classes={{
          paper: classes.paperStyle
        }}
      >
        <DialogTitle style={{ paddingBottom: 5 }} id="select-subscription-dialog">
          <Grid item xs={12} container justifyContent='space-between' alignItems='baseline'>
            <Grid><Typography style={{ fontWeight: 600 }} color='primary' variant='h2'>SELECT SUBSCRIPTION</Typography></Grid>
            <Grid>
              <IconButton
                onClick={props.onCancel}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <NuvvenDivider noMargin />
        <DialogContent>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} container justifyContent="center"><Typography style={{ fontWeight: 600, textTransform: "uppercase" }} variant='h3'>{`${props.vehicle?.make} ${props.vehicle?.model}`}</Typography></Grid>
            <Grid item xs={12} container justifyContent="center">
              {props.vehicle?.imageUrl ? (
                <img
                  className="fleet-resrc-img"
                  alt="vehicle type icon"
                  src={props.vehicle.imageUrl}
                  height="90"
                  width="180"
                  style={{ width: "40%", height: "auto", borderRadius: 4 }}
                />
              ) : (
                <img
                  className="fleet-resrc-img"
                  alt="vehicle type icon"
                  src={getVehicleTypeIcon(
                    capitalizeFirstLetter(props.vehicle?.bodyType || "")
                  )}
                  style={{ width: "40%", height: "auto", borderRadius: 4 }}
                />
              )}
            </Grid>
            <Grid item xs={12}><Typography variant='h4'>*(Prices shown is based on selected duration)</Typography></Grid>
            <Grid item xs={12}><Typography variant='h2'>Price Comparison</Typography></Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Price</StyledTableCell>
                      <StyledTableCell>Duration&nbsp;(Min)</StyledTableCell>
                      <StyledTableCell>Enrollment Fee&nbsp;(One Time)</StyledTableCell>
                      <StyledTableCell>Deposit</StyledTableCell>
                      <StyledTableCell>Swaps Allowed</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      subscriptionPlans && subscriptionPlans.length > 0 &&
                      subscriptionPlans.map(subscription => (
                        <StyledTableRow key={subscription.subscription.id}>
                          <StyledTableCell >{subscription.subscription.name}</StyledTableCell>
                          <StyledTableCell>{`${toCurrency(subscription.pricing.subscriptionAmount, currency, locale)} per ${subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? "week" : "month"}`}</StyledTableCell>
                          <StyledTableCell>{`${subscription.pricing.duration} ${subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? "Weeks" : "Months"}`}</StyledTableCell>
                          <StyledTableCell>{toCurrency(subscription.pricing.enrollmentAmount, currency, locale)}</StyledTableCell>
                          <StyledTableCell>{subscription.deposit ? toCurrency(subscription.deposit, currency, locale) : "N/A"}</StyledTableCell>
                          <StyledTableCell>{subscription.pricing.swapsAllowed || "N/A"}</StyledTableCell>
                          <StyledTableCell>
                            <Fab
                              size="medium"
                              variant="extended"
                              onClick={() => {
                                props.onCancel()
                                props.onSelectSubscription({ pricing: subscription.pricing, subscription: subscription.subscription, deposit: subscription.deposit }, props.vehicle)
                              }}
                            >
                              Select
                            </Fab>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SelectSubscriptionPricingModal
