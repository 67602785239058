import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Theme,
  Typography,
  Fab,
  Switch
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTenancyOrganisation, updateUser } from "../../../../../actions/user/actions";
import { GET_ORGANISATION } from "../../../../../graphql/organisation/getOrganisationQuery";
import { UPDATE_ORGANISATION } from "../../../../../graphql/organisation/updateOrganisationMutation";
import { initialState } from "../../../../../reducers/organisation/const";
import { IOrganisation } from "../../../../../reducers/organisation/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { UserRoles } from "../../../../hoc/Authorization";
import { setOrganisation as setOrganisationState } from "../../../../../actions/organisation/actions";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../../../../../utils/getSignedUrl";
import { UPDATE_CREDIT_SAFE_CREDS } from "../../../../../graphql/organisation/updateCreditSafeCredsMutation";
import { ICreditSafe } from "../../../../../reducers/user/types";
import * as Yup from "yup";
import { Integrations } from "../Integrations/Integration";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      marginTop: "50px"
    },
    form: {
      marginTop: "20px"
    },
    field: {
      marginBottom: "20px"
    },
    header: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    hidePassword: {
      "-webkit-text-security": "disc"
    }
  })
);

export const PaymentConfiguration = () => {

  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const currentOrganisation = userState.currentOrganisation
  const [organisation, setOrganisation] = useState<IOrganisation>(initialState.organisation);
  const [loading, setLoading] = useState<boolean>(false);
  const [credLoading, setCredLoading] = useState<boolean>(false);
  const [creditSafeCreds, setCreditSafeCreds] = useState<ICreditSafe>({
    username: currentOrganisation?.creditSafeCredentials?.username || "",
    password: currentOrganisation?.creditSafeCredentials?.password || "",
  });
  const convergeCredentials = {
    merchantAccountId: currentOrganisation.convergeCredentials?.merchantAccountId || "",
    merchantAlias: currentOrganisation.convergeCredentials?.merchantAlias || "",
    merchantSecret: currentOrganisation.convergeCredentials?.merchantSecret || ""
  };

  const [getOrganisation, { data: organisationData, loading: orgLoading }] = useLazyQuery(GET_ORGANISATION, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      if (formatGraphQLErrorMessage(error.message) === ErrorMessage.ACCESS_DENIED) {
        navigate("/access-denied");
      } else {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
      }
    }
  });

  useEffect(() => {
    if (currentOrganisation && currentOrganisation.id) {
      getOrganisation({
        variables: {
          organisationId: currentOrganisation.id
        }
      });
    }
  }, [currentOrganisation])

  useEffect(() => {
    if (organisationData && organisationData.organisation) {
      setOrganisation({
        address: organisationData.organisation.address
          ? organisationData.organisation.address
          : {
            city: "",
            country: "",
            fullAddress: "",
            state: "",
            street: "",
            zipcode: ""
          },
        branches: organisationData.organisation.branches ? organisationData.organisation.branches : [],
        currency: organisationData.organisation.currency ? organisationData.organisation.currency : "GBP",
        sepaEnabled: organisationData.organisation.sepaEnabled ? true : false,
        bacsEnabled: organisationData.organisation.bacsEnabled ? true : false,
        cashEnabled: organisationData.organisation.cashEnabled ? true : false,
        cardEnabled: organisationData.organisation.cardEnabled ? true : false,
        autoChargeEnabled: organisationData.organisation.autoChargeEnabled ? true : false,
        offlineBankTransferEnabled: organisationData.organisation.offlineBankTransferEnabled ? true : false,
        payByLinkEnabled: organisationData.organisation.payByLinkEnabled ? true : false,
        payOnCollectionEnabled: organisationData.organisation.payOnCollectionEnabled ? true : false,
        emailId: organisationData.organisation.emailId ? organisationData.organisation.emailId : "",
        id: organisationData.organisation.id ? organisationData.organisation.id : "",
        locale: organisationData.organisation.locale ? organisationData.organisation.locale : "en-GB",
        logoUrl: organisationData.organisation.logoUrl ? organisationData.organisation.logoUrl : "",
        name: organisationData.organisation.name ? organisationData.organisation.name : "",
        orgRegNumber: organisationData.organisation.orgRegNumber ? organisationData.organisation.orgRegNumber : "",
        orgVatNumber: organisationData.organisation.orgVatNumber ? organisationData.organisation.orgVatNumber : "",
        owner: organisationData.organisation.owner ? organisationData.organisation.owner.id : "",
        phoneNumber: organisationData.organisation.phoneNumber,
        rate: organisationData.organisation.taxes ? organisationData.organisation.taxes[0].rate : null,
        stripeAccountId: organisationData.organisation.stripeAccountId || "",
        termsAndConditionsUrl: organisationData.organisation.termsAndConditionsUrl
          ? organisationData.organisation.termsAndConditionsUrl
          : "",
        insuranceDeclaration: organisationData.organisation.insuranceDeclaration || "",
        cancellationPolicy: organisationData.organisation.cancellationPolicy || "",
        refundPolicy: organisationData.organisation.refundPolicy || {
          flatCharge: 0,
          refundRules: []
        },
        requiresCustomerVerification: organisationData.organisation.requiresCustomerVerification || false,
        enableAutoCreditCheck: organisationData.organisation.enableAutoCreditCheck || false,
        enableIdentityVerification: organisationData.organisation.enableIdentityVerification || false,
        creditSafeCredentials: organisationData.organisation.creditSafeCredentials || { username: "", password: "" },
        allowPreSigningAgreement: organisationData.organisation.allowPreSigningAgreement || false,
        convergeCredentials: organisationData.organisation.convergeCredentials || convergeCredentials,
      });
    }

    if (organisationData?.organisation?.creditSafeCredentials) {
      setCreditSafeCreds({
        username: organisationData.organisation.creditSafeCredentials.username,
        password: organisationData.organisation.creditSafeCredentials.password
      })
    }
  }, [organisationData]);

  const [onUpdateOrganisation] = useMutation(UPDATE_ORGANISATION, {
    onCompleted: (data: any) => {
      setLoading(false)
      if (organisation.id === userState.currentOrganisation.id) {
        dispatch(updateTenancyOrganisation(data.updateOrganisation));
      }
      dispatch(setOrganisationState(data.updateOrganisation));
      if (userState.currentOrganisation.id === data.updateOrganisation.id) {
        dispatch(
          updateUser({
            ...userState,
            currentOrganisation: data.updateOrganisation
          })
        );
      }
      snackbar({
        message: "Payment & Integrations updated",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      setLoading(false)
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [onUpdateCreditSafeCredentials] = useMutation(UPDATE_CREDIT_SAFE_CREDS, {
    onCompleted: (data: any) => {
      setCredLoading(false)
      snackbar({
        message: "Credit Safe Credentials updated",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      setCredLoading(false)
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const initiateSageOauth = () => {
    const url = `${process.env.REACT_APP_SAGE_ACCOUNTING_AUTH_URL || 'https://www.sageone.com/oauth2/auth/central?filter=apiv3.1'}&client_id=${process.env.REACT_APP_SAGE_ACCOUNTING_CLIENT_ID}&redirect_uri=${window.location.origin}/update-organisation&state=${organisation.id}&response_type=code&scopes=full_access`;
    window.location.href = url;
  }

  const onSubmitHandler = (data: any) => {
    setLoading(true)
    const obj = { ...data };
    delete obj.id;
    delete obj.branches;
    delete obj.rate;
    if (organisation.id) {
      onUpdateOrganisation({
        variables: {
          organisation: obj,
          organisationId: organisation.id
        }
      });
    }
  };

  const updateCredentials = (values: any) => {
    if (!values.username || !values.password) {
      snackbar({
        message: "Username or Password is missing",
        variant: SnackBarVariant.ERROR
      })
    } else {
      onUpdateCreditSafeCredentials({
        variables: {
          creditSafeCreds: values
        }
      })
    }
  }

  const convergeCredentialsValidationSchema = Yup.object().shape({
    merchantAccountId: Yup.string().required("Required"),
    merchantAlias: Yup.string().required("Required"),
    merchantSecret: Yup.string().required("Required"),
  });

  if (orgLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={12} alignItems="center">
          <Typography variant="h1" color="primary">
            &nbsp;Payment Configuration
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Paper className={classes.root}>
            <Formik
              enableReinitialize
              initialValues={organisation}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitHandler(values)
                setSubmitting(false);
              }}
            >
              {(props) => (
                <Form noValidate>
                  <Grid container item xs={12}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <Field
                          component={TextField}
                          placeholder="Stripe Account ID"
                          label="Stripe Account ID"
                          name={"stripeAccountId"}
                          value={props.values.stripeAccountId}
                          onChange={props.handleChange}
                          fullWidth
                          disabled={userState.role === UserRoles.BRANCH_MANAGER}
                        ></Field>
                      </Grid>
                      <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="add"
                          onClick={initiateSageOauth}
                        >
                          Setup Sage
                        </Fab>
                      </Grid>
                      <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.cashEnabled ? true : false}
                              onClick={(e: any) => {
                                setOrganisation({
                                  ...organisation,
                                  cashEnabled: e.target.checked
                                })
                              }}
                              value="cashEnabled"
                            />
                          }
                          label={"Enable Cash Payments"}
                        />
                      </Grid>
                      <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.cardEnabled ? true : false}
                              onClick={(e: any) => {
                                setOrganisation({
                                  ...organisation,
                                  cardEnabled: e.target.checked
                                })
                              }}
                              value="cardEnabled"
                            />
                          }
                          label={"Enable Card Payments"}
                        />
                      </Grid>
                      <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.autoChargeEnabled ? true : false}
                              onClick={(e: any) => {
                                setOrganisation({
                                  ...organisation,
                                  autoChargeEnabled: e.target.checked
                                })
                              }}
                              value="autoChargeEnabled"
                            />
                          }
                          label={"Enable Auto Charge for Cards"}
                        />
                      </Grid>
                      <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.offlineBankTransferEnabled ? true : false}
                              onClick={(e: any) => {
                                setOrganisation({
                                  ...organisation,
                                  offlineBankTransferEnabled: e.target.checked
                                })
                              }}
                              value="offlineBankTransferEnabled"
                            />
                          }
                          label={"Enable Offline Bank Payments"}
                        />
                      </Grid>
                      <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.payByLinkEnabled ? true : false}
                              onClick={(e: any) => {
                                setOrganisation({
                                  ...organisation,
                                  payByLinkEnabled: e.target.checked
                                })
                              }}
                              value="payByLinkEnabled"
                            />
                          }
                          label={"Enable Pay By Link"}
                        />
                      </Grid>
                      <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.payOnCollectionEnabled ? true : false}
                              onClick={(e: any) => {
                                setOrganisation({
                                  ...organisation,
                                  payOnCollectionEnabled: e.target.checked
                                })
                              }}
                              value="payOnCollectionEnabled"
                            />
                          }
                          label={"Enable Pay on collection"}
                        />
                      </Grid>
                      {props.values.currency === "EUR" && <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.sepaEnabled ? true : false}
                              onClick={(e: any) => {
                                setOrganisation({
                                  ...organisation,
                                  sepaEnabled: e.target.checked
                                })
                              }}
                              value="sepaEnabled"
                            />
                          }
                          label={"Enable SEPA Direct Bank Debit"}
                        />
                      </Grid>}
                      {props.values.currency === "GBP" && <Grid
                        item
                        className={"new-form-update "}
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.bacsEnabled ? true : false}
                              onClick={(e: any) => {
                                setOrganisation({
                                  ...organisation,
                                  bacsEnabled: e.target.checked
                                })
                              }}
                              value="bacsEnabled"
                            />
                          }
                          label={"Enable BACS Direct Bank Debit"}
                        />
                      </Grid>
                      }
                      <Grid
                        item xs={12}
                        className={"new-form-update "}
                        container
                      >
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="add"
                          type="submit"
                          disabled={
                            loading ||
                            userState.role === UserRoles.BRANCH_MANAGER
                          }
                        >
                          {loading && <CircularProgress size={14} />}
                          Save
                        </Fab>
                      </Grid>
                    </Grid>

                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
      {userState.currentTenancy.creditCheckEnabled &&
        <Grid container spacing={2} style={{ marginTop: "40px" }}>
          <CssBaseline />
          <Grid container item xs={12} alignItems="center">
            <Typography variant="h1" color="primary">
              &nbsp;Credit Safe Configuration
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Paper className={classes.root} style={{ width: '100%' }}>
              <Formik
                enableReinitialize
                initialValues={creditSafeCreds}
                onSubmit={(values, { setSubmitting }) => {
                  updateCredentials(values)
                  setSubmitting(false);
                }}
              >
                {(props) => (
                  <Form noValidate>
                    <Grid container item xs={12} alignItems="center" className={classes.field}>
                      <Typography variant="h4" color="primary">
                        &nbsp;Please enter the credentials recieved from Credit Safe team to enable credit check process.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.field}
                      container
                      xs={12}
                    >
                      <Field
                        component={TextField}
                        placeholder="Username"
                        label="Username"
                        name={"username"}
                        value={creditSafeCreds.username}
                        onChange={(e: any) => {
                          setCreditSafeCreds({
                            ...creditSafeCreds,
                            username: e.target.value
                          })
                        }}
                        disabled={userState.role === UserRoles.BRANCH_MANAGER}
                      ></Field>
                    </Grid>
                    <Grid
                      item
                      className={classes.field}
                      container
                      xs={12}
                    >
                      <Field
                        component={TextField}
                        placeholder="Password"
                        label="Password"
                        name={"password"}
                        type="password"
                        value={creditSafeCreds.password}
                        onChange={(e: any) => {
                          setCreditSafeCreds({
                            ...creditSafeCreds,
                            password: e.target.value
                          })
                        }}
                        disabled={userState.role === UserRoles.BRANCH_MANAGER}
                      ></Field>
                    </Grid>
                    <Grid
                      item xs={12}
                      className={classes.field}
                      container
                    >
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                        disabled={
                          credLoading ||
                          userState.role === UserRoles.BRANCH_MANAGER
                        }
                      >
                        {credLoading && <CircularProgress size={14} />}
                        Save
                      </Fab>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      }
      <Grid container spacing={2} style={{ marginTop: "40px" }}>
          <CssBaseline />
          <Grid container item xs={12} alignItems="center">
            <Typography variant="h1" color="primary">
              &nbsp;Converge Configuration
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Paper className={classes.root} style={{ width: '100%' }}>
              <Formik
                enableReinitialize
                initialValues={organisation.convergeCredentials || convergeCredentials}
                validationSchema={convergeCredentialsValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const updatedCredentials = {
                    convergeCredentials: {
                      ...values
                    }
                  };
                  onSubmitHandler(updatedCredentials);
                  setSubmitting(false);
                }}
              >
                {(props) => (
                  <Form noValidate>
                    <Grid container item xs={12} alignItems="center" className={classes.field}>
                      <Typography variant="h4" color="primary">
                        &nbsp;Please enter the credentials received from Elavon team to use Converge as a payment gateway.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.field}
                      container
                      xs={12}
                    >
                      <Field
                        component={TextField}
                        label="Account Id"
                        name={"merchantAccountId"}
                        value={props.values.merchantAccountId}
                        onChange={props.handleChange}
                      ></Field>
                    </Grid>
                    <Grid
                      item
                      className={classes.field}
                      container
                      xs={12}
                    >
                      <Field
                        component={TextField}
                        label="Merchant Alias"
                        name={"merchantAlias"}
                        value={props.values.merchantAlias}
                        onChange={props.handleChange}
                      ></Field>
                    </Grid>
                    <Grid
                      item
                      className={classes.field}
                      container
                      xs={12}
                    >
                      <Field
                        component={TextField}
                        label="Secret Key"
                        name={"merchantSecret"}
                        value={props.values.merchantSecret}
                        onChange={props.handleChange}
                        InputProps={{
                          className: classes.hidePassword
                        }}
                      ></Field>
                    </Grid>
                    <Grid
                      item xs={12}
                      className={classes.field}
                      container
                    >
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                        disabled={credLoading}
                      >
                        {credLoading && <CircularProgress size={14} />}
                        Save
                      </Fab>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
      </Grid>
      {userState.currentTenancy.transfer360Enabled &&
        <Grid container spacing={2} style={{ marginTop: "40px" }}>
          <CssBaseline />
          <Integrations />
        </Grid>
      }
    </>
  );
};
