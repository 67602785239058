import { gql } from "@apollo/client";

export const UPDATE_ORGANISATION = gql`
  mutation UpdateOrganisation(
    $organisationId: ID!
    $organisation: OrganisationUpdateInput!
  ) {
    updateOrganisation(
      organisationId: $organisationId
      organisation: $organisation
    ) {
      id
      name
      emailId
      allowSubscriptionBookings
      allowPreSigningAgreement
      requiresBookingApproval
      closeGroupSharingEnabled
      allowCarSharing
      disableMapForCarShareVehicles
      inspectionCheckEnabled
      enableAutoCreditCheck
      crossLocationBookingEnabled
      creditLimits {
        type
        lowerLimit
        upperLimit
      }
      owner {
        email
        id
      }
      enableCostCenter
      costCenters {
        name
        projectIds
      }
      stripeAccountId
      sepaEnabled
      bacsEnabled
      cashEnabled
      offlineBankTransferEnabled
      payByLinkEnabled
      cardEnabled
      payOnCollectionEnabled
      autoChargeEnabled
      branches {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        useThresholds
        offenceThreshold
        pointThreshold
        emailId
        depositCollection
        phoneNumber {
          phone
          country
        }
        timeZone
        owner {
          id
          email
        }
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      currency
      locale
      phoneNumber {
        phone
        country
      }
      termsAndConditionsUrl
      logoUrl
      sageAccounting {
        active
      }
      orgVatNumber
      orgRegNumber
      insuranceDeclaration
      cancellationPolicy
      enableIdentityVerification
      businessCustomerTags
      maxImagesPerDamage
    }
  }
`;
export const UPDATE_ORGANISATION_WEBSITE = gql`
  mutation UpdateOrganisation(
    $organisationId: ID!
    $organisation: OrganisationUpdateInput!
  ) {
    updateOrganisation(
      organisationId: $organisationId
      organisation: $organisation
    ) {
      id
      name
      emailId
      owner {
        email
        id
      }
      allowSubscriptionBookings
      allowPreSigningAgreement
      requiresBookingApproval
      allowCarSharing
      disableMapForCarShareVehicles
      inspectionCheckEnabled
      enableAutoCreditCheck
      creditLimits {
        type
        lowerLimit
        upperLimit
      }
      stripeAccountId
      sepaEnabled
      bacsEnabled
      closeGroupSharingEnabled
      cashEnabled
      cardEnabled
      offlineBankTransferEnabled
      payByLinkEnabled
      payOnCollectionEnabled
      autoChargeEnabled
      branches {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        emailId
        phoneNumber {
          phone
          country
        }
        timeZone
        owner {
          id
          email
        }
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      currency
      locale
      phoneNumber {
        phone
        country
      }
      termsAndConditionsUrl
      logoUrl
      sageAccounting {
        active
      }
      orgVatNumber
      orgRegNumber
      insuranceDeclaration
      cancellationPolicy
    }
  }
`;

export const UPDATE_ACCOUNTING_CODE_MAPPING = gql`
   mutation updateAccountCodeMapping($id: String!, $accountCodeMapping:AccountCodeMappingInput!) {
    updateAccountCodeMapping(id: $id, accountCodeMapping: $accountCodeMapping) {
      id
      accountingName
      codeMapping{
        line_item
        code
      }
      tenancyId
      organisationId
    }
  }
`;
