import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_GROUP = gql`
  mutation updateVehicleGroup($vehicleGroup: UpdateVehicleGroupInput!) {
    updateVehicleGroup(vehicleGroup: $vehicleGroup) {
      id
      name
      criteria {
        type
        values
      }
      images{
        idx
        url
        default
      }
      vehicles {
        id
        bodyType
        make
        model
        year
        licencePlate
        transmission
        colour
        steering
        fuelType
        features
        wheelPlan
        cylinderCapacity
        numberOfSeats
        numberOfDoors
      }
      deposit
      basePrices {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
        mileageLimit
        pricePerExtraMile
      }
      isActivated
      yieldPricingEnabled
      yieldPriceSlabs {
        yieldThreshold
        rules {
          rateType
          value
          valueType
        }
      }
    }
  }
`;
