import { useLazyQuery, useMutation } from "@apollo/client";
import { Checkbox, Chip, CircularProgress, Collapse, createStyles, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, InputBase, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Slider, Switch, Theme, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { ApolloError } from "@apollo/client";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  CHANGE_APP_SETTING_STATUS,
  CREATE_WEBSITE_MUTATION,
  UPDATE_APP_SETTING_MUTATION
} from "../../../../graphql/consumerWebsite/websiteMutations";
import {
  GET_ORGANISATIONS,
  GET_APP_SETTING
} from "../../../../graphql/consumerWebsite/websiteQueries";
import { IOrganisation } from "../../../../reducers/organisation/types";
import { IAppState } from "../../../../store";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { checkDeleteOrDownloadFileType, checkUploadFileFormatAppSettings, formatGraphQLErrorMessage, PaymentGateway, uploadFileExtensionAndContentType } from "../../../common/utils";
import { BookingType, IAppSetting } from "../types";
import titleIconIllustration from "../../../../assets/images/title-icon-illustration.png";
import mobileAppIcon from "../../../../assets/images/app-icon.png";
import styles from "./index.module.css";
import ImageUpload from "./ImageUpload";
import { NuvvenDivider } from "../../ReservationManagement/Summary/Components/Divider";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getSignedUrl } from "../../../../utils/getSignedUrl";
import SplashScreenIcon from "../../../../assets/images/company-icon-1.png"
import CompanyIconForHeader from "../../../../assets/images/company-icon-2.png"
import StraplineIcon from "../../../../assets/images/strapline-icon.png"
import * as Yup from "yup";
import ConfirmToggleAppSetting from "./ConfirmToggleAppSetting";
import { withStyles } from "@mui/styles";
import { getLocalizedBookingSyntex, getLocalizedOrganisationSyntex } from "../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../utils/sentry";

const appSettingSInitials: IAppSetting = {
  appName: "",
  organisationId: "",
  branchIds: [],
  privacyPolicyURL: "",
  termsAndConditionsURL: "",
  branding: {
    accentColor: "",
    brandColors: false,
    buttonColor: ""
  },
  organisation: {
    name: ""
  },
  isActive: true,
  bookingTypes: [],
  companyWebsite: "",
  webAppSetting: {
    websiteTitle: "",
    websiteIcon: "",
    websiteLogo: "",
    domain: "",
    websiteHeader: "",
    websiteDescription: "",
    websiteAddress: "",
    logoSize: 40,
    htmlDescription: false,
    hasCarousel: false,
    carousel: [],
    hasGallery: false,
    gallery: [],
    enableBusinessCustomerSignup: false,
    paymentGateway: PaymentGateway.STRIPE
  },
  mobileAppSetting: {
    appIcon: "",
    companyIcon: "",
    landingPageIcon: "",
    straplineIcon: "",
  },
  allowedRateTypes: ["daily"],
  allowedBillingFrequencies: []
}

export const BOOKING_TYPE = [
  {
    label: "Rental Business",
    value: "RENTAL"
  },
  {
    label: "Subscription Business",
    value: "SUBSCRIPTION"
  }
]

const StyledInputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5
    },
    error: {
      "& input": {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
      },
      "& textarea": {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
      },
      "& .MuiSelect-select": {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        backgroundColor: "#fff !important"
      }
    },
    input: {
      borderRadius: "4px !important",
      position: "relative",
      backgroundColor: theme.palette.common.white + "!important",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "12px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      minHeight: "initial"
    }
  })
)(InputBase);

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 22,
      padding: 0,
      display: "flex"
    },
    switchBase: {
      padding: 4,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "var(--theme-primary)",
          borderColor: "var(--theme-primary)"
        }
      }
    },
    thumb: {
      width: 14,
      height: 14,
      boxShadow: "none"
    },
    track: {
      border: `1px solid ${theme.palette.grey[800]}`,
      borderRadius: 22 / 2,
      height: 20,
      opacity: 1,
      backgroundColor: theme.palette.common.white
    },
    checked: {}
  })
)(Switch);

interface IImageKeys {
  carousel: string[];
  gallery: string[];
  favIcon: string;
  websiteLogo: string;
  appIcon: string;
  companyIcon: string;
  straplineIcon: string;
  landingPageIcon: string;
}

const AppSetting: React.FC = () => {

  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const [isUpdate, setIsUpdate] = useState<boolean>(true);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);

  const [appSettingState, setAppSettingState] = useState<IAppSetting>({
    ...appSettingSInitials,
    branding: {
      brandColors: true,
      buttonColor: userState.currentTenancy.themeColor || "#FFC700",
      accentColor: userState.currentTenancy.themeColor || "#002F6E"
    }
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [imageKeys, setImageKeys] = useState<IImageKeys>({
    carousel: [],
    gallery: [],
    favIcon: "",
    websiteLogo: "",
    appIcon: "",
    companyIcon: "",
    straplineIcon: "",
    landingPageIcon: ""
  });

  const [organisations, setOrganisations] = useState<Partial<IOrganisation>[]>([]);
  const [uploadAppIconInProgress, setUploadAppIconInProgress] = useState<boolean>(false)
  const [uploadCompanyIconInProgress, setUploadCompanyIconInProgress] = useState<boolean>(false)
  const [uploadStraplineImageInProgress, setUploadStraplineImageInProgress] = useState<boolean>(false)
  const [uploadLandingImageInProgress, setUploadLandingImapeInProgress] = useState<boolean>(false)
  const [openAppSettingToggle, setOpenAppSettingToggle] = useState<boolean>(false)
  const [selecetdOrganisation, setSelectedOrganisation] = useState<Partial<IOrganisation>>()

  const [
    loadAppSetting,
    { loading: settingLoading, data: appSettingData }
  ] = useLazyQuery(GET_APP_SETTING, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.getConsumerAppSetting) {
        navigate("/apps");
      }
    },
    onError: (error: ApolloError) => {
      if (
        formatGraphQLErrorMessage(error.message) === "App does not exist"
      ) {
        navigate("/apps");
      }
    }
  });
  const [
    loadOrganisations,
    { data: organisationsData }
  ] = useLazyQuery(GET_ORGANISATIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.organisations) {
        navigate("/websites");
      }
    },
    onError: (error: ApolloError) => {
      if (formatGraphQLErrorMessage(error.message) === "Something went wrong") {
        navigate("/websites");
      }
    }
  });

  const [createAppSetting] = useMutation(
    CREATE_WEBSITE_MUTATION,
    {
      onCompleted: () => {
        snackbar({
          message: "App setting Created successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate("/apps");
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [updateAppSetting] = useMutation(
    UPDATE_APP_SETTING_MUTATION,
    {
      onCompleted: () => {
        snackbar({
          message: "App setting Updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate("/apps");
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [changeAppSettingStatus] = useMutation(CHANGE_APP_SETTING_STATUS, {
    onCompleted: ({ changeAppSettingStatus }) => {
      if (changeAppSettingStatus.isActive) {
        snackbar({
          message: formatGraphQLErrorMessage("App setting activated successfully"),
          variant: SnackBarVariant.SUCCESS
        });
      } else {
        snackbar({
          message: formatGraphQLErrorMessage("App setting deactivated successfully"),
          variant: SnackBarVariant.SUCCESS
        });
      }
      setAppSettingState({
        ...appSettingState,
        isActive: changeAppSettingStatus.isActive
      })
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  useEffect(() => {
    loadOrganisations();
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const appId = params.get("appId");
      if (appId) {
        loadAppSetting({
          variables: {
            appId
          }
        });
      } else {
        setIsUpdate(false);
      }
    } else {
      setIsUpdate(false);
      const tempCarouselArray: any[] = [];
      for (var i = 0; i < 5; i++) {
        tempCarouselArray.push("");
      }
      setImageKeys({
        ...imageKeys,
        carousel: tempCarouselArray
      })
    }
  }, [location]);

  useEffect(() => {
    if (appSettingData && appSettingData.getConsumerAppSetting && organisationsData && organisationsData.organisations.length) {
      let tempData: IAppSetting = {
        ...appSettingData.getConsumerAppSetting
      };
      const tempCarouselArray: any[] = [];
      for (var i = 0; i < 5; i++) {
        tempCarouselArray.push(tempData.webAppSetting.carousel[i] || "");
      }
      tempData = {
        ...tempData,
        appName: appSettingData.getConsumerAppSetting.appName,
        allowedBillingFrequencies: appSettingData.getConsumerAppSetting.allowedBillingFrequencies || [],
        bookingTypes: appSettingData.getConsumerAppSetting.bookingTypes || [],
        webAppSetting: {
          ...tempData.webAppSetting,
          hasCarousel: tempData.webAppSetting.hasCarousel || false,
          gallery: tempData.webAppSetting.gallery || [],
          carousel: tempCarouselArray,
          hasGallery: tempData.webAppSetting.hasGallery || false,
          websiteIcon: tempData.webAppSetting.websiteIcon || "",
          websiteLogo: tempData.webAppSetting.websiteLogo || "",
          websiteHeader: tempData.webAppSetting.websiteHeader || "",
          logoSize: tempData.webAppSetting.logoSize || 40,
          htmlDescription: tempData.webAppSetting.htmlDescription || false,
          paymentGateway: tempData.webAppSetting.paymentGateway || "",
        },
      }
      const appIcon = tempData.mobileAppSetting.appIcon || ""
      const companyIcon = tempData.mobileAppSetting.companyIcon || ""
      const straplineIcon = tempData.mobileAppSetting.straplineIcon || ""
      const landingPageIcon = tempData.mobileAppSetting.landingPageIcon || ""
      setImageKeys({
        carousel: tempData.webAppSetting.carousel,
        gallery: tempData.webAppSetting.gallery,
        favIcon: tempData.webAppSetting.websiteIcon,
        websiteLogo: tempData.webAppSetting.websiteLogo,
        appIcon,
        companyIcon,
        straplineIcon,
        landingPageIcon
      });
      const selectedOrg = organisationsData.organisations.find((org: Partial<IOrganisation>) => org.id === tempData.organisationId)
      setSelectedOrganisation(selectedOrg)
      setAppSettingState(tempData);
    }
  }, [appSettingData, organisationsData]);

  useEffect(() => {
    if (organisationsData && organisationsData.organisations) {
      setOrganisations(organisationsData.organisations);
    }
  }, [organisationsData]);

  const handleFormSubmit = (values: IAppSetting) => {
    delete values.organisation
    values.isActive = appSettingState.isActive
    const appSetting = { ...values };
    appSetting.allowedRateTypes = appSettingState.allowedRateTypes
    if (appSetting.id) {
      updateAppSetting({
        variables: {
          appId: appSetting.id,
          consumerAppSetting: appSetting
        }
      });
    } else {
      createAppSetting({
        variables: { consumerAppSetting: appSetting }
      });
    }
  };

  const onConfirmAppSettingStatus = () => {
    if (appSettingState.id) {
      changeAppSettingStatus({
        variables: {
          appId: appSettingState.id,
          isActive: !appSettingState.isActive
        }
      })
      setOpenAppSettingToggle(false)
    }
  }

  const handleSetFavIconKey = (key: string, index: number) => {
    setImageKeys({
      ...imageKeys,
      favIcon: key
    });
  };

  const handleSetLogoKey = (key: string, index: number) => {
    setImageKeys({
      ...imageKeys,
      websiteLogo: key
    });
  };

  const galleryImageUpload = async (e: any) => {
    var filesArr = Array.prototype.slice.call(e.target.files);
    const file = filesArr[0];
    let key: string = "";
    const uniqueId = uuidv4();
    if (file && file.type === "image/jpeg") {
      key = `${userState.tenancy.id}-${uniqueId}galleryImgUrl.jpeg`;
    } else if (file && file.type === "image/png") {
      key = `${userState.tenancy.id}-${uniqueId}galleryImgUrl.png`;
    } else if (file && file.type === "image/bmp") {
      key = `${userState.tenancy.id}-${uniqueId}galleryImgUrl.bmp`;
    } else {
      setTimeout(() => {
        return snackbar({
          message: "Invalid Image type. Supported types: .png, .jpeg, .bmp",
          variant: SnackBarVariant.ERROR
        });
      }, 2000);
    }
    key = `${userState.tenancy.id}/${key}`;
    setLoading(true);
    if (_e_) {
      await _e_.add({
        name: key,
        file: file,
        complete: async () => {
          setLoading(false);
          const tempKeyArray = [...imageKeys.gallery];
          tempKeyArray.push(key);
          setImageKeys({
            ...imageKeys,
            gallery: tempKeyArray
          });
        }
      });
    }
  };

  const validateCarousel = (hasCarousel: boolean) => {
    let error;
    const _imageKeys = imageKeys.carousel.filter((carousel) => carousel.length);
    if (hasCarousel && !_imageKeys.length) {
      error = "Add at least one image for carousel*";
    }
    return error;
  };

  const handleSetCarouselImageKey = (key: string, index: number) => {
    let tempKeyArray = [...imageKeys.carousel];
    tempKeyArray[index] = key;
    setImageKeys({
      ...imageKeys,
      carousel: tempKeyArray
    });
  };

  const validateGallery = (hasGallery: boolean) => {
    let error;
    if (hasGallery && imageKeys.gallery.length < 6) {
      error = "Minimun 6 images required*";
    }
    return error;
  };

  const handleSetGalleryImageKey = (key: string, index: number) => {
    let tempKeyArray = [...imageKeys.gallery];
    if (!key.length) {
      tempKeyArray.splice(index, 1);
    }
    setImageKeys({
      ...imageKeys,
      gallery: tempKeyArray
    });
  };

  async function downloadDocument(documentKey: string, title: string) {
    if (!userState.tenancy?.id) {
      return;
    }
    const { fileExtension } = checkDeleteOrDownloadFileType(
      documentKey
    );
    const file = await getSignedUrl(documentKey)
    const url: any = file;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileExtension ? `${title}.${fileExtension}` : title;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  async function uploadAppIconZipFile(
    file: File,
  ) {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      // 2MB limit exceeded
      if (file.size > 2000000) {
        throw new Error("File size exceeded limit of 2MB");
      }
      setUploadAppIconInProgress(true);
      if (checkUploadFileFormatAppSettings(file.type)) {
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const uniqueId = uuidv4();
        const key = `${userState.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setImageKeys({
                  ...imageKeys,
                  appIcon: key
                })
                snackbar({
                  message: "Document Added",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadAppIconInProgress(false);
              }
            });
        }
      } else {
        setUploadAppIconInProgress(false);
        return snackbar({
          message: "Please only upload .zip!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }
  async function uploadCompanyIcon(
    file: File,
  ) {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      // 10kb limit exceeded
      if (file.size > 10000) {
        throw new Error("Image size exceeded limit of 10KB");
      }
      setUploadCompanyIconInProgress(true);
      if (checkUploadFileFormatAppSettings(file.type)) {
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const uniqueId = uuidv4();
        const key = `${userState.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setImageKeys({
                  ...imageKeys,
                  companyIcon: key
                })
                snackbar({
                  message: "Image Uploaded.",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadCompanyIconInProgress(false);
              }
            });
        }
      } else {
        setUploadCompanyIconInProgress(false);
        return snackbar({
          message: "Please only upload .png format!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  async function uploadStraplineImage(
    file: File,
  ) {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      // 10kb limit exceeded
      if (file.size > 1000000) {
        throw new Error("Image size exceeded limit of 1MB");
      }
      setUploadStraplineImageInProgress(true);
      if (checkUploadFileFormatAppSettings(file.type)) {
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const uniqueId = uuidv4();
        const key = `${userState.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setImageKeys({
                  ...imageKeys,
                  straplineIcon: key
                })
                snackbar({
                  message: "Image Uploaded.",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadStraplineImageInProgress(false);
              }
            });
        }
      } else {
        setUploadStraplineImageInProgress(false);
        return snackbar({
          message: "Please only upload .png format!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  async function uploadLandingPageImage(
    file: File,
  ) {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      // 10kb limit exceeded
      if (file.size > 1000000) {
        throw new Error("Image size exceeded limit of 1MB");
      }
      setUploadLandingImapeInProgress(true);
      if (checkUploadFileFormatAppSettings(file.type)) {
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const uniqueId = uuidv4();
        const key = `${userState.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setImageKeys({
                  ...imageKeys,
                  landingPageIcon: key
                })
                snackbar({
                  message: "Image Uploaded.",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadLandingImapeInProgress(false);
              }
            });
        }
      } else {
        setUploadLandingImapeInProgress(false);
        return snackbar({
          message: "Please only upload .png format!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const handleRateTypeChange = (event: any, rateType: string) => {
    let rateTypes = [...(appSettingState.allowedRateTypes || [])]
    if (!event.target.checked) {
      rateTypes = rateTypes.filter(ele => ele !== rateType);
    } else {
      if (!rateTypes.includes(rateType)) {
        rateTypes.push(rateType);
      }
    }
    setAppSettingState({
      ...appSettingState,
      allowedRateTypes: rateTypes
    })
  }

  const ifRateTypeSelected = (rateType: string) => appSettingState.allowedRateTypes?.includes(rateType) || false;

  const validationSchema = Yup.object().shape({
    appName: Yup.string().required("App name is required"),
    organisationId: Yup.string().required("Organisation id name is required"),
    branchIds: Yup.array()
      .min(1)
      .of(Yup.string().min(1)),
    privacyPolicyURL: Yup.string().required("Privacy policy url is required"),
    termsAndConditionsURL: Yup.string().required("T&C url is required"),
    webAppSetting: Yup.object({
      websiteAddress: Yup.string().required("Website address is required"),
      websiteTitle: Yup.string().required("Required"),
      websiteLogo: Yup.string().required("Required"),
      websiteDescription: Yup.string().required("Required"),
      websiteIcon: Yup.string().required("Required"),
      paymentGateway: Yup.string().required("Required"),
    }),
    branding: Yup.object().shape({
      buttonColor: Yup.string().required("Required"),
      accentColor: Yup.string().required("Required")
    })
  });

  if (
    (isUpdate && settingLoading) ||
    (isUpdate && !organisations.length)
  ) {
    return <CircularProgress />;
  }
  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12} className={styles.headingNavigation}>
        <Typography variant="h3" style={{ color: "#002F6E", fontSize: 22, fontWeight: "bold" }}>
          <IconButton
            onClick={() => navigate("/apps")}
            edge="start"
            color="primary"
            aria-label="upload picture"
            component="span"
            size="large">
            <ArrowBackIcon style={{ fontWeight: "bold" }} />
          </IconButton>
          {isUpdate ? "Update App" : "New App"}
        </Typography>
      </Grid>
      <Formik
        initialValues={appSettingState}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          let bookingTypes;
          if (userState.currentOrganisation.allowSubscriptionBookings) {
            if (appSettingState.bookingTypes && !appSettingState.bookingTypes.length) {
              return snackbar({
                message: "Please choose at least one Business Categorization",
                variant: SnackBarVariant.ERROR
              });
            }
            bookingTypes = appSettingState.bookingTypes
          } else {
            bookingTypes = [BookingType.RENTAL]
          }
          let tempValues: IAppSetting = { ...values };
          tempValues = {
            ...tempValues,
            bookingTypes,
            webAppSetting: {
              ...tempValues.webAppSetting,
              websiteIcon: imageKeys.favIcon,
              websiteLogo: imageKeys.websiteLogo,
              carousel: imageKeys.carousel.filter(
                (carousel) => carousel.length
              ),
              gallery: imageKeys.gallery
            },
            mobileAppSetting: {
              ...tempValues.mobileAppSetting,
              appIcon: imageKeys.appIcon,
              companyIcon: imageKeys.companyIcon,
              landingPageIcon: imageKeys.landingPageIcon,
              straplineIcon: imageKeys.straplineIcon
            }
          }
          handleFormSubmit(tempValues);
        }}
      >
        {(formikProps) => (
          <Form style={{ flexGrow: 1 }} >
            <Paper style={{ padding: 30, maxWidth: "1280px", margin: "0 auto" }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6} md={6} lg={6} xl={6}><Typography className={styles.sectionHeading}>General Setting</Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6} container justifyContent="flex-end">
                  {
                    isUpdate && (
                      <Grid item xs={6} container justifyContent="flex-end">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={appSettingState.isActive ? true : false}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setOpenAppSettingToggle(true)
                              }}
                              name="isActive"
                              color="primary"
                            />
                          }
                          label={
                            <Typography variant="body1">
                              {appSettingState.isActive
                                ? "Active"
                                : "In-Active"}
                            </Typography>
                          }
                        />
                      </Grid>
                    )
                  }
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3} >
                  <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
                    App Name and address
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: "#5a5a5a" }}>
                    What will this website will be called and what is it’s web
                    address
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} md={8} lg={8} xl={8} container spacing={2}>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="appName">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Name *
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                          </Typography>
                          <StyledInputBase
                            {...field}
                            disabled={isUpdate}
                            placeholder="Company's example website"
                            fullWidth
                            error={touched && Boolean(error)}
                            value={formikProps.values.appName}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="organisationId">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            {`Select ${getLocalizedOrganisationSyntex(country)} *`}
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>
                                {`Select an ${getLocalizedOrganisationSyntex(country)}*`}
                              </span>
                            )}
                          </Typography>
                          <Select
                            {...field}
                            fullWidth
                            disabled={isUpdate}
                            input={<StyledInputBase />}
                            error={touched && Boolean(error)}
                            onChange={(e) => {
                              const org = organisations.find(org => org.id === e.target.value)
                              if (org) {
                                setSelectedOrganisation(org)
                              }
                              field.onChange(e);
                              formikProps.setFieldValue("branchIds", []);
                            }}
                          >
                            {_.sortBy(organisations, (organisation) =>
                              organisation.name?.toLowerCase()
                            ).map((organisation) => (
                              <MenuItem
                                key={organisation.id}
                                value={organisation.id}
                              >
                                {organisation.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="branchIds" multiple>
                      {({ field, meta: { touched, error } }: FieldProps) => {
                        const selectedOrg = organisations.find(
                          (organisation) =>
                            organisation.id === formikProps.values.organisationId
                        );
                        return (
                          <>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 700 }}
                            >
                              Select branches *
                              {touched && Boolean(error) && (
                                <span className={styles.errorText}>
                                  Select at least one branch*
                                </span>
                              )}
                            </Typography>
                            <Select
                              {...field}
                              fullWidth
                              multiple
                              value={field.value}
                              onChange={(event: SelectChangeEvent, child: React.ReactNode) => {
                                formikProps.setFieldValue(
                                  "branchIds",
                                  event.target.value,
                                  true
                                );
                              }}
                              input={<StyledInputBase />}
                              error={touched && Boolean(error)}
                              renderValue={(selected: any) => (
                                <div style={{ whiteSpace: "break-spaces" }}>
                                  {(selected as string[]).map((value) => {
                                    const thisBranch = selectedOrg?.branches?.find(
                                      (branch) => branch.id === value
                                    );
                                    return value.length ? (
                                      <Chip
                                        key={value}
                                        label={thisBranch?.name || ""}
                                        style={{ borderRadius: 5, margin: 3 }}
                                      />
                                    ) : null;
                                  })}
                                </div>
                              )}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "center",
                                  horizontal: "left"
                                }
                              }}
                            >
                              {_.sortBy(selectedOrg?.branches, (branch) =>
                                branch.name.toLowerCase()
                              ).map((branch) => (
                                <MenuItem key={branch.id} value={branch.id}>
                                  <Checkbox
                                    checked={field.value.indexOf(branch.id) > -1}
                                    size="small"
                                  />
                                  <ListItemText primary={branch.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="branding.accentColor">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Accent Colour *
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                          </Typography>
                          <StyledInputBase
                            fullWidth
                            placeholder="Put the hex code here"
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <div
                                  className={styles.colorPeek}
                                  style={{ background: field.value }}
                                ></div>
                              </InputAdornment>
                            }
                            error={touched && Boolean(error)}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="branding.buttonColor">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Button colour *
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                          </Typography>
                          <StyledInputBase
                            fullWidth
                            placeholder="Put the hex code here"
                            {...field}
                            startAdornment={
                              <InputAdornment position="start">
                                <div
                                  className={styles.colorPeek}
                                  style={{ background: field.value }}
                                ></div>
                              </InputAdornment>
                            }
                            error={touched && Boolean(error)}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="companyWebsite">
                      {({ field }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Company's website
                          </Typography>
                          <StyledInputBase
                            {...field}
                            placeholder="example.com"
                            fullWidth
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="privacyPolicyURL">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Privacy Policy URL *
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>
                                Required*
                              </span>
                            )}
                          </Typography>
                          <StyledInputBase
                            {...field}
                            placeholder="link the privacy policy URL here"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="termsAndConditionsURL">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Terms and Conditions URL *
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>
                                Required*
                              </span>
                            )}
                          </Typography>
                          <StyledInputBase
                            {...field}
                            placeholder="link the terms and conditions URL here"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} xl={12}>
                    <FormControl style={{ flexDirection: "row" }}>
                      <Typography variant="h6" style={{ fontWeight: 700, padding: "15px 15px 15px 0px" }}>
                        Allowed Rate Types:
                      </Typography>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox checked={ifRateTypeSelected("hourly")} onChange={(e) => handleRateTypeChange(e, "hourly")} name="hourly" />}
                          label={"Hourly"}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={ifRateTypeSelected("daily")} onChange={(e) => handleRateTypeChange(e, "daily")} name="daily" />}
                          label={"Daily"}
                          disabled={true}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={ifRateTypeSelected("weekly")} onChange={(e) => handleRateTypeChange(e, "weekly")} name="weekly" />}
                          label={"Weekly"}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={ifRateTypeSelected("monthly")} onChange={(e) => handleRateTypeChange(e, "monthly")} name="monthly" />}
                          label={"Monthly"}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {
                    selecetdOrganisation?.allowSubscriptionBookings && (
                      <Grid item xs={12} >
                        <FormGroup row>
                          <Typography variant="h6" style={{ fontWeight: 700, padding: "15px 15px 15px 0px" }}>
                            Business Categorization:
                          </Typography>
                          {
                            BOOKING_TYPE.map(type => {
                              return <FormControlLabel
                                key={type.value}
                                control={
                                  <Checkbox
                                    checked={appSettingState.bookingTypes.includes(type.value)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      if (event.target.checked) {
                                        setAppSettingState({
                                          ...appSettingState,
                                          bookingTypes: [
                                            ...appSettingState.bookingTypes,
                                            event.target.value
                                          ]
                                        })
                                      } else {
                                        const updatedValues = appSettingState.bookingTypes.filter(val => val !== event.target.value)
                                        setAppSettingState({
                                          ...appSettingState,
                                          bookingTypes: updatedValues
                                        });
                                      }
                                    }}
                                    name={type.label}
                                    color="primary"
                                    value={type.value}
                                  />
                                }
                                label={type.label}
                              />
                            })
                          }

                        </FormGroup>
                      </Grid>
                    )
                  }
                </Grid>
                <Grid item xs={12}><NuvvenDivider /></Grid>
                <Grid item xs={12}><Typography className={styles.sectionHeading}>Payment Setting</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3} >
                  <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
                    Payment Gateway
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: "#5a5a5a" }}>
                    Select the Payment Gateway to use on the App
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} md={8} lg={8} xl={8} container spacing={2}>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="webAppSetting.paymentGateway">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            {`Select Payment Gateway *`}
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>
                                {`Required`}
                              </span>
                            )}
                          </Typography>
                          <Select
                            {...field}
                            fullWidth
                            input={<StyledInputBase />}
                            error={touched && Boolean(error)}
                          >
                            {Object.keys(PaymentGateway).filter((type) => type !== PaymentGateway.OFFLINE).map((paymentGateway) => (
                              <MenuItem
                                key={paymentGateway}
                                value={paymentGateway}
                              >
                                {_.capitalize(paymentGateway)}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12}><NuvvenDivider /></Grid>
                <Grid item xs={12}><Typography className={styles.sectionHeading}>Website Setting</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} xl={3} >
                  <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
                    Branding and colours
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: "#5a5a5a" }}>
                    Reflect your brand on the website.
                    Website Title and Icon appears on the top of the website. See
                    the illustration below here.
                  </Typography>
                  <img
                    className={styles.hintIllustration}
                    src={titleIconIllustration}
                    alt="title"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} md={8} lg={8} xl={8} container spacing={2}>
                  <Grid item xs={12} lg={6} md={6} xl={6} >
                    <Field name="webAppSetting.websiteAddress">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Web Address *
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                          </Typography>
                          <StyledInputBase
                            {...field}
                            disabled={isUpdate}
                            placeholder="booking.example.com"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="webAppSetting.websiteHeader">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Website header
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                          </Typography>
                          <StyledInputBase
                            {...field}
                            multiline
                            minRows={4}
                            placeholder="Enter text or HTML"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Field name="webAppSetting.websiteTitle">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 700 }}
                          >
                            Website Title *
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                          </Typography>
                          <StyledInputBase
                            {...field}
                            placeholder="Website title"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} xl={12}>
                    <Field name="webAppSetting.websiteLogo">
                      {({ meta: { touched, error } }: FieldProps) => (
                        <Typography variant="h6" style={{ fontWeight: 700 }}>
                          Website Logo *
                          {touched && Boolean(error) && (
                            <span className={styles.errorText}>Required*</span>
                          )}
                        </Typography>
                      )}
                    </Field>
                    <Typography
                      variant="subtitle2"
                      style={{ margin: "4px 0 8px 0", color: "#5a5a5a" }}
                    >
                      Upload a logo for the website
                    </Typography>
                    <div
                      style={{
                        width: formikProps.values.webAppSetting.logoSize + 45,
                        height: formikProps.values.webAppSetting.logoSize,
                        display: "flex"
                      }}
                    >
                      <ImageUpload
                        isFavIcon
                        logo
                        setImageKey={(key: string, index: number) => {
                          formikProps.setFieldValue("webAppSetting.websiteLogo", key);
                          handleSetLogoKey(key, index);
                        }}
                        imageKey={imageKeys.websiteLogo}
                        imageIndex={0}
                        tenantId={userState.tenancy.id}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} xl={12}>
                    <Typography variant="h6" style={{ fontWeight: 700 }}>
                      Logo Size *
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ margin: "4px 0 8px 0", color: "#5a5a5a" }}
                    >
                      Set how big the logo should look on the website.
                    </Typography>
                    <div
                      style={{
                        maxWidth: 250,
                        padding: "8px 20px",
                        borderRadius: 8,
                        backgroundColor: "#f5f5f5",
                        display: "flex"
                      }}
                    >
                      <Field name="webAppSetting.websiteTitle">
                        {({ field }: FieldProps) => (
                          <Slider
                            min={34}
                            max={85}
                            value={formikProps.values.webAppSetting.logoSize}
                            onChange={(event: any, val: any) =>
                              formikProps.setFieldValue("webAppSetting.logoSize", val)
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} xl={12}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 700 }}
                    >
                      Top bar preview
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ margin: "4px 0 0 0", color: "#5a5a5a" }}
                    >
                      Based on the settings above this is how Top bar on the
                      website will look
                    </Typography>
                    <div
                      className={styles.topBar}
                      style={{
                        backgroundColor: formikProps.values.branding.accentColor,
                        height:
                          formikProps.values.webAppSetting.logoSize > 50
                            ? formikProps.values.webAppSetting.logoSize + 20
                            : 70
                      }}
                    >
                      <div className={styles.topBarWrapper}>
                        <div
                          className={styles.topBarLogo}
                          style={{ height: formikProps.values.webAppSetting.logoSize }}
                        >
                          <div style={{ height: "100%", display: "flex" }}>
                            <ImageUpload
                              hideControls
                              isFavIcon
                              logo
                              setImageKey={handleSetLogoKey}
                              imageKey={imageKeys.websiteLogo}
                              imageIndex={0}
                              tenantId={userState.tenancy.id}
                            />
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: 20, fontWeight: 700 }}
                          dangerouslySetInnerHTML={{
                            __html: formikProps.values.webAppSetting.websiteHeader
                          }}
                        ></div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} xl={12}>
                    <Field name="webAppSetting.websiteIcon">
                      {({ meta: { touched, error } }: FieldProps) => (
                        <Typography variant="h6" style={{ fontWeight: 700 }}>
                          Website Icon *
                          {touched && Boolean(error) && (
                            <span className={styles.errorText}>Required*</span>
                          )}
                        </Typography>
                      )}
                    </Field>
                    <Typography
                      variant="subtitle2"
                      style={{ margin: "4px 0 8px 0", color: "#5a5a5a" }}
                    >
                      Please upload square image with size upto 200px X 200px. See
                      how to{" "}
                      <a href="https://resizeyourimage.com" target="_blank">
                        resize an image
                      </a>
                    </Typography>
                    <div style={{ width: 130, height: 85 }}>
                      <ImageUpload
                        isFavIcon
                        setImageKey={(key: string, index: number) => {
                          formikProps.setFieldValue("webAppSetting.websiteIcon", key);
                          handleSetFavIconKey(key, index)
                        }}
                        imageKey={imageKeys.favIcon}
                        imageIndex={0}
                        tenantId={userState.tenancy.id}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} xl={12}>
                    <span className="flex fill cross-center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={appSettingState.webAppSetting.enableBusinessCustomerSignup}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setAppSettingState((prevState) => ({
                                ...prevState,
                                webAppSetting: {
                                  ...prevState.webAppSetting,
                                  enableBusinessCustomerSignup: e.target.checked,
                                },
                              }));
                              formikProps.setFieldValue("webAppSetting.enableBusinessCustomerSignup", e.target.checked);
                            }}
                          />
                        }
                        labelPlacement="end"
                        label='Enable Business Customer SignUp'
                      />
                    </span>
                  </Grid>
                </Grid>
                <Grid item xs={12}><NuvvenDivider /></Grid>
                <Grid item xs={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
                    Content on website
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: "#5a5a5a" }}>
                    Content to show on the landing of the {getLocalizedBookingSyntex(country).toLowerCase()} website
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 700, display: "flex" }}
                    >
                      <span className="padding-right margin-right">
                        Website description *
                      </span>
                      <span className="flex fill cross-center">
                        <Field name="webAppSetting.htmlDescription">
                          {({ field }: FieldProps) => (
                            <AntSwitch checked={field.value} {...field} />
                          )}
                        </Field>
                        <span className="padding-left">Enable HTML</span>
                      </span>
                      {formikProps.touched.webAppSetting?.websiteDescription &&
                        Boolean(formikProps.errors.webAppSetting?.websiteDescription) && (
                          <span className={styles.errorText}>Required*</span>
                        )}
                    </Typography>
                    <Field name="webAppSetting.websiteDescription">
                      {({ field, meta: { touched, error } }: FieldProps) => (
                        <StyledInputBase
                          {...field}
                          multiline
                          minRows={4}
                          placeholder={
                            formikProps.values.webAppSetting.htmlDescription
                              ? "Enter HTML here"
                              : "Website description"
                          }
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                      )}
                    </Field>
                    <Collapse in={formikProps.values.webAppSetting.htmlDescription}>
                      <div
                        style={{
                          border: "1px solid #e2e2e2",
                          borderRadius: 8,
                          overflow: "hidden"
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            background: "#f9f9f9",
                            fontSize: 12
                          }}
                        >
                          <Typography variant="subtitle2">HTML Output</Typography>
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#5a5a5a" }}
                          >
                            Note: Please style your HTML with devices of all sizes
                            in mind.
                          </Typography>
                        </div>
                        <div
                          style={{ padding: 10, minHeight: 60 }}
                          dangerouslySetInnerHTML={{
                            __html: formikProps.values.webAppSetting.websiteDescription
                          }}
                        ></div>
                      </div>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <Field name="webAppSetting.hasCarousel">
                        {({ field }: FieldProps) => (
                          <AntSwitch checked={field.value} {...field} />
                        )}
                      </Field>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: 700, marginLeft: 10 }}
                      >
                        Show Carousel
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className="flex fill main-end"
                      >
                        {formikProps.values.webAppSetting.hasCarousel &&
                          !imageKeys.carousel.filter((carousel) => carousel.length)
                            .length ? (
                          <span style={{ color: "#c71717" }}>
                            Add atleast one image*
                          </span>
                        ) : null}
                      </Typography>
                    </div>
                    <div style={{ display: "none" }}>
                      <Field
                        name="webAppSetting.carousel"
                        validate={() =>
                          validateCarousel(formikProps.values.webAppSetting.hasCarousel)
                        }
                      ></Field>
                    </div>
                    <Typography
                      variant="subtitle2"
                      style={{ margin: "4px 0 8px 0", color: "#5a5a5a" }}
                    >
                      Carousel on the website can be used to display additional info
                      like promotions
                    </Typography>
                    <Collapse in={formikProps.values.webAppSetting.hasCarousel}>
                      <Typography
                        variant="subtitle2"
                        style={{ margin: "4px 0 8px 0", color: "#5a5a5a" }}
                      >
                        (Minimum Dimensions = 1200x450, Aspect Ratio = 8:3). See how
                        to{" "}
                        <a href="https://resizeyourimage.com" target="_blank">
                          resize an image
                        </a>
                      </Typography>
                      <Grid container spacing={2}>
                        {imageKeys.carousel.map((imageKey, index) => (
                          <Grid key={index} item xs={12} lg={4} style={{ height: 105 }}>
                            <ImageUpload
                              setImageKey={handleSetCarouselImageKey}
                              imageKey={imageKey}
                              imageIndex={index}
                              tenantId={userState.tenancy.id}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Collapse>

                  </Grid>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div style={{ marginTop: 30, display: "flex" }}>
                      <Field name="webAppSetting.hasGallery">
                        {({ field }: FieldProps) => (
                          <AntSwitch checked={field.value} {...field} />
                        )}
                      </Field>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: 700, marginLeft: 10 }}
                      >
                        Show Vehicle Gallery
                      </Typography>
                    </div>
                    <div style={{ display: "none" }}>
                      <Field
                        name="gallery"
                        validate={() =>
                          validateGallery(formikProps.values.webAppSetting.hasGallery)
                        }
                      ></Field>
                    </div>
                    <Collapse in={formikProps.values.webAppSetting.hasGallery}>
                      <Typography
                        variant="subtitle2"
                        style={{ margin: "4px 0 8px 0", color: "#5a5a5a" }}
                      >
                        Add vehicle images to appear on the vehicle gallery.
                        Minimum:6
                        {imageKeys.gallery.length < 6 ? (
                          <span style={{ float: "right", color: "#c71717" }}>
                            Minimum 6 images required*
                          </span>
                        ) : null}
                      </Typography>
                      <Grid container spacing={2} style={{ marginTop: 10 }}>
                        {imageKeys.gallery.map((imageKey, index) => (
                          <div key={index} style={{ height: 105, width: 150, margin: 10 }}>
                            <ImageUpload
                              isGallery
                              setImageKey={handleSetGalleryImageKey}
                              imageKey={imageKey}
                              imageIndex={index}
                              tenantId={userState.tenancy.id}
                            />
                          </div>
                        ))}
                        <div
                          style={{
                            height: 105,
                            width: 105,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 10,
                            borderRadius: 10,
                            backgroundColor: "#f7f7f7"
                          }}
                        >
                          <input
                            accept="image/*"
                            id="aaaaa"
                            type="file"
                            onChange={(e: any) => {
                              galleryImageUpload(e);
                            }}
                            style={{ display: "none" }}
                          />
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <label htmlFor="aaaaa">
                              <IconButton color="primary" aria-label="upload picture" component="span" size="large">
                                <AddAPhotoIcon style={{ fontSize: 22 }} />
                              </IconButton>
                            </label>
                          )}
                        </div>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
                <Grid item xs={12}><NuvvenDivider /></Grid>
                <Grid item xs={12}><Typography className={styles.sectionHeading}>Mobile App Setting</Typography>
                </Grid>
                {/* App Icon */}
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"} style={{ color: "#5a5a5a" }}>The image will be used as the App Icon as shown in the below photo, by using the EasyAppIcon tool it will generate all the required sizes.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <img src={mobileAppIcon} className={styles.hintIllustration} alt="app-icon" />
                  </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <Grid item xs={12} container >
                    <Grid item xs={12}>
                      <Typography variant="h4">App Icon</Typography>
                      <Typography variant="subtitle2" style={{ color: "#5a5a5a" }}>
                        Please create app icons using this website EasyAppIcon <a href="https://easyappicon.com/" target="_blank">EasyAppIcon - Create Mobile App Icon</a> Create Mobile App Icon  and then share the zip file.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container >
                      <Grid item xs={6} >
                        <Field name="mobileAppSetting.appIcon">
                          {({ meta: { touched, error } }: FieldProps) => (
                            <Typography variant="h6" style={{ fontWeight: 700 }}>
                              {touched && Boolean(error) && (
                                <span className={styles.errorText}>Required*</span>
                              )}
                              <input
                                accept="application/zip"
                                id="upload-zip-icon"
                                type="file"
                                onChange={(e: any) => {
                                  const file = e.target.files[0]
                                  uploadAppIconZipFile(file)
                                  e.target.value = null
                                }}
                                style={{ display: "none" }}
                              />
                              {uploadAppIconInProgress ? (
                                <CircularProgress />
                              ) : (
                                <label htmlFor="upload-zip-icon">
                                  <IconButton color="primary" aria-label="upload picture" component="span" size="large">
                                    <CloudUploadIcon />
                                  </IconButton>
                                  <span style={{ color: "#000" }}>Please only upload .zip file</span>
                                </label>
                              )}
                            </Typography>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6} container justifyContent="flex-end" alignItems="center" >
                        <span style={{ color: "rgb(0, 0, 0)", fontSize: 14, fontWeight: 700, marginRight: 5 }}>Download</span>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            downloadDocument(imageKeys.appIcon, "appiconszip.zip")
                          }}
                          disabled={!imageKeys.appIcon}
                          size="large">
                          <CloudDownloadIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}><NuvvenDivider noMargin /></Grid>
                {/* Company Icon */}
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"} style={{ color: "#5a5a5a" }}>This image will be used in the bootsplash loading screen as per below:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <img src={SplashScreenIcon} className={styles.hintIllustration} alt="app-icon" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"} style={{ color: "#5a5a5a" }}>It will also be used in your company header as per below:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <img src={CompanyIconForHeader} className={styles.hintIllustration} alt="app-icon" />
                  </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <Grid item xs={12}>
                    <Typography variant="h4"> Company Icon</Typography>
                    <ul className={styles.companyIconRequirements}>
                      <li>Dark variant, as the image will displayed on white background</li>
                      <li>Simple logo with little text looks best here as will be used in the company header as above</li>
                      <li>PNG format</li>
                      <li>Image above is 159 × 174 however if your logo is larger than this then does not matter, it’s much easier to start with a large size and go smaller, rather than trying to expand one that is too small. Please ensure the logo has similar proportions to one highlighted above and is no larger than 10kb.</li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} container >
                    <Grid item xs={6} >
                      <Field name="mobileAppSetting.appLogo">
                        {({ meta: { touched, error } }: FieldProps) => (
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                            <input
                              accept="image/png"
                              id="upload-company-icon"
                              type="file"
                              onChange={(e: any) => {
                                const file = e.target.files[0]
                                uploadCompanyIcon(file)
                                e.target.value = null
                              }}
                              style={{ display: "none" }}
                            />
                            {uploadCompanyIconInProgress ? (
                              <CircularProgress />
                            ) : (
                              <label htmlFor="upload-company-icon">
                                <IconButton color="primary" aria-label="upload picture" component="span" size="large">
                                  <CloudUploadIcon />
                                </IconButton>
                                <span style={{ color: "#000" }}>Upload Image</span>
                              </label>
                            )}
                          </Typography>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end" alignItems="center" >
                      <span style={{ color: "rgb(0, 0, 0)", fontSize: 14, fontWeight: 700, marginRight: 5 }}>Download</span>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          downloadDocument(imageKeys.companyIcon, "company-icon.png")
                        }}
                        disabled={!imageKeys.companyIcon}
                        size="large">

                        <CloudDownloadIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}><NuvvenDivider noMargin /></Grid>
                {/* Strapline Icon */}
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"} style={{ color: "#5a5a5a" }}>This image will appear at the top of the login module on the login screen as header and is used to represent your company as below: </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <img src={StraplineIcon} className={styles.hintIllustration} alt="app-icon" />
                  </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Strapline Icon</Typography>
                    <ul className={styles.companyIconRequirements}>
                      <li>Dark variant, as the image will displayed on white background </li>
                      <li>A more detailed logo than your company Icon, can include a strapline as per example</li>
                      <li>PNG format</li>
                      <li>Image used above is 221 × 83, as a general rule please try to make sure no larger than 320px wide, and 70–100px high.</li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} container >
                    <Grid item xs={6} >
                      <Field name="mobileAppSetting.straplineIcon">
                        {({ meta: { touched, error } }: FieldProps) => (
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                            <input
                              accept="image/*"
                              id="upload-strapline-image"
                              type="file"
                              onChange={(e: any) => {
                                const file = e.target.files[0]
                                uploadStraplineImage(file)
                                e.target.value = null
                              }}
                              style={{ display: "none" }}
                            />
                            {uploadStraplineImageInProgress ? (
                              <CircularProgress />
                            ) : (
                              <label htmlFor="upload-strapline-image">
                                <IconButton color="primary" aria-label="upload picture" component="span" size="large">
                                  <CloudUploadIcon />
                                </IconButton>
                                <span style={{ color: "#000" }}>Upload Image</span>
                              </label>
                            )}
                          </Typography>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end" alignItems="center" >
                      <span style={{ color: "rgb(0, 0, 0)", fontSize: 14, fontWeight: 700, marginRight: 5 }}>Download</span>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          downloadDocument(imageKeys.straplineIcon, "strapline-image.png")
                        }}
                        disabled={!imageKeys.straplineIcon}
                        size="large">

                        <CloudDownloadIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}><NuvvenDivider noMargin /></Grid>
                {/* Authentication Landing Page Image */}
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"} style={{ color: "#5a5a5a" }}>This image is required on the Authentication Landing page. Image can contain your logo but it is not necessary. Make sure not to have the logo towards the bottom of the image otherwise it will get covered with the buttons for ‘New {getLocalizedBookingSyntex(country)}’ and ‘Log in’. </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Authentication Landing Page Image</Typography>
                    <ul className={styles.companyIconRequirements}>
                      <li>Can contain your logo </li>
                      <li>PNG format</li>
                      <li>We recommend a size of around 640x960 given this will be used a phone wall paper however as mentioned previously there is a degree of flexibility to this.</li>
                      <li>As per image above, it will be used as a log in page and thus will have buttons placed on top of it, given this would recommend logo placement centered horizontally with a little bit of padding on the top as per below.</li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} container >
                    <Grid item xs={6} >
                      <Field name="mobileAppSetting.landingPageIcon">
                        {({ meta: { touched, error } }: FieldProps) => (
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            {touched && Boolean(error) && (
                              <span className={styles.errorText}>Required*</span>
                            )}
                            <input
                              accept="image/*"
                              id="upload-landing-image"
                              type="file"
                              onChange={(e: any) => {
                                const file = e.target.files[0]
                                uploadLandingPageImage(file)
                                e.target.value = null
                              }}
                              style={{ display: "none" }}
                            />
                            {uploadLandingImageInProgress ? (
                              <CircularProgress />
                            ) : (
                              <label htmlFor="upload-landing-image">
                                <IconButton color="primary" aria-label="upload picture" component="span" size="large">
                                  <CloudUploadIcon />
                                </IconButton>
                                <span style={{ color: "#000" }}>Upload Image</span>
                              </label>
                            )}
                          </Typography>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end" alignItems="center" >
                      <span style={{ color: "rgb(0, 0, 0)", fontSize: 14, fontWeight: 700, marginRight: 5 }}>Download</span>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          downloadDocument(imageKeys.landingPageIcon, "landing-page-image.png")
                        }}
                        disabled={!imageKeys.landingPageIcon}
                        size="large">
                        <CloudDownloadIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div style={{ padding: 40, textAlign: "right" }}>
                  <Fab variant="extended" size="medium" type="submit">
                    <strong>Save Setting</strong>
                  </Fab>
                </div>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
      {
        openAppSettingToggle && (
          <ConfirmToggleAppSetting
            onClose={() => setOpenAppSettingToggle(false)}
            onConfirm={onConfirmAppSettingStatus}
            open={openAppSettingToggle}
            isActive={appSettingState.isActive}
          />
        )
      }
    </Grid >
  );
};

export default AppSetting;
