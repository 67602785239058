
import React, { useEffect, useState } from 'react'
import { ISubscriptionInfo, IVehicle } from '../../../../../reducers/fleet/types';
import { ISubscriptionArgs } from '../../../../../reducers/bookings/types';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { createStyles, makeStyles } from '@mui/styles';
import { Card, CardActions, CardContent, CardHeader, Fab, Grid, IconButton, Theme, Typography } from '@mui/material';
import { capitalizeFirstLetter, RATE_TYPES } from '../../utils';
import { getFeatureTypeIcon, getVehicleTypeIcon, toCurrency } from '../../../../common/utils';
import { IAppState } from '../../../../../store';
import { useSelector } from 'react-redux';
import SelectSubscriptionPricingModal from './SelectSubscriptionPricingModal';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { getSignedUrl } from '../../../../../utils/getSignedUrl';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 0,
      backgroundSize: "contain",
      paddingTop: "25%",
      marginTop: "2rem"
    },
    cardHeader: {
      background: "#55c2da",
      color: "#fff",
      textAlign: "center"
    },
    actionBtn: {
      backgroundImage: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)"
    },
    subButn: {
      background: "none",
      border: "none",
      boxShadow: "none",
      fontSize: 16,
      color: "#fff",
      width: "100%",
      height: 0,
      "&:hover": {
        background: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)",
      }
    }
  }),
);

interface IProps {
  subscriptionVehicles: IVehicle[];
  bookingDurationInMonths: number;
  bookingDurationInWeeks: number;
  onSelectSubscription: (subscription: ISubscriptionArgs, vehicle: IVehicle) => void;
}

const SelectSubscriptionVehiclesView: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { locale, currency } = userState.currentOrganisation;

  const [open, setOpen] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [vehicleImages, setVehicleImages] = useState<any>([]);
  const [vehicles, setVehicles] = useState<IVehicle[]>([])

  useEffect(() => {
    if (props.subscriptionVehicles && props.subscriptionVehicles.length) {
      getVehicleImage(props.subscriptionVehicles)
      setVehicles(props.subscriptionVehicles)
    }
  }, [props.subscriptionVehicles])

  const getVehicleImage = async (vehicles: IVehicle[]) => {
    try {
      const imagePromises = vehicles.map(async (vehicle) => {
        if (vehicle && vehicle.imageUrl) {
          try {
            const config = { contentType: 'image/*', level: 'public' };
            const file = await getSignedUrl(vehicle.imageUrl)
            return {
              vehicleId: vehicle.id,
              imageUrl: file,
            };
          } catch (urlError) {
            snackbar({
              message: 'Error fetching signed URL',
              variant: SnackBarVariant.ERROR,
            });
            return null;
          }
        }
        return null;
      });
      const resolvedImages = await Promise.all(imagePromises);
      const validImages = resolvedImages.filter((image) => image !== null);
      setVehicleImages(validImages)
    } catch (error) {
      snackbar({
        message: "Invalid File Type. Allowed type: jpeg or png",
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const getLowestSubscriptionPricing = (vehicles: IVehicle[]) => {
    const subscriptions: ISubscriptionInfo[] = [];
    vehicles.forEach(vehicle => {
      if (vehicle.subscriptions?.length) {
        subscriptions.push(...vehicle.subscriptions)
      }
    })
    let pricings: any[] = [];
    subscriptions.forEach(subscription => {
      if (subscription.subscription.isActive) {
        subscription.pricings.forEach(pricing => {
          if ((subscription.subscription.rateTypeName === RATE_TYPES.MONTHLY && pricing.duration <= props.bookingDurationInMonths) || (subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY && pricing.duration <= props.bookingDurationInWeeks)) {
            pricings.push(pricing)
          }
        })
      }
    })

    const lowestPricing = Math.min(...pricings.map(({ subscriptionAmount }) => subscriptionAmount));
    return lowestPricing
  }
  return (
    <Grid
      container
      item
      sm={11}
      style={{
        marginLeft: "20px",
        marginTop: "20px",
      }}
      spacing={2}
    >
      {
        vehicles && vehicles.length > 0 &&
        vehicles.map((vehicle: IVehicle, index: number) => {
          if (vehicleImages && vehicleImages.length) {
            vehicleImages.forEach((vehicleImage: any, index: number) => {
              if (vehicleImage.imageUrl && vehicleImage.vehicleId === vehicle.id) {
                vehicle = {
                  ...vehicle,
                  imageUrl: vehicleImage.imageUrl
                }
              }
            });
          }
          if (vehicle && vehicle.subscriptions.length) {
            const lowestPrice = getLowestSubscriptionPricing([vehicle])
            if (lowestPrice !== Infinity) {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                >
                  <Card
                    style={{ borderRadius: 14 }}
                  >
                    <CardHeader
                      className={classes.cardHeader}
                      action={
                        <IconButton aria-label="settings">

                        </IconButton>
                      }
                      title={<Typography variant='h4' style={{ fontSize: 20 }}>{`${vehicle.make.toUpperCase()} ${vehicle.model.toUpperCase()} - ${vehicle.licencePlate}`}</Typography>}
                      subheader={<Typography variant='caption'></Typography>}
                    />
                    <CardContent>
                      <Grid item xs={12} container justifyContent='center'>
                        {
                          vehicle.imageUrl ? (

                            <img
                              src={vehicle.imageUrl}
                              alt='vehicle'
                              style={{ width: "100%", height: "200px" }}
                            />
                          ) : (
                            <img
                              src={getVehicleTypeIcon(capitalizeFirstLetter(vehicle.bodyType))}
                              alt='vehicle'
                              style={{ width: "100%", height: "200px" }}
                            />
                          )
                        }
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                          <img
                            style={{
                              height: "12px",
                              marginRight: "10px",
                              width: "16px",
                            }}
                            src={getFeatureTypeIcon("numberOfDoors")}
                            alt={"numberOfDoors"}
                          />
                          <span style={{ fontSize: 12 }}>{`${vehicle.numberOfDoors} Doors`}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <img
                            style={{
                              height: "12px",
                              marginRight: "10px",
                              width: "16px",
                            }}
                            src={getFeatureTypeIcon("numberOfSeats")}
                            alt={"numberOfSeats"}
                          />
                          <span style={{ fontSize: 12 }}>{`${vehicle.numberOfSeats} Seats`}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <img
                            style={{
                              height: "12px",
                              marginRight: "10px",
                              width: "16px",
                            }}
                            src={getFeatureTypeIcon("transmission")}
                            alt={"transmission"}
                          />
                          <span style={{ fontSize: 12 }}>{`${vehicle.transmission}`}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <img
                            style={{
                              height: "12px",
                              marginRight: "10px",
                              width: "16px",
                            }}
                            src={getFeatureTypeIcon("fuelType")}
                            alt={"fuelType"}
                          />
                          <span style={{ fontSize: 12 }}>{`${vehicle.fuelType}`}</span>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions className={classes.actionBtn} disableSpacing>
                      <Grid item xs={12} container justifyContent='center'>
                        <Fab
                          className={`${classes.subButn} test `}
                          onClick={() => {
                            setSelectedVehicle(vehicle)
                            setOpen(true)
                          }}
                        >
                          {
                            props.bookingDurationInMonths > 0 && vehicle.subscriptions.some((subscription) => subscription.pricings.some(pricing => (subscription.subscription.rateTypeName === RATE_TYPES.MONTHLY && pricing.duration <= props.bookingDurationInMonths) || (subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY && pricing.duration <= props.bookingDurationInWeeks))) && (
                              <Typography variant="h3" style={{ fontWeight: 600 }}>Subscription starts at {toCurrency(lowestPrice, currency, locale)}</Typography>
                            )
                          }
                        </Fab>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              )
            }
          }
        })
      }
      {/* {noVehiclesFoundError && <div className={styles.vehicleGroupcard} style={{ padding: 15, border: 0 }}>No vehicles found with matching criteria!</div>} */}
      {
        open && (
          <SelectSubscriptionPricingModal
            open={open}
            onCancel={() => setOpen(false)}
            onSelectSubscription={props.onSelectSubscription}
            vehicle={selectedVehicle}
            bookingDurationInMonths={props.bookingDurationInMonths}
            bookingDurationInWeeks={props.bookingDurationInWeeks}
          />
        )
      }
    </Grid>
  )
}

export default SelectSubscriptionVehiclesView
