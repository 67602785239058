import "./index.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TextField as InputField,
  Theme,
  Typography,
  Checkbox,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Box,
  CssBaseline,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Switch,
  Link,
  Avatar,
  Hidden,
  AccordionSummary,
  Accordion,
  AccordionDetails
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from '@mui/material/Tooltip';
import { GET_BUSINESS_CUSTOMER_PRICE } from "../../../../../graphql/priceRules/getBusinessCustomersQuery";
import { GET_VEHICLE_GROUPS_PRICE } from "../../../../../graphql/priceRules/getVehiclesGroupsQuery";
import { priceRuleInitialState } from "../../../../../reducers/priceRule/consts";
import {
  IPriceRule,
  IRule,
  ValueType,
} from "../../../../../reducers/priceRule/types";
import { IVehiclePriceGroup } from "../../../../../reducers/vehiclePriceGroups/types";
import { IAppState } from "../../../../../store";
import { daySpecific, rateTypes, ruleOperators, valueTypes } from "./utils";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from "../../../../common/utils";
import { DateTime as d } from "luxon"
import { CREATE_PRICE_RULE } from "../../../../../graphql/priceRules/createPriceRuleMutation";
import { ApolloError } from "@apollo/client";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_PRICE_RULE } from "../../../../../graphql/priceRules/getPriceRuleQuery";
import { UPDATE_PRICE_RULE } from "../../../../../graphql/priceRules/updatePriceRuleMutation";
import FlatPickerBar from "../../../../common/FlatPicker"
import ConfirmTogglePriceRuleDialog from "./ConfirmTogglePriceRuleDialog";
import { CHANGE_PRICE_RULE_STATUS } from "../../../../../graphql/priceRules/changePriceRuleStatusMutation";
import { TimePicker } from "../../../../common/TimePicker";
import MobilePriceRuleVehicleGroupCard from "./MobilePriceRuleVehicleGroupCard";
import { createStyles, makeStyles } from "@mui/styles";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
interface IProps {
  priceRule: IPriceRule;
  onSubmit(payload?: IPriceRule): void;
}
interface IBusinessCustomer {
  id: string;
  businessName: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    ruleCardMain: {
      position: "relative"
    },
    addButton: {
      position: "fixed",
      [theme.breakpoints.up('md')]: {
        right: "6rem",
        bottom: '30%',
      },
      [theme.breakpoints.down('md')]: {
        right: "2rem",
        bottom: '10%',
      },
    },
    iconStyle: {
      padding: 2
    },
    tableContainer: {
      maxHeight: 300,
      border: "solid 1px rgba(224, 224, 224, 1)"
    },
    avatarStyle: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    ruleAvatar: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    expansionPanel: {
      marginTop: 8,
      width: `calc(100vw - 105px)`,
    },
  })
);

const NewPriceRule: React.FC<any> = (props: any) => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address
  const [values, setValues] = useState(priceRuleInitialState.priceRule);
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [businessCustomers, setBusinessCustomers] = useState<IBusinessCustomer[]>([]);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [selectedBusinessCustomers, setSelectedBusinessCustomers] = useState<IBusinessCustomer[]>([])
  const [selectedVehicleGroups, setSelectedVehicleGroups] = useState<IVehiclePriceGroup[]>([])
  const [togglePriceRule, setTogglePriceRule] = useState<boolean>(false)
  const prevBranchRef = useRef(userState.currentBranch);

  const [loadVehicleGroups,
    { loading: vehicleGroupsLoading, data: vehicleGroupsData }
  ] = useLazyQuery(
    GET_VEHICLE_GROUPS_PRICE, {
    fetchPolicy: "network-only"
  });

  const [loadBusinessCustomers, { loading: businessCustomersLoading, data: businessCustomersData }
  ] = useLazyQuery(
    GET_BUSINESS_CUSTOMER_PRICE, {
    fetchPolicy: "network-only"
  })

  const [loadPriceRule, { loading: priceRuleLoading, data }] = useLazyQuery(GET_PRICE_RULE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.priceRule) {
        navigate("/price-rules");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createPriceRuleMutation, { loading: createPriceRuleLoading }] = useMutation(CREATE_PRICE_RULE, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Price rule created"),
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/price-rules");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });

    },
  });

  const [changePriceRuleStatus] = useMutation(CHANGE_PRICE_RULE_STATUS, {
    onCompleted: ({ changePriceRuleStatus }) => {
      if (changePriceRuleStatus.isActive) {
        snackbar({
          message: formatGraphQLErrorMessage("Price rule activated successfully"),
          variant: SnackBarVariant.SUCCESS
        });
      } else {
        snackbar({
          message: formatGraphQLErrorMessage("Price rule deactivated successfully"),
          variant: SnackBarVariant.SUCCESS
        });
      }
      setValues({
        ...values,
        isActive: changePriceRuleStatus.isActive
      })
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  const [updatePriceRuleMutation] = useMutation(
    UPDATE_PRICE_RULE,
    {
      onCompleted: () => {
        snackbar({
          message: "Price Rule successfully updated",
          variant: SnackBarVariant.SUCCESS
        })
        navigate("/price-rules");
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const ruleId = params.get("id");
      if (ruleId) {
        setIsUpdate(true)
        loadPriceRule({
          variables: {
            id: ruleId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (data &&
      data.priceRule &&
      businessCustomersData &&
      businessCustomersData.businessCustomers &&
      vehicleGroupsData &&
      vehicleGroupsData.vehicleGroups
    ) {
      const businessCustomers = businessCustomersData.businessCustomers;
      const vehicleGroups = vehicleGroupsData.vehicleGroups;
      setValues({
        ...data.priceRule,
        rules: data.priceRule.rules.map((rule: any) => {
          return Object.keys(rule).reduce((acc: any, curr: string) => {
            if (rule[curr] !== null) {
              acc[curr] = rule[curr]
            }
            return acc
          }, {} as IRule)
        })
      })
      const selectedCustomers = businessCustomers.filter((customer: any) => data.priceRule.customers.includes(customer.id))
      const selectedGroups = vehicleGroups.filter((group: any) => data.priceRule.vehicleGroups.includes(group.id))
      setSelectedVehicleGroups(selectedGroups)
      setSelectedBusinessCustomers(selectedCustomers)
    }
  }, [data, businessCustomersData, vehicleGroupsData]);

  useEffect(() => {
    if (userState.tenancy) {
      loadBusinessCustomers()
      loadVehicleGroups()
    }
    if (prevBranchRef.current !== userState.currentBranch) {
      navigate("/price-rules");
      prevBranchRef.current = userState.currentBranch;
    }
  }, [userState.tenancy, userState.currentBranch])

  useEffect(() => {
    if (businessCustomersData && businessCustomersData.businessCustomers) {
      setBusinessCustomers(businessCustomersData.businessCustomers)
    }
  }, [businessCustomersData]);

  useEffect(() => {
    if (vehicleGroupsData && vehicleGroupsData.vehicleGroups) {
      const sortedGroups = [...vehicleGroupsData.vehicleGroups].sort((firstGroup: IVehiclePriceGroup, secondGroup: IVehiclePriceGroup) => {
        return firstGroup.name.toLowerCase().localeCompare(secondGroup.name.toLowerCase());
      })
      setVehicleGroups(sortedGroups)
    }
  }, [vehicleGroupsData])

  const addRuleToTrigger = () => {
    setValues({
      ...values,
      rules: [
        ...values.rules,
        {
          rateType: "",
          value: 0,
          valueType: ValueType.FIXED,
          dateRangeOptIn: false,
          durationOptIn: false,
          timeRangeOptIn: false,
          pickupDropoffDaysOptIn: false,
          mileageLimitOptIn: false,
          unlimitedMileage: false,
        }
      ]
    })
  }

  const removeRuleFromTrigger = (ruleIndex: number) => {
    setValues({
      ...values,
      rules: [
        ...values.rules.slice(0, ruleIndex),
        ...values.rules.slice(ruleIndex + 1)
      ]
    })
  }

  const onChangeRateType = (value: string, ruleIndex: number) => {
    setValues({
      ...values,
      rules: [
        ...values.rules.slice(0, ruleIndex),
        { ...values.rules[ruleIndex], rateType: value },
        ...values.rules.slice(ruleIndex + 1)
      ]
    })
  }

  const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>, ruleIndex: number) => {
    const value = parseInt(event.target.value);
    setValues((previousValues) => (
      {
        ...previousValues,
        rules: [
          ...previousValues.rules.slice(0, ruleIndex),
          { ...previousValues.rules[ruleIndex], value },
          ...previousValues.rules.slice(ruleIndex + 1)
        ]
      }
    ));
  }

  const onChangeValueType = (event: React.ChangeEvent<HTMLInputElement>, ruleIndex: number) => {
    setValues({
      ...values,
      rules: [
        ...values.rules.slice(0, ruleIndex),
        { ...values.rules[ruleIndex], valueType: event.target.value as ValueType },
        ...values.rules.slice(ruleIndex + 1)
      ]
    })
  }

  const onSubmitForm = (values: IPriceRule) => {
    const { isActive, ...rest } = values;
    if (values.id) {
      updatePriceRuleMutation({
        variables: {
          priceRule: rest
        }
      })
    } else {
      createPriceRuleMutation({
        variables: {
          priceRule: rest
        }
      })
    }
  }

  const onConfirmChangePriceRuleStatus = () => {
    if (values.id) {
      changePriceRuleStatus({
        variables: {
          id: values.id,
          isActive: !values.isActive
        }
      })
      setTogglePriceRule(false)
    }
  }

  const reshapeVehicleGroupIntoRow = (vehicleGroup: IVehiclePriceGroup) => {
    const vehicleRow = {
      name: vehicleGroup.name,
      vehicleCount: vehicleGroup.vehicles.length,
      hourlyRate: 0,
      dailyRate: 0,
      weeklyRate: 0,
      monthlyRate: 0
    }
    for (let index = 0; index < vehicleGroup.basePrices.length; index++) {
      const element = vehicleGroup.basePrices[index];
      if (element.rateTypeName === "hourly") {
        vehicleRow.hourlyRate = element.rate;
      }
      if (element.rateTypeName === "daily") {
        vehicleRow.dailyRate = element.rate;
      }
      if (element.rateTypeName === "weekly") {
        vehicleRow.weeklyRate = element.rate;
      }
      if (element.rateTypeName === "monthly") {
        vehicleRow.monthlyRate = element.rate;
      }
    }
    return vehicleRow;
  };

  const detailsFormSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    operator: Yup.string().required("Please select Rule Effect."),
  });

  if (priceRuleLoading || businessCustomersLoading || vehicleGroupsLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container item xs={12} className="price-rule-creation-container">
      <CssBaseline />
      <Grid item container xs={12}>
        <Hidden smDown>
          <Grid container item xs={12} md={5} alignItems="center">
            <Typography variant="h1" color="primary">
              {" "}
              Price Rules{"  "}
            </Typography>
            <Box color="white" sx={{ pr: 1 }}></Box>
            <DoubleArrowIcon />
            <Box color="white" sx={{ pl: 1 }}></Box>
            <Typography variant="h1" color="primary">
              &nbsp;{isUpdate ? "Update" : "New"} Price Rule
            </Typography>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container item xs={12} md={5} alignItems="center" justifyContent='center'>
            <Typography variant="h3" color="primary">
              {isUpdate ? "Update" : "New"} Price Rule
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
      <Paper className={classes.root}>
        {/* FIXME: This is wrong on many levels, I have used formik but setting  the values through setState*/}
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={detailsFormSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (!values.rules.length) {
              return snackbar({
                message: "Select at least one rate matrix to continue.",
                variant: SnackBarVariant.ERROR
              });
            } else {
              for (let index = 0; index < values.rules.length; index++) {
                const rule = values.rules[index];
                if (!rule.rateType || !rule.value) {
                  return snackbar({
                    message: formatGraphQLErrorMessage("Rate Type and amount are Required"),
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (rule && rule?.maximumBookingDuration && rule.minimumBookingDuration) {
                  if (rule.maximumBookingDuration < rule.minimumBookingDuration) {
                    return snackbar({
                      message: formatGraphQLErrorMessage("Max booking duration can not be less than min booking duration"),
                      variant: SnackBarVariant.ERROR
                    });
                  }
                }
                if (rule && rule.pickupDropoffDaysOptIn && (!rule.pickupDays || !rule.pickupDays.length) &&
                  (!rule.dropoffDays || !rule.dropoffDays.length)) {
                  return snackbar({
                    message: formatGraphQLErrorMessage("Please select either pickup days or dropoff days"),
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
              onSubmitForm(values)
            }
            setSubmitting(false);
          }}
        >
          {(props) => (
            <Form>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}><Typography variant="h3" color="secondary">{"RULE DETAILS"}</Typography></Grid>
                {
                  isUpdate && (
                    <Grid item xs={6} container justifyContent="flex-end">
                      <FormControlLabel
                        style={{ marginLeft: "105px" }}
                        control={
                          <Switch
                            checked={values.isActive}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setTogglePriceRule(true)
                            }}
                            name="isActive"
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="body1">
                            {values.isActive
                              ? "Active"
                              : "In-Active"}
                          </Typography>
                        }
                      />
                    </Grid>
                  )
                }
                <Grid item xs={12} container>
                  <Grid item xs={12} md={3}>
                    <Field
                      component={TextField}
                      name="name"
                      label="Rule Name"
                      fullWidth
                      required
                      inputProps={{
                        value: values.name,
                        onChange: (e: any) => {
                          setValues({
                            ...values,
                            name: e.target.value
                          })
                        }
                      }}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box mt={1} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      component={TextField}
                      select
                      label={"Price Effect"}
                      name={"operator"}
                      fullWidth
                      variant={"outlined"}
                      inputProps={{
                        value: values.operator,
                        onChange: (e: any) => {
                          setValues({
                            ...values,
                            operator: e.target.value
                          })
                        }
                      }}
                      required
                    >
                      {
                        ruleOperators.map(operator => {
                          return (
                            <MenuItem key={operator.value} value={operator.value}>
                              {operator.label}
                            </MenuItem>
                          )
                        })
                      }
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="free-solo-customer-search"
                    disableClearable
                    options={businessCustomers}
                    getOptionLabel={(option: any) =>
                      option.businessName
                    }
                    filterSelectedOptions={true}
                    onChange={(_: any, newValues: any, reason: any) => {
                      if (reason === "selectOption") {
                        setValues({
                          ...props.values,
                          customers: [
                            ...props.values.customers,
                            newValues[newValues.length - 1].id
                          ]
                        })
                      }
                      if (reason === "removeOption") {
                        const updatedCustomers = newValues.map((value: IBusinessCustomer) => value.id)
                        setValues({
                          ...props.values,
                          customers: updatedCustomers
                        })
                      }
                      setSelectedBusinessCustomers(newValues)
                    }}
                    value={selectedBusinessCustomers}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        label="Select Business Customer(s)"
                        variant="outlined"
                        placeholder={"Select Business Customer(s)"}
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="free-solo-vehicle-group-search"
                    disableClearable
                    options={vehicleGroups}
                    getOptionLabel={(option: any) =>
                      option.name
                    }
                    filterSelectedOptions
                    onChange={(_: any, newValues: any, reason: any) => {
                      if (reason === "selectOption") {
                        setValues({
                          ...props.values,
                          vehicleGroups: [
                            ...props.values.vehicleGroups,
                            newValues[newValues.length - 1].id
                          ]
                        })
                      }
                      if (reason === "removeOption") {
                        const updatedGroups = newValues.map((value: IVehiclePriceGroup) => value.id)
                        setValues({
                          ...props.values,
                          vehicleGroups: updatedGroups
                        })
                      }
                      setSelectedVehicleGroups(newValues)
                    }}
                    value={selectedVehicleGroups}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        label="Select Vehicle Group(s)"
                        variant="outlined"
                        placeholder={"Select Vehicle Group(s)"}
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Hidden mdDown>
                  <Grid item xs={9}></Grid>
                </Hidden>
                {
                  selectedVehicleGroups.length > 0 && (
                    <>
                      <Hidden mdDown>
                        <Grid item xs={12}>
                          <Typography variant="h3" color="secondary">{"VEHICLE GROUPS"}</Typography>
                          <Box mt={1}></Box>
                          <TableContainer className={classes.tableContainer} >
                            <Table stickyHeader aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell><Typography variant={"h4"}>Group Name</Typography></TableCell>
                                  <TableCell><Typography variant={"h4"}>Vehicles</Typography></TableCell>
                                  <TableCell><Typography variant={"h4"}>Hourly Rate</Typography></TableCell>
                                  <TableCell><Typography variant={"h4"}>Daily Rate</Typography></TableCell>
                                  <TableCell><Typography variant={"h4"}>Weekly Rate</Typography></TableCell>
                                  <TableCell><Typography variant={"h4"}>Monthly Rate</Typography></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  selectedVehicleGroups.map(group => {
                                    const basePrice = reshapeVehicleGroupIntoRow(group)
                                    return <TableRow key={group.id}>
                                      <TableCell>{basePrice.name}</TableCell>
                                      <TableCell>{basePrice.vehicleCount}</TableCell>
                                      <TableCell>{toCurrency(basePrice.hourlyRate, currency, locale)}</TableCell>
                                      <TableCell>{toCurrency(basePrice.dailyRate, currency, locale)}</TableCell>
                                      <TableCell>{toCurrency(basePrice.weeklyRate, currency, locale)}</TableCell>
                                      <TableCell>{toCurrency(basePrice.monthlyRate, currency, locale)}</TableCell>
                                    </TableRow>
                                  })
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Hidden>
                      <Hidden mdUp>
                        <Accordion className={classes.expansionPanel}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h3">Vehicle Groups</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container item xs={12}>
                              {
                                selectedVehicleGroups.map(group => {
                                  const basePrice = reshapeVehicleGroupIntoRow(group)
                                  return (
                                    <MobilePriceRuleVehicleGroupCard VehicleGroup={basePrice} />
                                  )
                                })
                              }
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Hidden>
                    </>
                  )
                }
                <Grid item xs={12}>
                  <Box mt={1}>
                    <Paper elevation={3}>
                      <Grid item xs={12} container></Grid>
                    </Paper>
                  </Box>
                  <Grid item xs={12} container className={classes.ruleCardMain}>
                    <Grid item xs={11}>
                      <Typography variant={"h3"} color={"secondary"}>SET PRICE RULE TRIGGERS</Typography>
                      <Box mt={0.5}>
                        <Typography>
                          Read more about automating seasonal pricing using Rate Matrix and how the triggers works
                          <Link href="https://help.coastr.com/knowledge/price-rules-complete" target="_blank" underline="always"> here</Link>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={1} >
                      <IconButton
                        onClick={() => {
                          addRuleToTrigger()
                        }}
                        className={classes.iconStyle}
                        aria-label="add-icon"
                        size="large">
                        <Tooltip title="Add Rule" aria-label="add">
                          <Avatar className={classes.avatarStyle} ><AddIcon /></Avatar>
                        </Tooltip>
                      </IconButton>
                    </Grid>
                    {
                      values.rules && values.rules.length > 0 &&
                      values.rules.map((rule: IRule, index: number) => {
                        return (
                          <Grid item xs={12} md={11} key={index}>
                            <Paper variant="outlined" style={{ flexGrow: 1, padding: 20 }}>
                              <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12} md={3}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="simple-rate-type-select">Rate Type</InputLabel>
                                    <Select
                                      labelId="simple-rate-type-select"
                                      id="simple-rate-type-outlined"
                                      value={rule.rateType}
                                      onChange={(e: any) => {
                                        onChangeRateType(e.target.value, index)
                                      }}
                                      label={"Rate Type"}
                                      required
                                      name={`${values.rules[index]}.rateType`}
                                    >
                                      {
                                        rateTypes.map(rateType => {
                                          return (
                                            <MenuItem key={rateType.value} value={rateType.value}>
                                              {rateType.label}
                                            </MenuItem>
                                          )
                                        })
                                      }
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Field
                                    component={TextField}
                                    select
                                    label="Type"
                                    name={`${values.rules[index]}.valueType`}
                                    fullWidth
                                    inputProps={{
                                      value: rule.valueType,
                                      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                        onChangeValueType(event, index)
                                      }
                                    }}
                                  >
                                    {
                                      valueTypes.map(valueType => {
                                        return (
                                          <MenuItem key={valueType.value} value={valueType.value}>
                                            {valueType.label}
                                          </MenuItem>
                                        )
                                      })
                                    }
                                  </Field>
                                </Grid>
                                {
                                  rule.valueType === ValueType.FIXED ? (
                                    <Grid item xs={12} md={3}>
                                      <Field
                                        component={TextField}
                                        fullWidth
                                        placeholder="Amount"
                                        label="Amount"
                                        required
                                        name={`${values.rules[index]}.value`}
                                        InputProps={{
                                          value: rule.value,
                                          inputComponent: FloatInput as any,
                                          onChange: (
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            onChangeAmount(e, index)
                                          }
                                        }}
                                        inputProps={{
                                          hasCurrencyPrefix: true,
                                          allowNegative: false
                                        }}
                                      ></Field>
                                    </Grid>
                                  ) :
                                    <Grid item xs={12} md={3}>
                                      <Field
                                        component={TextField}
                                        label="Value(%)"
                                        required
                                        fullWidth
                                        type={"number"}
                                        name={`${values.rules[index]}.value`}
                                        InputProps={{
                                          value: rule.value > 100 ? "" : rule.value,
                                          onChange: (
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            if (parseFloat(e.target.value) > 100) {
                                              snackbar({
                                                message:
                                                  "Value can't be more than 100%",
                                                variant: SnackBarVariant.ERROR
                                              });
                                            }
                                            else if (parseFloat(e.target.value) < 0) {
                                              snackbar({
                                                message:
                                                  "Value can't be less than 0%",
                                                variant: SnackBarVariant.ERROR
                                              });
                                            }
                                            else { onChangeAmount(e, index) }
                                          },
                                          min: "1"
                                        }}
                                      />
                                    </Grid>
                                }
                                <Hidden mdDown>
                                  {
                                    index > 0 && (
                                      <Grid item xs={12} md={3}>
                                        <IconButton
                                          style={{ padding: 6, color: "var(--theme-accent)" }}
                                          onClick={() => {
                                            removeRuleFromTrigger(index)
                                          }}
                                          aria-label="delete-icon"
                                          size="large">
                                          <Tooltip title="Delete This Rule" aria-label="add">
                                            <DeleteIcon />
                                          </Tooltip>
                                        </IconButton>
                                      </Grid>
                                    )
                                  }
                                </Hidden>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.rules[index].mileageLimitOptIn || false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setValues({
                                            ...values,
                                            rules: [
                                              ...values.rules.slice(0, index),
                                              {
                                                ...values.rules[index],
                                                mileageLimitOptIn: e.target.checked,
                                                mileageLimit: !e.target.checked ? 0 : values.rules[index].mileageLimit || 0,
                                                pricePerExtraMile: !e.target.checked ? 0 : values.rules[index].pricePerExtraMile || 0
                                              },
                                              ...values.rules.slice(index + 1)
                                            ]
                                          })
                                        }}
                                        name={`${values.rules[index]}.mileageLimitOptIn`}
                                        color="secondary"
                                      />
                                    }
                                    label="Modify Mileage Limit"
                                  />
                                </Grid>
                                {
                                  values.rules[index].mileageLimitOptIn && (
                                    <>
                                      <Grid item xs={12} md={3}>
                                        <Field
                                          component={TextField}
                                          label="Mileage Limit"
                                          type={"number"}
                                          fullWidth
                                          disabled={values.rules[index].unlimitedMileage ? true : false}
                                          name={`${values.rules[index]}.mileageLimit`}
                                          InputProps={{
                                            value: values.rules[index].mileageLimit || 0,
                                            onChange: (
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                              setValues({
                                                ...values,
                                                rules: [
                                                  ...values.rules.slice(0, index),
                                                  { ...values.rules[index], mileageLimit: parseInt(e.target.value) },
                                                  ...values.rules.slice(index + 1)
                                                ]
                                              })
                                            },
                                            min: "1"
                                          }}
                                        >
                                        </Field>
                                      </Grid>
                                      <Grid container item xs={12} md={3}>
                                        <Field
                                          component={TextField}
                                          fullWidth
                                          placeholder="Mileage Per Unit ThereAfter"
                                          label="Mileage Per Unit ThereAfter"
                                          disabled={values.rules[index].unlimitedMileage ? true : false}
                                          name={`${values.rules[index]}.pricePerExtraMile`}
                                          InputProps={{
                                            value: values.rules[index].pricePerExtraMile || 0,
                                            inputComponent: FloatInput as any,
                                            onChange: (
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                              setValues({
                                                ...values,
                                                rules: [
                                                  ...values.rules.slice(0, index),
                                                  { ...values.rules[index], pricePerExtraMile: parseInt(e.target.value) },
                                                  ...values.rules.slice(index + 1)
                                                ]
                                              })
                                            }
                                          }}
                                          inputProps={{
                                            hasCurrencyPrefix: true,
                                            allowNegative: false
                                          }}
                                        ></Field>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.rules[index].unlimitedMileage || false}
                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setValues({
                                                  ...values,
                                                  rules: [
                                                    ...values.rules.slice(0, index),
                                                    {
                                                      ...values.rules[index],
                                                      unlimitedMileage: e.target.checked,
                                                      mileageLimit: 0,
                                                      pricePerExtraMile: 0
                                                    },
                                                    ...values.rules.slice(index + 1)
                                                  ]
                                                })
                                              }}
                                              name={`${values.rules[index]}.unlimitedMileage`}
                                              color="secondary"
                                            />
                                          }
                                          label="Allow Unlimited Mileage"
                                        />
                                      </Grid>
                                    </>
                                  )
                                }
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.rules[index].dateRangeOptIn || false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setValues({
                                            ...values,
                                            rules: [
                                              ...values.rules.slice(0, index),
                                              {
                                                ...values.rules[index],
                                                dateRangeOptIn: e.target.checked
                                              },
                                              ...values.rules.slice(index + 1)
                                            ]
                                          })
                                        }}
                                        name={`${values.rules[index]}.dateRangeOptIn`}
                                        color="secondary"
                                      />
                                    }
                                    label="Add Rule Effective Dates"
                                  />
                                </Grid>
                                {
                                  values.rules[index].dateRangeOptIn && (
                                    <>
                                      <Grid item xs={12} md={3}>
                                        <FlatPickerBar
                                          enableTime={false}
                                          handleDateChange={(value: Date) => {
                                            setValues({
                                              ...values,
                                              rules: [
                                                ...values.rules.slice(0, index),
                                                { ...values.rules[index], ruleEffectiveFromDate: d.fromJSDate(value).toUTC().toISO() },
                                                ...values.rules.slice(index + 1)
                                              ]
                                            })
                                          }}
                                          label={"Rule Start Date"}
                                          identifier={`${values.rules[index]}.ruleEffectiveFromDate`}
                                          placeholderValue={"select date..."}
                                          value={values.rules[index].ruleEffectiveFromDate ? getLocalizedDateFormat(country, values.rules[index].ruleEffectiveFromDate || "", DATE_TYPE.CONDENSED) : ""}
                                          minDate={values.rules[index].ruleEffectiveFromDate && (values.rules[index].ruleEffectiveFromDate || "") < d.now().toISO() ? getLocalizedDateFormat(country, values.rules[index].ruleEffectiveFromDate || "", DATE_TYPE.CONDENSED) : "today"}
                                          country={country}
                                        />
                                      </Grid>
                                      <Grid container item xs={12} md={3}>
                                        <FlatPickerBar
                                          enableTime={false}
                                          handleDateChange={(value: Date) => {
                                            setValues({
                                              ...values,
                                              rules: [
                                                ...values.rules.slice(0, index),
                                                { ...values.rules[index], ruleEffectiveToDate: d.fromJSDate(value).toUTC().toISO() },
                                                ...values.rules.slice(index + 1)
                                              ]
                                            })
                                          }}
                                          disabled={!values.rules[index].ruleEffectiveFromDate}
                                          label={"Rule End Date"}
                                          identifier={`${values.rules[index]}.ruleEffectiveToDate`}
                                          placeholderValue={"select date..."}
                                          value={getLocalizedDateFormat(country, values.rules[index].ruleEffectiveToDate || "", DATE_TYPE.CONDENSED)}
                                          minDate={getLocalizedDateFormat(country, d.fromISO(values.rules[index].ruleEffectiveFromDate || "").plus({ days: 1 }).toUTC().toISO(), DATE_TYPE.CONDENSED)}
                                          country={country}
                                        />
                                      </Grid>
                                    </>
                                  )
                                }
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.rules[index].timeRangeOptIn || false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setValues({
                                            ...values,
                                            rules: [
                                              ...values.rules.slice(0, index),
                                              { ...values.rules[index], timeRangeOptIn: e.target.checked },
                                              ...values.rules.slice(index + 1)
                                            ]
                                          })
                                        }}
                                        name={`${values.rules[index]}.timeRangeOptIn`}
                                        color="secondary"
                                      />
                                    }
                                    label="Add Rule Effective Times"
                                  />
                                </Grid>
                                {
                                  values.rules[index].timeRangeOptIn && (
                                    <>
                                      <Grid item xs={12} md={3}>
                                        <TimePicker
                                          placeholder="Start Time"
                                          label="Start Time"
                                          time={values.rules[index].ruleEffectiveFromTime || ""}
                                          setTime={(time: string) => {
                                            setValues({
                                              ...values,
                                              rules: [
                                                ...values.rules.slice(0, index),
                                                { ...values.rules[index], ruleEffectiveFromTime: time },
                                                ...values.rules.slice(index + 1)
                                              ]
                                            })
                                          }}
                                        />
                                      </Grid>
                                      <Grid container item xs={12} md={3}>
                                        <TimePicker
                                          placeholder="End Time"
                                          label="End Time"
                                          time={values.rules[index].ruleEffectiveToTime || ""}
                                          setTime={(time: string) => {
                                            setValues({
                                              ...values,
                                              rules: [
                                                ...values.rules.slice(0, index),
                                                { ...values.rules[index], ruleEffectiveToTime: time },
                                                ...values.rules.slice(index + 1)
                                              ]
                                            })
                                          }}
                                        />
                                      </Grid>
                                    </>
                                  )
                                }
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.rules[index].durationOptIn || false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setValues({
                                            ...values,
                                            rules: [
                                              ...values.rules.slice(0, index),
                                              { ...values.rules[index], durationOptIn: e.target.checked },
                                              ...values.rules.slice(index + 1)
                                            ]
                                          })
                                        }}
                                        name={`${values.rules[index]}.durationOptIn`}
                                        color="secondary"
                                      />
                                    }
                                    label={`Add Minimum ${getLocalizedBookingSyntex(country)} Duration`}
                                  />
                                </Grid>
                                {
                                  values.rules[index].durationOptIn && (
                                    <>
                                      <Grid item xs={12} md={2}>
                                        <Field
                                          component={TextField}
                                          label="Min Booking Days"
                                          variant="outlined"
                                          name={`${values.rules[index]}.minimumBookingDuration`}
                                          fullWidth
                                          type="number"
                                          inputProps={{
                                            value: values.rules[index].minimumBookingDuration ? values.rules[index].minimumBookingDuration : 0,
                                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                              const maxBookingDuration = values.rules[index].maximumBookingDuration
                                              setValues({
                                                ...values,
                                                rules: [
                                                  ...values.rules.slice(0, index),
                                                  {
                                                    ...values.rules[index], minimumBookingDuration: parseInt(e.target.value),
                                                    ...((!maxBookingDuration || (maxBookingDuration && maxBookingDuration < parseInt(e.target.value))) &&
                                                    {
                                                      maximumBookingDuration: parseInt(e.target.value)
                                                    })
                                                  },
                                                  ...values.rules.slice(index + 1)
                                                ]
                                              })
                                            }
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2} >
                                        <Field
                                          component={TextField}
                                          label="Max Duration Day"
                                          variant="outlined"
                                          name={`${values.rules[index]}.maximumBookingDuration`}
                                          fullWidth
                                          min={values.rules[index].maximumBookingDuration}
                                          type="number"
                                          inputProps={{
                                            value: values.rules[index].maximumBookingDuration ? values.rules[index].maximumBookingDuration : 0,
                                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                              setValues({
                                                ...values,
                                                rules: [
                                                  ...values.rules.slice(0, index),
                                                  { ...values.rules[index], maximumBookingDuration: parseInt(e.target.value) },
                                                  ...values.rules.slice(index + 1)
                                                ]
                                              })
                                            }
                                          }}
                                        />
                                      </Grid>
                                    </>
                                  )
                                }
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.rules[index].pickupDropoffDaysOptIn || false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setValues({
                                            ...values,
                                            rules: [
                                              ...values.rules.slice(0, index),
                                              { ...values.rules[index], pickupDropoffDaysOptIn: e.target.checked },
                                              ...values.rules.slice(index + 1)
                                            ]
                                          })
                                        }}
                                        name={`${values.rules[index]}.durationOptIn`}
                                        color="secondary"
                                      />
                                    }
                                    label="Add Pickup/Dropoff Day(s)"
                                  />
                                </Grid>
                                {
                                  values.rules[index].pickupDropoffDaysOptIn && (
                                    <>
                                      <Grid item xs={12} md={4}>
                                        <Autocomplete
                                          multiple
                                          freeSolo
                                          id="free-solo-day-specific-search"
                                          disableClearable
                                          options={daySpecific}
                                          getOptionLabel={(option: any) =>
                                            option
                                          }
                                          filterSelectedOptions
                                          value={values.rules[index].pickupDays}
                                          onChange={(_: any, newVal: any, reason: any) => {
                                            if (reason === "selectOption") {
                                              setValues({
                                                ...values,
                                                rules: [
                                                  ...values.rules.slice(0, index),
                                                  { ...values.rules[index], pickupDays: newVal },
                                                  ...values.rules.slice(index + 1)
                                                ]
                                              })
                                            }
                                            if (reason === "removeOption") {
                                              setValues({
                                                ...values,
                                                rules: [
                                                  ...values.rules.slice(0, index),
                                                  { ...values.rules[index], pickupDays: newVal },
                                                  ...values.rules.slice(index + 1)
                                                ]
                                              })
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <InputField
                                              {...params}
                                              label="Select Pickup Day(s)"
                                              name={`${values.rules[index]}.pickupDays`}
                                              variant="outlined"
                                              placeholder={"select day"}
                                              InputProps={{ ...params.InputProps, type: "search" }}
                                            />
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={4}>
                                        <Autocomplete
                                          multiple
                                          freeSolo
                                          id="free-solo-day-specific-search"
                                          disableClearable
                                          options={daySpecific}
                                          getOptionLabel={(option: any) =>
                                            option
                                          }
                                          filterSelectedOptions
                                          value={values.rules[index].dropoffDays}
                                          onChange={(_: any, newVal: any, reason: any) => {
                                            if (reason === "selectOption") {
                                              setValues({
                                                ...values,
                                                rules: [
                                                  ...values.rules.slice(0, index),
                                                  { ...values.rules[index], dropoffDays: newVal },
                                                  ...values.rules.slice(index + 1)
                                                ]
                                              })
                                            }
                                            if (reason === "removeOption") {
                                              setValues({
                                                ...values,
                                                rules: [
                                                  ...values.rules.slice(0, index),
                                                  { ...values.rules[index], dropoffDays: newVal },
                                                  ...values.rules.slice(index + 1)
                                                ]
                                              })
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <InputField
                                              {...params}
                                              label="Select Dropoff Day(s)"
                                              name={`${values.rules[index]}.dropoffDays`}
                                              variant="outlined"
                                              placeholder={"select day"}
                                              InputProps={{ ...params.InputProps, type: "search" }}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </>
                                  )
                                }
                                <Hidden mdUp>
                                  {
                                    index > 0 && (
                                      <Grid item justifyContent='flex-end' xs={12}>
                                        <Grid item>
                                          <IconButton
                                            style={{ padding: 6, color: "var(--theme-accent)" }}
                                            onClick={() => {
                                              removeRuleFromTrigger(index)
                                            }}
                                            aria-label="delete-icon"
                                            size="large">
                                            <Tooltip title="Delete This Rule" aria-label="add">
                                              <DeleteIcon />
                                            </Tooltip>
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    )
                                  }
                                </Hidden>
                              </Grid>
                            </Paper>
                          </Grid>
                        );
                      })
                    }
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <Box mt={1} />
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="submit"
                    type="submit"
                    disabled={createPriceRuleLoading}
                  >
                    {createPriceRuleLoading && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}
                    Save
                  </Fab>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
      <ConfirmTogglePriceRuleDialog
        open={togglePriceRule}
        onClose={() => setTogglePriceRule(false)}
        isActive={values.isActive}
        onConfirm={onConfirmChangePriceRuleStatus}
      />
    </Grid>
  );
}

export default NewPriceRule;
