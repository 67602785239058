import { Value } from "react-phone-number-input";

import { IPartner } from "./types";

export const partnerInitialState: IPartner = {
  address: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  },
  companyRegistrationNo: "",
  documents: [],
  email: "",
  isActive: false,
  partnerContact: {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: {
      country: "",
      phone: "" as Value
    }
  },
  partnerId: "",
  partnerName: "",
  partnerType: "GARAGE",
  phoneNumber: {
    country: "",
    phone: "" as Value
  },
  id: "",
  VAT: ""
};
