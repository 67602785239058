import { VehicleBodyTypes } from "../../../../../common/VehicleDamagePreview/frogDiagram";

export const frogDiagramTypes: any = [
  {
    label: "SALOON",
    value: VehicleBodyTypes.SALOON
  },
  {
    label: "SEDAN",
    value: VehicleBodyTypes.SEDAN
  },
  {
    label: "SUV",
    value: VehicleBodyTypes.SUV
  },
  {
    label: "HATCHBACK 3-DOOR",
    value: VehicleBodyTypes.HATCHBACK_3_DOOR
  },
  {
    label: "HATCHBACK 5-DOOR",
    value: VehicleBodyTypes.HATCHBACK_5_DOOR
  },
  {
    label: "MPV",
    value: VehicleBodyTypes.MPV
  },
  {
    label: "MPV-7-SEATER",
    value: VehicleBodyTypes.MPV_7_SEATER
  },
  {
    label: "ESTATE",
    value: VehicleBodyTypes.ESTATE
  },

  {
    label: "MINI 3-DOOR",
    value: VehicleBodyTypes.MINI_3_DOOR
  },
  {
    label: "CONVERTABLE 3-DOOR",
    value: VehicleBodyTypes.CONVERTABLE_3_DOOR
  },
  {
    label: "COUPE",
    value: VehicleBodyTypes.COUPE
  },
  {
    label: "PANEL VAN",
    value: VehicleBodyTypes.PANEL_VAN
  },
  {
    label: "CDV (Car Derived Van)",
    value: VehicleBodyTypes.CDV
  },
  {
    label: "LARGE PANEL VAN",
    value: VehicleBodyTypes.LARGE_PANEL_VAN
  },

  {
    label: "LUTON VAN",
    value: VehicleBodyTypes.LUTON_VAN
  },
  {
    label: 'LUTON VAN 3-SEATER 2-DOOR',
    value: VehicleBodyTypes.LUTON_3_SEATER
  },
  {
    label: 'ATV',
    value: VehicleBodyTypes.ATV
  },
  {
    label: "VAN MID SIZE",
    value: VehicleBodyTypes.VAN_MIDSIZE
  },
  {
    label: "VAN FLAT BED",
    value: VehicleBodyTypes.VAN_FLAT_BED
  },
  {
    label: "VAN FLAT BED BOXED",
    value: VehicleBodyTypes.VAN_FLAT_BED_BOXED
  },
  {
    label: "VAN 7 TONNE",
    value: VehicleBodyTypes.VAN_7_TONNE
  },
  {
    label: "VAN 18 TONNE",
    value: VehicleBodyTypes.VAN_18_TONNE
  },
  {
    label: "VAN 26 TONNE",
    value: VehicleBodyTypes.VAN_26_TONNE
  },
  {
    label: "VAN 41 TONNE",
    value: VehicleBodyTypes.VAN_41_TONNE
  },
  {
    label: "VAN 7-SEATER",
    value: VehicleBodyTypes.VAN_7_SEATER
  },
  {
    label: "VAN 7-SEATER-3-WINDOWS",
    value: VehicleBodyTypes.VAN_7_SEATER_3_WINDOWS
  },
  {
    label: "VAN 9-SEATER",
    value: VehicleBodyTypes.VAN_9_SEATER
  },
  {
    label: "TIPPER VAN",
    value: VehicleBodyTypes.TIPPER_VAN
  },
  {
    label: "TIPPER TRUCK",
    value: VehicleBodyTypes.TIPPER_TRUCK
  },
  {
    label: "PICKUP TRUCK",
    value: VehicleBodyTypes.PICK_UP_TRUCK
  },
  {
    label: "TRUCKMAN TOP 4-DOOR",
    value: VehicleBodyTypes.TRUCKMAN_TOP_4_DOOR
  },
  {
    label: "TRUCKMAN WITHOUT TOP 4-DOOR",
    value: VehicleBodyTypes.TRUCKMAN_WITHOUT_TOP_4_DOOR
  },
  {
    label: "CAMPERVAN",
    value: VehicleBodyTypes.CAMPERVAN
  },
  {
    label: "4x4 ESTATE",
    value: VehicleBodyTypes.ESTATE_4X4
  },
  {
    label: "4x4 ESTATE 7-SEATER",
    value: VehicleBodyTypes.ESTATE_4X4_7_SEATER
  },
  {
    label: "LIGHT UTILITY 4x4",
    value: VehicleBodyTypes.LIGHT_UTILITY_4X4
  },
  {
    label: "LIGHT UTILITY 4x4 2-SEATER",
    value: VehicleBodyTypes.LIGHT_UTILITY_4X4_2_SEATER
  },
  {
    label: "SAFARI",
    value: VehicleBodyTypes.SAFARI
  },
  {
    label: "SCOOTER",
    value: VehicleBodyTypes.SCOOTER
  },
  {
    label: 'HGV TRAILER',
    value: VehicleBodyTypes.HGV_TRAILER
  },
  {
    label: 'SWB VAN',
    value: VehicleBodyTypes.SWB_VAN
  },
  {
    label: "MINI BUS",
    value: VehicleBodyTypes.MINIBUS
  },
  {
    label: 'FLATBED TRAILER',
    value: VehicleBodyTypes.FLATBED_TRAILER
  },
  {
    label: 'STEPFRAME TRAILER',
    value: VehicleBodyTypes.STEPFRAME_TRAILER
  },
  {
    label: 'STRAIGHT FRAME TRAILER',
    value: VehicleBodyTypes.STRAIGHT_FRAME_TRAILER
  }
];
