import React from "react";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BuildIcon from "@mui/icons-material/Build";
import BusinessIcon from "@mui/icons-material/Business";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EventIcon from "@mui/icons-material/Event";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MapIcon from "@mui/icons-material/Map";
import ExpensesIcon from "@mui/icons-material/PaymentTwoTone";
import FuelIcon from "@mui/icons-material/EvStation";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import WarningIcon from "@mui/icons-material/Warning";
import WebIcon from '@mui/icons-material/Language';
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ConnectedIcon from '@mui/icons-material/ViewStream';
import { ReactComponent as BillingIcon } from "../../../assets/icons/icon-billing.svg";
import { ReactComponent as InvoiceIcon } from "../../../assets/icons/icon-invoice.svg";
import { ReactComponent as CreditNoteIcon } from "../../../assets/icons/icon-credit-note.svg";
import { ReactComponent as TaskManagerIcon } from "../../../assets/icons/Job-schedular-icon.svg";
import { ReactComponent as FleetTransferIcon } from "../../../assets/icons/vehicle-transfr-icon.svg";
import { ReactComponent as PCNIcon } from "../../../assets/icons/payment.svg";
import depositIcon from "../../../assets/icons/deposit-icon.svg"
import ConsolidatedInvoiceIcon from '../../../assets/icons/consolidated-icon-invoice.svg'

export const NavBarModules: any = [
  {
    name: "Dashboard",
    link: "/",
    Icon: <SpeedIcon style={{ fontSize: 18 }} />
  },
  {
    name: "Bookings",
    link: "/bookings",
    Icon: <CheckCircleOutlineIcon style={{ fontSize: 18 }} />,
  },
  {
    name: "Customers",
    Icon: <SupervisorAccountIcon style={{ fontSize: 18 }} />,
    items: [
      {
        name: "Individual",
        link: "/individual-customer",
        Icon: <PermIdentityIcon style={{ marginLeft: 10, fontSize: 18 }} />
      },
      {
        name: "Business",
        link: "/business-customer",
        Icon: <BusinessIcon style={{ marginLeft: 10, fontSize: 18 }} />
      }
    ]
  },
  {
    name: "Fleet",
    Icon: <DirectionsCarIcon style={{ fontSize: 18 }} />,
    items: [
      {
        name: "Fleet Dashboard",
        link: "/vehicle-dashboard",
        Icon: <MapIcon style={{ marginLeft: 10, fontSize: 18 }} />,
      },
      {
        name: "Fleet Inventory",
        link: "/vehicle-inventory",
        Icon: <DirectionsCarIcon style={{ marginLeft: 10, fontSize: 18 }} />,
      },
      {
        name: "Smartcar",
        link: "/smartcar",
        Icon: <ConnectedIcon style={{ marginLeft: 10, fontSize: 18 }} />,
      },
      {
        name: "Fleet Transfer",
        link: "/fleet-transfer",
        Icon: <FleetTransferIcon
          style={{
            width: 20,
            height: 26,
            marginLeft: 8,
          }}
        />,
      },
      {
        name: "Vehicle Checklist",
        link: "/vehicle-checklist",
        Icon: <DoneAllIcon style={{ marginLeft: 10, fontSize: 18 }} />,
      },
      {
        name: "Vehicle Scheduler",
        link: "/vehicle-scheduler",
        Icon: <EventIcon style={{ marginLeft: 10, fontSize: 18 }} />,
      },
      {
        name: "Damages",
        link: "/vehicle-damage",
        Icon: <WarningIcon style={{ marginLeft: 10, fontSize: 18 }} />,
      },
      {
        name: "Service",
        link: "/services-and-repair",
        Icon: <BuildIcon style={{ marginLeft: 10, fontSize: 18 }} />,
      }
    ]
  },
  {
    name: "Fleet Movement",
    link: "/movements",
    Icon: <span><svg width="18px" version="1.1" id="Layer_1" x="0px" y="0px"
      viewBox="0 0 24 24" enable-background="new 0 0 24 24">
      <g id="surface1">
        <path fill="#010101" stroke="#F9F9FA" stroke-miterlimit="10" d="M9.9,11.4h1.5v0.7H9.9V11.4z" />
        <path stroke="#F9F9FA" stroke-miterlimit="10" d="M22.8,10.4l-2.6-0.3c-0.5-0.3-1-0.5-1.5-0.7c-1.7-0.7-3.4-1.1-5.3-1.1H13
		c-2.1,0-4.2,0.5-6.1,1.5l-1.3,0.7c-1.5,0-3,0.4-4.3,1.1c-0.5,0.3-0.9,0.8-0.9,1.4V15c0,0.2,0.2,0.4,0.4,0.4h1.3
		C2.5,16.4,3.8,17,5,16.6c0.6-0.2,1.2-0.7,1.4-1.3h10.1c0.4,1.1,1.7,1.7,2.9,1.3c0.6-0.2,1.2-0.7,1.4-1.3h0.1c0,0,0.1,0,0.1,0
		l1.2-0.5c0.5-0.2,0.9-0.7,0.9-1.2v-2.8C23.1,10.6,23,10.4,22.8,10.4z M5.7,14.9c-0.2,0.8-1,1.2-1.8,1.1c-0.8-0.2-1.3-0.9-1.2-1.7
		c0.2-0.8,1-1.2,1.8-1.1c0.7,0.1,1.2,0.7,1.2,1.4C5.7,14.7,5.7,14.8,5.7,14.9z M12.5,14.6h-6c0-1.2-1-2.1-2.3-2.1S2,13.4,2,14.6H1.2
		v-1.7c0-0.3,0.2-0.6,0.5-0.8c1.2-0.7,2.6-1,4-1h6.8V14.6z M12.5,10.4H7.2l0.1,0c1.6-0.8,3.4-1.3,5.2-1.4V10.4z M13.3,9h0.2
		c1.5,0,3,0.3,4.4,0.8l-0.7,0.6h-4V9z M20,14.9c-0.2,0.8-1,1.2-1.8,1.1c-0.8-0.2-1.3-0.9-1.2-1.7c0.2-0.8,1-1.2,1.8-1.1
		c0.7,0.1,1.2,0.7,1.2,1.4C20.1,14.7,20.1,14.8,20,14.9z M22.3,11.4h-1.1v0.7h1.1v1.4c0,0.3-0.2,0.5-0.4,0.6l-1.1,0.4
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
		c0,0,0,0-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1
		c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c-1.3,0-2.3,0.9-2.3,2.1h-3v-3.5h4.2c0.1,0,0.2,0,0.3-0.1l1-0.9
		c0.4,0.2,0.8,0.4,1.2,0.6c0,0,0.1,0,0.1,0l2.3,0.3V11.4z"/>
      </g>
      <text transform="matrix(1.0763 0 0 1 14.4355 11.5269)" fill="none" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="10" font-family="'Acme-Regular'" font-size="22.3448px">&gt;</text>
      <text transform="matrix(-1.0763 0 0 1 13.125 23.8594)" fill="none" stroke="#FFFFFF" stroke-width="0.5" stroke-miterlimit="10" font-family="'Acme-Regular'" font-size="22.3448px">&gt;</text>
    </svg></span>,
  },
  {
    name: "Billing",
    Icon: <BillingIcon
      style={{
        width: 20,
        height: 26,
      }}
    />,
    items: [
      {
        name: "Invoices",
        link: "/invoices",
        Icon: <InvoiceIcon
          style={{
            width: 20,
            height: 26,
            marginLeft: 8,
          }}
        />
      },
      {
        name: "Consolidated Invoices",
        link: "/consolidated-invoices",
        Icon: <img alt="" src={ConsolidatedInvoiceIcon}
          style={{
            width: 20,
            height: 26,
            marginLeft: 8,
          }}
        />
      },
      {
        name: "Credit Notes",
        link: "/credit-notes",
        Icon: <CreditNoteIcon
          style={{
            width: 20,
            height: 26,
            marginLeft: 8,
          }}
        />
      },
      {
        name: "Penalty Charge Notice",
        link: "/penalty-charge-notice",
        Icon: <PCNIcon
          style={{
            width: 20,
            height: 26,
            marginLeft: 8
          }}
        />
      },
      {
        name: "Pending Deposits",
        link: "/deposits",
        Icon: <img alt="" src={depositIcon}
          style={{
            width: 20,
            height: 26,
            marginLeft: 8,
          }}
        />
      }

    ]
  },
  {
    name: "Expenses",
    Icon: <ExpensesIcon style={{ fontSize: 18 }} />,
    link: "/expenses"
  },
  {
    name: "Reports",
    link: "/reports",
    Icon: <AssessmentIcon style={{ fontSize: 18 }} />,
  },
  {
    name: "Management Reports",
    link: "/management-reports",
    Icon: <AssessmentIcon style={{ fontSize: 18 }} />,
  },
  {
    name: "Web/Mobile Apps",
    link: "/apps",
    Icon: <WebIcon style={{ fontSize: 18 }} />,
  },
  {
    name: "Pricing",
    Icon: <LocalOfferIcon style={{ fontSize: 18 }} />,
    items: [
      {
        name: "Group & Price",
        link: "/vehicle-price-groups",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        )
      },
      {
        name: "Price Rules",
        link: "/price-rules",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        )
      },
      {
        name: "Vehicle Rate Cards",
        link: "/rate-cards",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        )
      },
      {
        name: "Addon Rate Cards",
        link: "/addon-rate-cards",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        )
      },
      {
        name: "Subscription Plans",
        link: "/subscriptions",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        )
      }
    ]
  },
  {
    name: "Asset Lifecycle Mgmt",
    Icon: <DirectionsCarIcon style={{ fontSize: 18 }} />,
    items: [
      {
        name: "Procurements",
        link: "/procurements",
        Icon: <span style={{ marginLeft: 6 }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 5.25003C2.80109 5.25003 2.61032 5.32905 2.46967 5.4697C2.32902 5.61035 2.25 5.80112 2.25 6.00003C2.25 6.19894 2.32902 6.38971 2.46967 6.53036C2.61032 6.67101 2.80109 6.75003 3 6.75003H4.665L6.633 14.625C6.80025 15.2925 7.398 15.75 8.08575 15.75H17.4382C18.1155 15.75 18.6908 15.3 18.8685 14.6475L20.8125 7.50003H19.242L17.4375 14.25H8.085L6.11775 6.37503C6.03649 6.05195 5.84916 5.76549 5.58576 5.56152C5.32236 5.35754 4.99813 5.24786 4.665 5.25003H3ZM16.5 15.75C15.2662 15.75 14.25 16.7663 14.25 18C14.25 19.2338 15.2662 20.25 16.5 20.25C17.7338 20.25 18.75 19.2338 18.75 18C18.75 16.7663 17.7338 15.75 16.5 15.75ZM9.75 15.75C8.51625 15.75 7.5 16.7663 7.5 18C7.5 19.2338 8.51625 20.25 9.75 20.25C10.9838 20.25 12 19.2338 12 18C12 16.7663 10.9838 15.75 9.75 15.75ZM12 5.25003V9.00003H9.75L12.75 12L15.75 9.00003H13.5V5.25003H12ZM9.75 17.25C10.173 17.25 10.5 17.577 10.5 18C10.5 18.423 10.173 18.75 9.75 18.75C9.327 18.75 9 18.423 9 18C9 17.577 9.327 17.25 9.75 17.25ZM16.5 17.25C16.923 17.25 17.25 17.577 17.25 18C17.25 18.423 16.923 18.75 16.5 18.75C16.077 18.75 15.75 18.423 15.75 18C15.75 17.577 16.077 17.25 16.5 17.25Z" fill="white" />
        </svg></span>,
      },
      {
        name: "De-fleet",
        link: "/vehicle-defleet",
        Icon: <DirectionsCarIcon style={{ marginLeft: 10, fontSize: 18 }} />
      }
    ]
  },
  {
    name: "Partners",
    link: "/partners",
    Icon: <span><svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.1589 7.1589C21.4089 5.9089 20.8389 4.4489 20.1589 3.7389L17.1589 0.738904C15.8989 -0.511096 14.4489 0.0589036 13.7389 0.738904L12.0389 2.4489H9.4489C7.5489 2.4489 6.4489 3.4489 5.8889 4.5989L1.4489 9.0389V13.0389L0.738904 13.7389C-0.511096 14.9989 0.0589036 16.4489 0.738904 17.1589L3.7389 20.1589C4.2789 20.6989 4.8589 20.8989 5.4089 20.8989C6.1189 20.8989 6.7689 20.5489 7.1589 20.1589L9.8589 17.4489H13.4489C15.1489 17.4489 16.0089 16.3889 16.3189 15.3489C17.4489 15.0489 18.0689 14.1889 18.3189 13.3489C19.8689 12.9489 20.4489 11.4789 20.4489 10.4489V7.4489H19.8589L20.1589 7.1589ZM18.4489 10.4489C18.4489 10.8989 18.2589 11.4489 17.4489 11.4489H16.4489V12.4489C16.4489 12.8989 16.2589 13.4489 15.4489 13.4489H14.4489V14.4489C14.4489 14.8989 14.2589 15.4489 13.4489 15.4489H9.0389L5.7589 18.7289C5.4489 19.0189 5.2689 18.8489 5.1589 18.7389L2.1689 15.7589C1.8789 15.4489 2.0489 15.2689 2.1589 15.1589L3.4489 13.8589V9.8589L5.4489 7.8589V9.4489C5.4489 10.6589 6.2489 12.4489 8.4489 12.4489C10.6489 12.4489 11.4489 10.6589 11.4489 9.4489H18.4489V10.4489ZM18.7389 5.7389L17.0389 7.4489H9.4489V9.4489C9.4489 9.8989 9.2589 10.4489 8.4489 10.4489C7.6389 10.4489 7.4489 9.8989 7.4489 9.4489V6.4489C7.4489 5.9889 7.6189 4.4489 9.4489 4.4489H12.8589L15.1389 2.1689C15.4489 1.8789 15.6289 2.0489 15.7389 2.1589L18.7289 5.1389C19.0189 5.4489 18.8489 5.6289 18.7389 5.7389Z" fill="white" />
    </svg></span>,
  },
  {
    name: "Task Manager",
    link: "/user-jobs",
    Icon: <TaskManagerIcon
      style={{
        width: 20,
        height: 26,
      }}
    />
  },
  {
    name: "Settings",
    Icon: <SettingsIcon style={{ fontSize: 18 }} />,
    items: [
      {
        name: "Account",
        link: "/account-settings",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Add Ons",
        link: "/addons",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Insurance",
        link: "/insurance-settings",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Organisation",
        link: "/org-structure",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Users",
        link: "/users",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Roles",
        link: "/roles",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Policies",
        link: "/policies",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Rental Agreement",
        link: "/rental-agreement",
        Icon: <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
      },
      {
        name: "Subscription Agreement",
        link: "/subscription-agreement",
        Icon: <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
      },
      {
        name: "Document Types",
        link: "/document-types",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Payment & Integrations",
        link: "/integrations",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Marketplace",
        link: "/marketplace",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Service Task Items",
        link: "/service-task-items",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
      {
        name: "Email Settings",
        link: "/email-settings",
        Icon: (
          <FiberManualRecordIcon style={{ marginLeft: 10, fontSize: 18 }} />
        ),
      },
    ]
  },
];
