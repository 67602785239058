import { gql } from "@apollo/client";

export const CREATE_WEBSITE_MUTATION = gql`
  mutation createConsumerAppSetting(
    $consumerAppSetting: CreateConsumerAppSettingInput!
  ) {
    createConsumerAppSetting(consumerAppSetting: $consumerAppSetting) {
      id
      appName
      organisationId
      branchIds
      privacyPolicyURL
      provider
      callbackUris
      clientId
      clientSecret
      firstParty
      companyWebsite
      termsAndConditionsURL
      organisation {
        name
      }
      branding {
        brandColors
        buttonColor
        accentColor
      }
      webAppSetting {
        websiteTitle
        websiteIcon
        websiteLogo
        domain
        websiteHeader
        websiteDescription
        logoSize
        htmlDescription
        hasCarousel
        carousel
        hasGallery
        gallery
        websiteAddress
        enableBusinessCustomerSignup
        paymentGateway
      }
      mobileAppSetting {
        appIcon
        companyIcon
        straplineIcon
        landingPageIcon
      }
    }
  }
`;
export const UPDATE_APP_SETTING_MUTATION = gql`
  mutation updateConsumerAppSetting(
    $appId: String!
    $consumerAppSetting: CreateConsumerAppSettingInput!
  ) {
    updateConsumerAppSetting(
      appId: $appId
      consumerAppSetting: $consumerAppSetting
    ) {
      id
      appName
      organisationId
      branchIds
      privacyPolicyURL
      provider
      callbackUris
      clientId
      clientSecret
      firstParty
      companyWebsite
      termsAndConditionsURL
      organisation {
        name
      }
      isActive
      branding {
        brandColors
        buttonColor
        accentColor
      }
      webAppSetting {
        websiteTitle
        websiteIcon
        websiteLogo
        domain
        websiteHeader
        websiteDescription
        logoSize
        htmlDescription
        hasCarousel
        carousel
        hasGallery
        gallery
        websiteAddress
        enableBusinessCustomerSignup
      }
      mobileAppSetting {
        appIcon
        companyIcon
        straplineIcon
        landingPageIcon
      }
    }
  }
`;

export const CHANGE_APP_SETTING_STATUS = gql`
  mutation changeAppSettingStatus($appId: String!, $isActive: Boolean!) {
    changeAppSettingStatus(appId: $appId, isActive: $isActive) {
      id
      isActive
    }
  }
`;
