import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Theme
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { ChangeEvent, useMemo } from "react";
import { useSelector } from "react-redux";
import { ANSWER_TYPES, BOOKING_SOURCE, IBookingCreateInput, IBookingQuestionInput } from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";
import { IBookingQuestion } from "../../../AppSettings/types";
import styles from './index.module.css';

const StyledInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-multiline': {
        padding: '0px 4px',
        height: 60
      },
      '& .MuiOutlinedInput-root fieldset': {
        borderColor: '#e2e2e2',
        borderRadius: 8
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      },
      '& .MuiSelect-select ~ fieldset': {
        border: 0
      },
    },
  }),
)(TextField);

const ColoredCheckbox = withStyles({
  root: {
    color: 'var(--theme-primary)',
    '&$checked': {
      color: 'var(--theme-primary)',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface IBookingQuestionsProps {
  booking: IBookingCreateInput,
  formikRef: any
}

export const BookingQuestions: React.FC<IBookingQuestionsProps> = (props) => {
  const { currentBranch, currentOrganisation } = useSelector((state: IAppState) => state.userReducer);
  const getFormattedBookingQuestions = () => {
    let _questions: IBookingQuestionInput[] = [];
    if (currentBranch && currentBranch.preBookingQuestions && currentBranch.preBookingQuestions.length) {
      currentBranch.preBookingQuestions.forEach((preBookingQuestion, index) => {
        preBookingQuestion.B2B && _questions.push({
          question: preBookingQuestion.question,
          answerType: preBookingQuestion.answerType,
          answer: props.booking && props.booking.preBookingQuestions &&
            props.booking.preBookingQuestions[index]?.answer !== "false" ? props.booking.preBookingQuestions[index]?.answer : ""
        })
      })
    }
    return _questions
  }

  let initialValues = useMemo(() => {
    return {
      ...props.booking,
      preBookingQuestions: getFormattedBookingQuestions()
    }
  }, []);

  const validateAnswer = (value: any, required: boolean, expected?: string) => {
    let error;
    if (required) {
      if (expected === "TRUE" && !value) {
        error = "Error"
      }
      if (expected === "FALSE" && value) {
        error = "Error"
      }
      if (expected === "TEXT" && !value) {
        error = "Error"
      }
      if (expected === "YES" && value !== "YES") {
        error = "Error"
      }
      if (expected === "NO" && value !== "NO") {
        error = "Error"
      }
    }
    return error;
  }

  const preBookingQuestions = currentBranch && currentBranch.preBookingQuestions && currentBranch.preBookingQuestions.length ? currentBranch.preBookingQuestions.filter(bq => bq.B2B) : []

  return (
    <>
      {preBookingQuestions.length > 0 && (
        <Grid container>
          <Paper style={{ width: "100%", marginTop: "20px", marginBottom: 20 }}>
            <Grid item style={{ padding: 20 }}>
              <Formik
                initialValues={initialValues}
                validateOnMount
                onSubmit={() => { }}
                innerRef={props.formikRef}
              >
                {(formikProps) => (
                  <Form>
                    {preBookingQuestions.length > 0 && (
                      <Grid container xs={12}>
                        <Grid item xs={12} style={{ marginTop: 10 }}>
                          <Typography variant="h4">{`${getLocalizedBookingSyntex(currentOrganisation.address.country)} questions`}</Typography>
                        </Grid>
                        <Grid container spacing={1} item xs={12} style={{ marginTop: 16 }}>
                          {preBookingQuestions.map((preBookingQuesiton: IBookingQuestion, idx: number) => {
                            if (!preBookingQuesiton.B2B) {
                              return null
                            }
                            switch (preBookingQuesiton.answerType) {
                              case ANSWER_TYPES.CHECKBOX:
                                return (
                                  <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
                                    <Field
                                      name={`preBookingQuestions[${idx}].answer`}
                                      validate={(value: any) => validateAnswer(value, preBookingQuesiton.required, 'TRUE')} >
                                      {({ field, meta: { touched, error }, }: FieldProps) => (
                                        <div className={`${styles.validation} ${(touched && Boolean(error)) ? styles.error : ''}`} style={{ padding: 12 }}>
                                          <FormControlLabel
                                            control={
                                              <ColoredCheckbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                  formikProps.setFieldValue(`preBookingQuestions[${idx}].answer`, event.target.checked);
                                                }}
                                                size="small"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                              />
                                            }
                                            label={`${preBookingQuesiton.question} ${preBookingQuesiton.required ? "*" : ""}`}
                                            disabled={formikProps.values.source === BOOKING_SOURCE.B2B2C}
                                          />
                                          {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{preBookingQuesiton.errorMessage}</Typography>}
                                        </div>
                                      )}
                                    </Field>
                                  </Grid>
                                )
                              case ANSWER_TYPES.DROP_DOWN:
                                return (
                                  <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
                                    <Field name={`preBookingQuestions[${idx}].answer`} validate={(value: any) => validateAnswer(value, preBookingQuesiton.required, "TEXT")}>
                                      {({ field, meta: { touched, error }, }: FieldProps) => (
                                        <>
                                          <div className={`${styles.bookingQuestion} ${touched && (Boolean(error)) ? styles.error : ''}`}>
                                            <div className={styles.rowFlex}>
                                              <div style={{ display: 'flex', flex: 1, paddingRight: 15 }}>
                                                <Typography>{`${preBookingQuesiton.question} ${preBookingQuesiton.required ? "*" : ""}`}</Typography>
                                              </div>
                                              <div className={styles.dropdownWrap}>
                                                <StyledInput
                                                  select
                                                  {...field}
                                                  fullWidth
                                                  variant="outlined"
                                                  error={touched && Boolean(error)}
                                                  disabled={formikProps.values.source === BOOKING_SOURCE.B2B2C}
                                                >
                                                  {preBookingQuesiton.options && preBookingQuesiton.options.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                      {option}
                                                    </MenuItem>
                                                  ))}
                                                </StyledInput>
                                              </div>
                                            </div>
                                            {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{preBookingQuesiton.errorMessage}</Typography>}
                                          </div>
                                        </>
                                      )}
                                    </Field>
                                  </Grid>
                                )
                              case ANSWER_TYPES.YES_NO:
                                return (
                                  <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
                                    <Field
                                      name={`preBookingQuestions[${idx}].answer`}
                                      validate={(value: any) => validateAnswer(value, preBookingQuesiton.required, preBookingQuesiton.expectedAnswer)}
                                    >
                                      {({ field, meta: { touched, error }, }: FieldProps) => (
                                        <RadioGroup {...field} onClick={field.onBlur}>
                                          <div className={`${styles.bookingQuestion} ${touched && (Boolean(error)) ? styles.error : ''}`}>
                                            <div className={styles.rowFlex}>
                                              <div style={{ display: 'flex', flex: 1, paddingRight: 15 }}>
                                                <Typography>{`${preBookingQuesiton.question} ${preBookingQuesiton.required ? "*" : ""}`}</Typography>
                                              </div>
                                              <div className={styles.yesnoWrap}>
                                                <Radio value="YES" id={`yes_no_${idx}_yes`} style={{ display: 'none' }} disabled={formikProps.values.source === BOOKING_SOURCE.B2B2C} />
                                                <label htmlFor={`yes_no_${idx}_yes`} className={`${styles.yesnoButton} ${styles.left} ${field.value === "YES" ? styles.active : ''}`}>Yes</label>
                                                <Radio value="NO" id={`yes_no_${idx}_no`} style={{ display: 'none' }} disabled={formikProps.values.source === BOOKING_SOURCE.B2B2C} />
                                                <label htmlFor={`yes_no_${idx}_no`} className={`${styles.yesnoButton} ${styles.right} ${field.value === "NO" ? styles.active : ''}`}>No</label>
                                              </div>
                                            </div>
                                            {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{preBookingQuesiton.errorMessage}</Typography>}
                                          </div>
                                        </RadioGroup>
                                      )
                                      }
                                    </Field>
                                  </Grid>
                                )
                              case ANSWER_TYPES.TEXT:
                                return (
                                  <Grid item xs={12} sm={6}>
                                    <Field name={`preBookingQuestions[${idx}].answer`} validate={(value: any) => validateAnswer(value, preBookingQuesiton.required, 'TEXT')}>
                                      {({ field, meta: { touched, error }, }: FieldProps) => (
                                        <>
                                          <Typography variant="body1">{`${preBookingQuesiton.question} ${preBookingQuesiton.required ? "*" : ""}`}</Typography>
                                          <StyledInput
                                            variant="outlined"
                                            {...field}
                                            multiline
                                            fullWidth
                                            rows={2}
                                            error={touched && Boolean(error)}
                                            disabled={formikProps.values.source === BOOKING_SOURCE.B2B2C}
                                          />
                                          <Typography variant="body1" style={{ color: '#D13135', padding: '5px 15px 5px 15px' }}>{touched && Boolean(error) && preBookingQuesiton.errorMessage}</Typography>
                                        </>
                                      )}
                                    </Field>
                                  </Grid>
                                )
                            }
                          })}
                        </Grid>
                      </Grid>
                    )}
                  </Form>
                )}
              </Formik>
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  )
}
