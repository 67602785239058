import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CssBaseline,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  Theme,
  Typography,
  CircularProgress
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber, Value } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  CREATE_ADMIN_USER,
  UPDATE_ADMIN_USER
} from "../../../../../../graphql/adminUser/adminUser";
import { GET_BRANCHES } from "../../../../../../graphql/organisation/getBranch";
import { GET_ORGANISATIONS } from "../../../../../../graphql/organisation/getOrganisationQuery";
import { GET_USER } from "../../../../../../graphql/organisation/getUsersQuery";
import { GET_USER_ROLES } from "../../../../../../graphql/userPermissions/userAccessQuery";
import { IAdminUser } from "../../../../../../reducers/adminUser/types";
import {
  IBranch,
  IOrganisation
} from "../../../../../../reducers/organisation/types";
import { IAppState } from "../../../../../../store";
import { getDefaultCountryCode, getLocalizedOrganisationSyntex } from "../../../../../../utils/localized.syntex";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../../common/utils";
import {
  validateName
} from "../../../../../common/ValidationRules";
import { UserRoles } from "../../../../../hoc/Authorization";
import { IUserAccessRole } from "../../RolesAndPermission/NewRole";
import { IRowUserRole } from "../../RolesAndPermission/Roles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    }
  })
);


export const NewAdminUser = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address;
  const [userRoles, setUserRoles] = useState<IRowUserRole[]>([]);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [values, setValues] = useState<IAdminUser>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    organisationId: "",
    branchId: "",
    role: "",
    userRole: "",
    active: true
  });
  const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
  const [organisationId, setOrganisationId] = useState("");
  const [isEmailExist, setIsEmaiExist] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode(country));
  const [phoneError, setPhoneError] = useState<string>("");
  const [newUserLoading, setNewUserLoading] = useState(false);
  const [updateUserLoading, setUpdateUserLoad] = useState(false);
  const adminUser: IAdminUser = useSelector(
    (state: IAppState) => state.adminUserReducer.adminUser
  );

  const { data } = useQuery(GET_ORGANISATIONS, {
    fetchPolicy: "network-only"
  });
  const [getBranches, { data: brahcesData }] = useLazyQuery(GET_BRANCHES, {
    fetchPolicy: "network-only"
  });
  const [getUserRoles, { data: userRolesData }] = useLazyQuery(GET_USER_ROLES, {
    fetchPolicy: "network-only",
    onCompleted: () => {

    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });
  const [getUser, { loading: loadingUser, data: userData }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && !data.getUser) {
        navigate("/users");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });
  const [createUser] = useMutation(CREATE_ADMIN_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.createAdminUser) {
        snackbar({
          message: "New user created. Please refresh this page to display the new record.",
          variant: SnackBarVariant.SUCCESS
        });
      }
      setNewUserLoading(false);
      navigate("/users");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setNewUserLoading(false);
    }
  });

  const [updateUser] = useMutation(UPDATE_ADMIN_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.updateAdminUser) {
        snackbar({
          message: "User record updated. Please refresh this page to view changes.",
          variant: SnackBarVariant.SUCCESS
        });
        setUpdateUserLoad(false)
      }
      if (data.updateAdminUser.id === userReducer.id) {
        window.location.reload();
      }
      navigate("/users");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setUpdateUserLoad(false);
    }
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const userId = params.get("user");
      if (userId) {
        getUser({
          variables: {
            userId
          }
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (userData && userData.getUser) {
      if (userData.getUser.email) {
        setIsEmaiExist(true);
      }
      handleOrganisationSelection(userData.getUser.organisation.id);
      setOrganisationId(userData.getUser.organisation.id);
      setValues({
        ...values,
        id: userData.getUser.id,
        firstName: userData.getUser.firstName,
        lastName: userData.getUser.lastName,
        email: userData.getUser.email,
        phoneNumber: userData.getUser.phoneNumber,
        organisationId: userData.getUser.organisation.id,
        branchId: userData.getUser.branch ? userData.getUser.branch.id : values.branchId,
        role: userData.getUser.role,
        userRole: userData.getUser && userData.getUser.userRole ? userData.getUser.userRole.id : "",
        active: userData.getUser.active
      });
    }
  }, [userData]);

  useEffect(() => {
    if (data && data.organisations) {
      setOrganisations(data.organisations);
    }
  }, [data]);


  useEffect(() => {
    if (userRolesData && userRolesData.getUserAccessRolesInOrganisation) {
      let roleToRemove = "";
      if (userReducer.role !== UserRoles.SUPER_ADMIN && userReducer.role !== UserRoles.ADMIN) {
        if (!adminUser.id) {
          roleToRemove = UserRoles.ADMIN;
        } else if (adminUser.id && (adminUser.role === UserRoles.ADMIN || adminUser.role === UserRoles.SUPER_ADMIN)) {
          roleToRemove = "";
          setIsEditDisabled(true);
        } else if (adminUser.id && adminUser.role !== UserRoles.ADMIN && adminUser.role !== UserRoles.SUPER_ADMIN) {
          roleToRemove = UserRoles.ADMIN;
        }
      }
      setUserRoles(userRolesData.getUserAccessRolesInOrganisation.filter((role: Partial<IUserAccessRole>) => role.isActive && role.key && role.key !== roleToRemove));
    }
  }, [userRolesData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleOrganisationSelection = (
    organisationId: string,
  ) => {
    setOrganisationId(organisationId);
    if (organisationId) {
      getBranches({
        variables: {
          tenancyId: userReducer.currentTenancy.id,
          organisationId
        }
      });
      getUserRoles({
        variables: {
          organisationId
        }
      })
      setValues({
        ...values,
        branchId: ''
      })
    }
  };

  const onSubmitHandler = (values: IAdminUser) => {
    values.organisationId = organisationId;
    const user = { ...values };
    delete user.id;
    if (values.id) {
      delete user.email;
      if (!values.active && values.id === userReducer.id) {
        return snackbar({
          message: "You can not deactivate yourself",
          variant: SnackBarVariant.ERROR
        });
      }
      if (!values.branchId) {
        return snackbar({
          message: "Branch is required",
          variant: SnackBarVariant.ERROR
        });
      }
      updateUser({
        variables: {
          userId: values.id,
          user
        }
      });
      setUpdateUserLoad(true);
    } else {
      delete user.active;
      createUser({
        variables: {
          user
        }
      });
      setNewUserLoading(true);
    }
  };

  const userSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email address."),
    organisationId: Yup.string().required("Please select an organisation."),
    branchId: Yup.string().required("Please select a branch."),
    role: Yup.string().required("Please select a role."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    })
  });

  if (loadingUser) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        {values.id ? (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Create
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;User
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            validationSchema={userSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              const updateValues = {
                ...values,
                phoneNumber: {
                  ...values.phoneNumber,
                  country: countryCode
                }
              }
              setSubmitting(false);
              onSubmitHandler(updateValues);
            }}
          >
            {(props) => (
              <Form noValidate>
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="firstName"
                        placeholder="First Name"
                        label="First Name"
                        value={values.firstName}
                        inputProps={{ maxLength: 50 }}
                        onChange={handleChange}
                        fullWidth
                        validate={(value: string) =>
                          validateName(value, "First Name")
                        }
                        disabled={isEditDisabled}
                        required
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="lastName"
                        placeholder="Last Name"
                        label="Last Name"
                        value={values.lastName}
                        inputProps={{ maxLength: 50 }}
                        onChange={handleChange}
                        fullWidth
                        required
                        disabled={isEditDisabled}
                        validate={(value: string) =>
                          validateName(value, "Last Name")
                        }
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="Email Address"
                        label="Email Address"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        fullWidth
                        required
                        InputLabelProps={{
                          shrink: true
                        }}
                        disabled={isEmailExist}
                      ></Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name={"phoneNumber.phone"}
                        required
                      >
                        {({ form: { }, meta }: FieldProps) => {
                          return (
                            <div className={`phone-input-container`}>
                              <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                                Phone Number <sup className={"phone-input-required"}>*</sup>
                              </label>
                              <PhoneInput
                                international={false}
                                defaultCountry={countryCode}
                                placeholder="Enter phone number"
                                className={phoneError ? "phone-input-error" : ""}
                                value={props.values.phoneNumber.phone}
                                name={"phoneNumber.phone"}
                                required
                                onChange={(val: any) => {
                                  if (val && isValidPhoneNumber(val)) {
                                    setPhoneError("");
                                  }
                                  setValues({
                                    ...props.values,
                                    phoneNumber: {
                                      ...props.values.phoneNumber,
                                      phone: val
                                    }
                                  });
                                }}
                                onBlur={() => {
                                  if (!props.values.phoneNumber.phone) {
                                    setPhoneError("Phone number is required.");
                                  } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                    setPhoneError("Enter a valid phone number.");
                                  } else {
                                    setPhoneError("");
                                  }
                                }}
                                disabled={isEditDisabled}
                                onCountryChange={(val) => {
                                  setCountryCode(val);
                                }}
                              />
                              {
                                phoneError !== "" ?
                                  <span className={"phone-error-message"}>{phoneError}</span>
                                  : (meta.touched && meta.error) &&
                                  <span className={"phone-error-message"}>
                                    {phoneError || "Phone number is required."}
                                  </span>
                              }
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name="organisationId"
                          fullWidth
                          select
                          label={getLocalizedOrganisationSyntex(country)}
                          required
                          disabled={isEditDisabled}
                          inputProps={{
                            onChange: (event: React.ChangeEvent<HTMLFormElement>) => {
                              getUserRoles({
                                variables: {
                                  organisationId: event.target.value
                                }
                              });
                              handleOrganisationSelection(
                                event.target.value
                              );
                            },
                            value: organisationId
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          {organisations &&
                            _.sortBy(organisations, (organisation) => organisation.name.toLowerCase()).map((organisation: IOrganisation) => (
                              <MenuItem
                                key={organisation.id}
                                value={organisation.id}
                              >
                                {organisation.name}
                              </MenuItem>
                            ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name="branchId"
                          fullWidth
                          select
                          required
                          label="Branch"
                          value={values.branchId}
                          InputProps={{
                            onChange: (event: any) => {
                              setValues({
                                ...props.values,
                                branchId: event.target.value
                              });
                            }
                          }}
                          disabled={isEditDisabled}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          {brahcesData &&
                            brahcesData.branches &&
                            _.sortBy(brahcesData.branches, (branch) => branch.name.toLowerCase()).map((branch: IBranch) => {
                              return (
                                <MenuItem key={branch.id} value={branch.id}>
                                  {branch.name}
                                </MenuItem>
                              );
                            })}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name="role"
                          fullWidth
                          select
                          label="Role"
                          value={props.values.userRole}
                          InputProps={{
                            onChange: (event: any) => {
                              const selectedUserRole = userRoles.find((userrole) => userrole.id === event.target.value);
                              setValues({
                                ...props.values,
                                role: selectedUserRole ? selectedUserRole?.key : "",
                                userRole: event.target.value
                              });
                            }
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled={isEditDisabled}
                          required
                        >
                          {userRoles && userRoles.length > 0 &&
                            _.sortBy(userRoles, (role) => role.name.toLowerCase()).map((role: IRowUserRole, index: number) => (
                              <MenuItem key={index} value={role.id}>
                                {role.name}
                              </MenuItem>
                            ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    {props.values && props.values.id && (
                      <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.active}
                              onChange={(event: any) => {
                                setValues({
                                  ...values,
                                  active: event.target.checked
                                });
                              }}
                              name="active"
                              color="primary"
                            />
                          }
                          disabled={isEditDisabled || values.id === userReducer.id}
                          label={!props.values.active ? "Activate" : "Active"}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container xs={12} justifyContent="flex-start">
                    <Fab
                      variant="extended"
                      size="medium"
                      type="submit"
                      aria-label="add"
                      disabled={!values.phoneNumber || !isValidPhoneNumber || isEditDisabled || newUserLoading || updateUserLoading}
                    >
                      {(newUserLoading || updateUserLoading) && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      SAVE
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

