import { useMutation } from "@apollo/client";
import {
  CircularProgress,
  Tab,
  Tabs,
  Grid,
  Fab,
  Theme
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { CREATE_PAYMENT_FOR_EXTENSiON } from "../../../../../graphql/invoices/createPaymentForExtensionMutation";
import {
  BookingExtensionStatus, IBookingExtension
} from "../../../../../reducers/bookings/types";
import { IBusinessCustomer, ICustomer } from "../../../../../reducers/customer/types";
import {
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { TabPanel } from "../../../../common/TabPannel/TabPannel";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import BacsTransactionExtension from "./BacsTransactionExtension";
import CardTransactionExtension from "./CardTransactionExtension";
import PayOnReturnExtension from "./PayOnReturnExtension";
import SepaTransactionExtension from "./SepaTransactionExtension";
import AutoChargeForExtension from "./AutoChargeExtension";

interface IProps {
  bookingData: any;
  extensionData: IBookingExtension;
  amountToPay: number;
  handleClose(type?: boolean): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexGrow: 1
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "unset"
  }
}));

const PaymentViewExtension: React.FC<IProps> = (props) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [isBusinessCustomerBooking, setIsBusinessCustomerBooking] = useState<
    boolean
  >();
  const [businessCustomer, setBusinessCustomer] = useState<IBusinessCustomer>();
  const [customer, setCustomer] = useState<ICustomer>();
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { currency, autoChargeEnabled } = userState.currentOrganisation;

  const [createPaymentForExtension, { loading, data: createExtensionPaymentData }] = useMutation(CREATE_PAYMENT_FOR_EXTENSiON, {
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    onCompleted: async () => {
      navigate(`/view-booking?booking=${props.bookingData.id}`);
      props.handleClose()
      snackbar({
        message: "Booking extended successfully!",
        variant: SnackBarVariant.SUCCESS
      })
    }

  });

  useEffect(() => {
    if (props.bookingData) {
      const isBusinessCustomerBooking =
        props.bookingData.customerType === "business" &&
          props.bookingData.businessCustomer &&
          props.bookingData.businessCustomer.id !== ""
          ? true
          : false;
      setIsBusinessCustomerBooking(isBusinessCustomerBooking);
      setBusinessCustomer(props.bookingData.businessCustomer);
      setCustomer(props.bookingData.customer);
    }
  }, [props.bookingData]);

  useEffect(() => {
    if (createExtensionPaymentData && createExtensionPaymentData.createPayment) {
      navigate(`/view-booking?booking=${props.bookingData?.id}`);
    }
  }, [createExtensionPaymentData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const submitPayment = (data: IPaymentInput) => {
    setPaymentInput(data);
    createPaymentForExtension({
      variables: {
        payment: data
      }
    })
  };

  return (
    <>
      {
        !props.amountToPay ?
          <>
            <Grid container xs={12} justifyContent="flex-start">
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                onClick={() => {
                  submitPayment({
                    paymentMode: PaymentMode.CASH,
                    paymentType: "INWARD",
                    amount: 0,
                    booking: props.bookingData.id,
                    bookingExtension: props.extensionData.id,
                    currency,
                    description: PaymentMode.CASH,
                    expireBy: "",
                    invoice: "",
                  })
                }}
              >
                {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                Proceed
              </Fab>
            </Grid>
          </> :
          <>
            <div className={classes.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Payment tabs"
                className={classes.tabs}
              >
                <Tab label={PaymentMode.CARD} />
                {currency === "EUR" && <Tab label={PaymentMode.SEPA_BANK_DEBIT} />}
                {currency === "GBP" && <Tab label={PaymentMode.BACS_BANK_DEBIT} />}
                {props.extensionData.status === BookingExtensionStatus.QUOTE && (
                  <Tab label={PaymentMode.PAY_BY_COLLECTION} />
                )}
                {autoChargeEnabled && <Tab label={PaymentMode.AUTO_CHARGE} />}
              </Tabs>
              <TabPanel value={value} index={0}>
                <CardTransactionExtension
                  bookingId={props.bookingData.id}
                  extensionId={props.extensionData.id}
                  amount={props.amountToPay}
                  handleClose={props.handleClose}
                />
              </TabPanel>
              {currency === "EUR" && <TabPanel value={value} index={1}>
                <SepaTransactionExtension
                  bookingId={props.bookingData.id}
                  extensionId={props.extensionData.id}
                  amount={props.amountToPay}
                />
              </TabPanel>}
              {currency === "GBP" && <TabPanel value={value} index={1}>
                <BacsTransactionExtension
                  bookingId={props.bookingData.id}
                  extensionId={props.extensionData.id}
                  amount={props.amountToPay}
                />
              </TabPanel>}
              <TabPanel value={value} index={["GBP", "EUR"].includes(currency) ? 2 : 1}>
                <PayOnReturnExtension
                  onSubmit={submitPayment}
                  bookingId={props.bookingData.id}
                  extensionId={props.extensionData.id}
                  amount={props.amountToPay}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <AutoChargeForExtension
                  bookingId={props.bookingData.id}
                  bookingAutoChargeMode={props.bookingData.autoChargeMode}
                  extensionId={props.extensionData.id}
                  bookingAutoChargeSetup={props.bookingData.autoChargeLater}
                  amount={props.amountToPay}
                  paymentMethodId={props.bookingData.stripePaymentMethodId}
                  handleClose={props.handleClose}
                />
              </TabPanel>
            </div>
          </>
      }
    </>
  );
};

export default PaymentViewExtension;
