import { gql } from "@apollo/client";

export const CREATE_VEHICLE_CHECKLIST = gql`
  mutation createVehicleCheckList($vehicleCheckList: VehicleCheckListInput!) {
    createVehicleCheckList(vehicleCheckList: $vehicleCheckList) {
      id
      generalCheckList
      accessoriesCheckList
      frogDiagram
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;