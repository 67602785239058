import { Value } from "react-phone-number-input";

import { ChargebeePlanType, IUserState } from "./types";

export const initialState: IUserState = {
  email: "",
  firstName: "",
  lastName: "",
  active: true,
  phoneNumber: {
    phone: "" as Value,
    country: "GB"
  },
  tenancy: {
    id: "",
    organisations: [],
    name: "",
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    logoUri: "",
    emailId: "",
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    chargebeeCustomerId: "",
    chargebeePlanId: "",
    chargebeePlanType: ChargebeePlanType.TRIAL,
    chargebeeSubscriptionExpiry: "",
    chargebeeSubscriptionId: "",
    billing: {
      // orgName: "",
      address: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      },
      billingAddressSame: false,
      emailId: "",
      name: "",
      phoneNumber: {
        phone: "" as Value,
        country: "GB"
      }
    },
    epyxAccountNumber: "",
    epyxSchemeReferenceNumber: "",
    enableEpyxIntegration: false
  },
  currentTenancy: {
    id: "",
    organisations: [],
    name: "",
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    logoUri: "",
    emailId: "",
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    chargebeeCustomerId: "",
    chargebeePlanId: "",
    chargebeePlanType: ChargebeePlanType.TRIAL,
    chargebeeSubscriptionExpiry: "",
    chargebeeSubscriptionId: "",
    billing: {
      // orgName: "",
      address: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      },
      billingAddressSame: false,
      emailId: "",
      name: "",
      phoneNumber: {
        phone: "" as Value,
        country: "GB"
      }
    },
    epyxAccountNumber: "",
    epyxSchemeReferenceNumber: "",
    enableEpyxIntegration: false
  },
  role: "",
  userRole: {
    id: "",
    key: "",
    name: "",
    policies: []
  },
  currentOrganisation: {
    id: "",
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    locale: "en-GB",
    branches: [],
    cashEnabled: true,
    cardEnabled: true,
    payOnCollectionEnabled: true,
    requiresCustomerVerification: false,
    offlineBankTransferEnabled: true,
    payByLinkEnabled: true,
    autoChargeEnabled: false,
    emailId: "",
    name: "",
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    termsAndConditionsUrl: "",
    currency: "GBP",
    stripeAccountId: "",
    taxes: [],
    orgVatNumber: "",
    orgRegNumber: "",
    logoUrl: "",
    sageAccounting: {
      active: false
    },
    allowCarSharing: false,
    disableMapForCarShareVehicles: false,
    inspectionCheckEnabled: false,
    xeroEnabled: false,
    allowSubscriptionBookings: false
  },
  currentBranch: {
    id: "",
    groupName: "",
    timeZone: "Europe/London",
    locations: [],
    name: "",
    preBookingQuestions: [],
    additionalDriverQuestions: [],
    isAdditionalDriverQuestionsEnabled: false,
    deliveryQuestions: [],
    distanceUnit: "",
    vehiclePreparationTime: 15,
    carShareGracePeriodMins: 15,
    holidays: [],
    taxes: []
  },
  organisation: {
    id: "",
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    locale: "en-GB",
    branches: [],
    cashEnabled: true,
    cardEnabled: true,
    payOnCollectionEnabled: true,
    requiresCustomerVerification: false,
    offlineBankTransferEnabled: true,
    payByLinkEnabled: true,
    autoChargeEnabled: false,
    emailId: "",
    name: "",
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    termsAndConditionsUrl: "",
    currency: "GBP",
    stripeAccountId: "",
    taxes: [],
    orgVatNumber: "",
    orgRegNumber: "",
    logoUrl: "",
    sageAccounting: {
      active: false
    },
    allowCarSharing: false,
    disableMapForCarShareVehicles: false,
    inspectionCheckEnabled: false,
    allowSubscriptionBookings: false
  },
  branch: {
    id: "",
    groupName: "",
    timeZone: "",
    locations: [],
    name: "",
    preBookingQuestions: [],
    isAdditionalDriverQuestionsEnabled: false,
    additionalDriverQuestions: [],
    deliveryQuestions: [],
    distanceUnit: "",
    vehiclePreparationTime: 15,
    carShareGracePeriodMins: 15,
    holidays: [],
    taxes: []
  }
};
