import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  Radio,
  RadioGroup,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CHECK_BOOKING_CONFIRMATION_ELIGIBILITY } from "../../../../../graphql/bookings/checkBookingConfirmationEligibility";
import { CHECK_FOR_TBA_WARNINGS } from "../../../../../graphql/bookings/CheckForTbaWarnings";
import { CREATE_PAYMENT } from "../../../../../graphql/invoices/createPaymentMutation";
import {
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  PaymentGateway,
  formatGraphQLErrorMessage
} from "../../../../common/utils";
import { ICreditNotesToAdjust } from "./PaymentView";

interface IProps {
  bookingId: string;
  invoice: string;
  loading?: boolean;
  amount: number;
  onSubmit(): void;
  collectDeposit?: boolean;
  depositAmount?: number;
  tba?: boolean;
  creditNotesToAdjust: ICreditNotesToAdjust[];
  creditAmount: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    basicButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      }
    }
  })
);

export const PayOnCollection: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const {
    locale,
    currency,
    stripeAccountId,
    cardEnabled,
    payOnCollectionEnabled,
    convergeCredentials
  } = userState.currentOrganisation;
  const [paymentType, setPaymentType] = useState<string>("payLater");
  const [tbaWarning, setTbaWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(props.loading || false);
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();
  const [paymentGateway, setPaymentGateway] = useState<string>(
    PaymentGateway.STRIPE
  );

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (data?.createPayment?.payment?.id) {
        if (paymentGateway === PaymentGateway.STRIPE) {
          setLoading(false);
          navigate(
            `/payment?booking=${props.bookingId}&payment=${data.createPayment.payment.id}`
          );
        } else if (paymentGateway === PaymentGateway.CONVERGE) {
          window.location =
            data.createPayment.payment.convergePaymentSessionUrl;
        }
      }
    }
  });

  const [checkForTbaWarnings] = useLazyQuery(CHECK_FOR_TBA_WARNINGS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      navigate(`/update-booking?booking=${props.bookingId}&step=3`);
    },
    onCompleted: (data) => {
      if (data.checkForTbaWarnings) {
        setTbaWarning(true);
      } else {
        if (paymentInput) {
          createPayment({
            variables: {
              payment: {
                ...paymentInput,
                booking: props.bookingId
              },
              collectDeposit: props.collectDeposit,
              creditNotesToAdjust: props.creditNotesToAdjust
            }
          });
        }
      }
    }
  });

  const [checkBookingConfirmationEligibility] = useLazyQuery(
    CHECK_BOOKING_CONFIRMATION_ELIGIBILITY,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        navigate(`/update-booking?booking=${props.bookingId}&step=3`);
      },
      onCompleted: () => {
        if (paymentInput) {
          if (props.tba) {
            checkForTbaWarnings({
              variables: {
                bookingId: props.bookingId
              }
            });
          } else {
            createPayment({
              variables: {
                payment: {
                  ...paymentInput,
                  booking: props.bookingId
                },
                collectDeposit: props.collectDeposit,
                creditNotesToAdjust: props.creditNotesToAdjust
              }
            });
          }
        }
      }
    }
  );

  const handleSubmit = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: props.amount,
      booking: props.bookingId,
      paymentGateway,
      currency,
      description: PaymentMode.CARD,
      expireBy: "",
      invoice: props.invoice,
      paymentMode: PaymentMode.CARD,
      paymentType: "INWARD",
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/update-booking?booking=${props.bookingId}&step=3`,
      captureLater: true
    };
    setPaymentInput(payment);
    checkBookingConfirmationEligibility({
      variables: {
        bookingId: props.bookingId
      }
    });
  };

  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!payOnCollectionEnabled ? (
        <Grid item container xs={12}>
          <Typography variant="h4">
            To use this payment mode please enable pay on collection in Payment
            and Integration Settings.
          </Typography>
        </Grid>
      ) : (
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                name="isPayingFull"
                value={paymentType}
                onChange={(event) => {
                  if (event.target.value === "preAuth") {
                    setPaymentType("preAuth");
                  } else {
                    setPaymentType("payLater");
                  }
                }}
              >
                <FormControlLabel
                  value="payLater"
                  control={<Radio />}
                  label={
                    <Typography variant="body1">Pay on Collection</Typography>
                  }
                />
                <FormControlLabel
                  value="preAuth"
                  control={<Radio />}
                  label={
                    <Typography variant="body1">Pre-Authorise Card</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {paymentType === "payLater" && (
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  You can take and add payment to the invoice under 'Billing'
                  later on.
                </Typography>
              </Grid>
              <Grid item xs={12} justifyContent="flex-start">
                <Hidden smDown>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    type="button"
                    onClick={() => {
                      props.onSubmit();
                    }}
                    disabled={props.loading}
                  >
                    {props.loading && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}
                    {PaymentMode.PAY_BY_COLLECTION}
                  </Fab>
                </Hidden>
                <Hidden smUp>
                  <Button
                    style={{ width: "calc(100vw - 90px)" }}
                    variant="contained"
                    className={classes.basicButton}
                    type="button"
                    aria-label="add"
                    onClick={() => {
                      props.onSubmit();
                    }}
                    disabled={props.loading}
                  >
                    {props.loading && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}
                    {PaymentMode.PAY_BY_COLLECTION}
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
          )}
          {paymentType === "preAuth" && (
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Please proceed to collect card details for pre-authorisation
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    name="paymentGateway"
                    value={paymentGateway}
                    onChange={(event) => {
                      setPaymentGateway(event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={PaymentGateway.STRIPE}
                      control={<Radio />}
                      disabled={loading}
                      label={<Typography variant="body1">STRIPE</Typography>}
                    />
                    <FormControlLabel
                      value={PaymentGateway.CONVERGE}
                      control={<Radio />}
                      disabled={loading}
                      label={<Typography variant="body1">CONVERGE</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {paymentGateway === PaymentGateway.STRIPE &&
                (stripeAccountId ? (
                  <Grid item xs={12} justifyContent="flex-start">
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="button"
                      onClick={handleSubmit}
                      disabled={props.loading || loading}
                    >
                      {loading && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      Proceed
                    </Fab>
                  </Grid>
                ) : (
                  <Grid container xs={12}>
                    <Typography style={{ margin: "1rem" }}>
                      Please add Stripe Account Id in Payments & Integrations
                      settings to use this feature.
                    </Typography>
                  </Grid>
                ))}
              {paymentGateway === PaymentGateway.CONVERGE &&
                (convergeCredentials?.merchantAccountId ? (
                  <Grid item xs={12} justifyContent="flex-start">
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="button"
                      onClick={handleSubmit}
                      disabled={props.loading || loading}
                    >
                      {loading && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      Proceed
                    </Fab>
                  </Grid>
                ) : (
                  <Grid container xs={12}>
                    <Typography style={{ margin: "1rem" }}>
                      Please add Converge Credentials in Payments & Integrations
                      settings to use this feature.
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
      )}
      {tbaWarning && (
        <ConfirmationDialog
          isOpen={tbaWarning}
          onCancel={() => {
            setTbaWarning(false);
            navigate(`/update-booking?booking=${props.bookingId}&step=3`);
          }}
          onConfirm={() => {
            if (paymentInput) {
              setTbaWarning(false);
              createPayment({
                variables: {
                  payment: {
                    ...paymentInput,
                    booking: props.bookingId
                  },
                  collectDeposit: true,
                  creditNotesToAdjust: props.creditNotesToAdjust
                }
              });
            }
          }}
          title=""
          description={
            "The vehicles in this booking have some future bookings. Do you wish to continue to with this TBA booking?"
          }
        />
      )}
    </Grid>
  );
};
