export const partNerTypes = [
	{
		label: "Car Dealer",
		value: "CAR_DEALER"
	},
	{
		label: "Garage",
		value: "GARAGE"
	}
]

export enum PartnerType {
	CAR_DEALER = "CAR_DEALER",
	GARAGE = "GARAGE"
}

export const PartnerTypeValue: {
	[key in PartnerType]: string;
} = {
	CAR_DEALER: "Car Dealer",
	GARAGE: "Garage"
};
