import {
  Grid,
  CssBaseline,
  Typography,
  Paper,
  FormControl,
  MenuItem,
  Fab,
  Theme,
  Box
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ApolloError } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../common/utils";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { frogDiagramTypes } from "../VehicleInventory/ViewVehicle/Details/constants";
import { AddCustomCheckDialog } from "./AddCustomCheckDialog";
import { IVehicleCheckList, IVehicleCheckListInput } from "../../../../reducers/fleet/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_VEHICLE_CHECKLIST } from "../../../../graphql/fleet/vehicleCheckList/createVehicleCheckList";
import { GET_VEHICLE_CHECKLIST } from "../../../../graphql/fleet/vehicleCheckList/getCheckList";
import { GET_ACTIVE_FROGDIAGRAMS } from "../../../../graphql/fleet/vehicleCheckList/getActiveFrogDiagrams";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { CircularProgress } from "@mui/material";
import { VehicleBodyTypes } from "../../../common/VehicleDamagePreview/frogDiagram";

const defalutSuggestedGeneralChecks = [
  "Washer/Wiper", "Dashboard", "Horn Sound", "Interior Lights", "Sunroof", "Seat Belt", "Ash Tray", "Glove Box", "Indicators", "12v USB", "Brake Light",
  "Hazard Light", "Number of Keys", "SAT Nav", "Pump Kit", "Rear Mirror", "Electric Window", "Electric Mirror", "Tow Bar", "Media Screen", "NS Mirror",
  "Engine Oil Level", "Brake Fluid", "Coolant", "Front Right Tyre Pressure", "Rear Right Tyre Pressure", "Front Left Tyre Pressure",
  "Rear Left Tyre Pressure", "Wheel Nut Torque Setting", "Rear Screen/Window", "Boot Carpet", "Front Carpet", "Aerial", "Windscreen", "Bonnet",
  "Book Pack", "Rear Bumper", "Front Bumper", "Roof Panel", "Roof Bar/Rack", "Front Grill", "CD/DVD", "Radio/DVD Rear Media", "iPod Connector",
  "Clean Exterior", "Clean Interior", "Water Level", "Service Book", "Wheel Trims", "Next service due sticker"
];

const defalutTwoWheelerSuggestedGeneralChecks = [
  "Oil Change", "Oil filter", "Air Filter", "Spark Plug", "Fuel System", "Brakes", "Tires and Wheels", "Suspension", "Drive Belt/Chain", "Battery",
  "Lights", "Controls", "Exhaust System", "Coolant", "Transmission/Clutch", "Belts and Hoses", "Chassis", "Electrical System", "Steering", "Safety Systems",
  "Fasteners"
];

const defalutSuggestedAccessoriesChecks = [
  "Jack", "Wrench", "Tool Box", "Stepney", "Rear Camera", "Tool Brace"
];

const defalutTwoWheelerSuggestedAccessoriesChecks = ["Tool Box"];

const defaultVehicleCheckList: IVehicleCheckListInput = {
  generalCheckList: [],
  accessoriesCheckList: [],
  frogDiagram: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3),
      width: "100%"
    },
    section: {
      marginBottom: "25px"
    }
  })
);

export const NewVehicleCheckList: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IVehicleCheckListInput>(defaultVehicleCheckList);
  const [selectedGeneralChecks, setSelectedGeneralChecks] = useState<string[]>([]);
  const [selectedAccessoriesChecks, setSelectedAccessoriesChecks] = useState<string[]>([]);
  const [suggestedGeneralChecks, setSuggestedGeneralChecks] = useState<string[]>([]);
  const [suggestedAccessoriesChecks, setSuggestedAccessoresChecks] = useState<string[]>([]);
  const [frogDiagrams, setFrogDiagrams] = useState<any[]>(frogDiagramTypes);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [checkType, setCheckType] = useState<string>("");

  const [getVehicleCheckList, { data }] = useLazyQuery(GET_VEHICLE_CHECKLIST, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [getActiveFrogDiagrams, {
    data: activeFrogDiagramData
  }] = useLazyQuery(GET_ACTIVE_FROGDIAGRAMS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createVehicleCheckList, { loading: createVehicleCheckListLoading }] = useMutation(CREATE_VEHICLE_CHECKLIST, {
    onCompleted: () => {
      snackbar({
        message: "Vehicle Check list created",
        variant: SnackBarVariant.SUCCESS
      });
      navigate('/vehicle-checklist');
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (userState && userState.id) {
      getActiveFrogDiagrams();
      setSelectedAccessoriesChecks([]);
      setSelectedGeneralChecks([]);
      setValues({
        ...values,
        frogDiagram: ""
      })
    }
  }, [userState]);

  useEffect(() => {
    if (activeFrogDiagramData &&
      activeFrogDiagramData.getActiveFrogDiagrams &&
      activeFrogDiagramData.getActiveFrogDiagrams.length) {
      setFrogDiagrams(frogDiagramTypes.filter((frogDiagram: any) => activeFrogDiagramData.getActiveFrogDiagrams.includes(frogDiagram.value)));
    } else {
      setFrogDiagrams([]);
    }
  }, [activeFrogDiagramData])

  useEffect(() => {
    if (data) {
      if (data.getVehicleCheckList) {
        const vehicleCheckListData: IVehicleCheckList = data.getVehicleCheckList;
        setValues({
          ...values,
          id: vehicleCheckListData.id,
          frogDiagram: vehicleCheckListData.frogDiagram,
          generalCheckList: vehicleCheckListData.generalCheckList,
          accessoriesCheckList: vehicleCheckListData.accessoriesCheckList
        });
        setSelectedGeneralChecks(data.getVehicleCheckList.generalCheckList);
        setSelectedAccessoriesChecks(data.getVehicleCheckList.accessoriesCheckList);
        if (values.frogDiagram === VehicleBodyTypes.SCOOTER) {
          setSuggestedGeneralChecks(defalutTwoWheelerSuggestedGeneralChecks.filter(
            (generalCheck: string) => !data.getVehicleCheckList.generalCheckList.includes(generalCheck)
          ));
          setSuggestedAccessoresChecks(defalutTwoWheelerSuggestedAccessoriesChecks.filter(
            (accessoryCheck: string) => !data.getVehicleCheckList.accessoriesCheckList.includes(accessoryCheck)
          ));
        } else {
          setSuggestedGeneralChecks(defalutSuggestedGeneralChecks.filter(
            (generalCheck: string) => !data.getVehicleCheckList.generalCheckList.includes(generalCheck)
          ));
          setSuggestedAccessoresChecks(defalutSuggestedAccessoriesChecks.filter(
            (accessoryCheck: string) => !data.getVehicleCheckList.accessoriesCheckList.includes(accessoryCheck)
          ));
        }
      } else {
        if (values.frogDiagram === VehicleBodyTypes.SCOOTER) {
          setSuggestedGeneralChecks(defalutTwoWheelerSuggestedGeneralChecks.filter(
            (generalCheck: string) => !data.getVehicleCheckList?.generalCheckList.includes(generalCheck)
          ));
          setSuggestedAccessoresChecks(defalutTwoWheelerSuggestedAccessoriesChecks.filter(
            (accessoryCheck: string) => !data.getVehicleCheckList?.accessoriesCheckList.includes(accessoryCheck)
          ));
        } else {
          setSuggestedGeneralChecks(defalutSuggestedGeneralChecks.filter(
            (generalCheck: string) => !data.getVehicleCheckList?.generalCheckList.includes(generalCheck)
          ));
          setSuggestedAccessoresChecks(defalutSuggestedAccessoriesChecks.filter(
            (accessoryCheck: string) => !data.getVehicleCheckList?.accessoriesCheckList.includes(accessoryCheck)
          ));
        }
        setSelectedGeneralChecks([]);
        setSelectedAccessoriesChecks([]);
      }
    }
  }, [data]);

  const onSubmitHandler = (values: IVehicleCheckListInput) => {
    const vehicleCheckList = { ...values };
    if (selectedGeneralChecks && !selectedGeneralChecks.length) {
      return snackbar({
        message: "Please select at least one general check",
        variant: SnackBarVariant.ERROR
      });
    }
    if (selectedAccessoriesChecks && !selectedAccessoriesChecks.length) {
      return snackbar({
        message: "Please select at least one accessories check",
        variant: SnackBarVariant.ERROR
      });
    }
    vehicleCheckList.generalCheckList = [...selectedGeneralChecks];
    vehicleCheckList.accessoriesCheckList = [...selectedAccessoriesChecks];
    delete vehicleCheckList.id;
    createVehicleCheckList({
      variables: {
        vehicleCheckList
      }
    });
  };

  const handleAddCustomCheck = (customCheck: string) => {
    if (checkType === "GeneralCheck") {
      setSelectedGeneralChecks([customCheck, ...selectedGeneralChecks]);
    } else {
      setSelectedAccessoriesChecks([customCheck, ...selectedAccessoriesChecks]);
    }
    setOpenDialog(false);
    setCheckType("");
  }

  const vehicleCheckListSchema = Yup.object().shape({
    frogDiagram: Yup.string().required("Please select a frog diagram."),
  });

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          &nbsp;Create Vehicle Checklist
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            validationSchema={vehicleCheckListSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              const updateValues = {
                ...values
              }
              setSubmitting(false);
              onSubmitHandler(updateValues);
            }}
          >
            {(props) => (
              <Form noValidate>
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name="frogDiagram"
                          fullWidth
                          select
                          label="Select frog diagram"
                          value={props.values.frogDiagram}
                          InputProps={{
                            onChange: (event: any) => {
                              setValues({
                                ...props.values,
                                frogDiagram: event.target.value
                              });
                              if (event.target.value) {
                                getVehicleCheckList({
                                  variables: {
                                    frogDiagram: event.target.value
                                  }
                                })
                              }
                            }
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          required
                        >
                          {frogDiagrams &&
                            frogDiagrams.map((item: any, index: number) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {props.values.frogDiagram && (
                    <React.Fragment>
                      <Grid item container xs={12} spacing={3}>
                        <Grid item xs={9} style={{ paddingBottom: 0 }}>
                          <Typography variant="subtitle1">Vehicle General Checks</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container justifyContent="flex-end" style={{ height: 30 }}>
                            <Typography
                              variant="subtitle2"
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                              onClick={() => {
                                setOpenDialog(true)
                                setCheckType("GeneralCheck");
                              }}
                            >
                              + Add Custom General Checks
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">Suggested Checks</Typography>
                          <Box
                            style={{
                              maxHeight: "80vh",
                              overflow: "auto"
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              style={{
                                minHeight: "75vh",
                                marginTop: 10
                              }}
                            >
                              {suggestedGeneralChecks.map((suggestedCheck: string, idx: number) => {
                                return (
                                  <Grid item xs={12} key={idx}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        margin: "5px",
                                        marginLeft: "16px",
                                        marginRight: "16px"
                                      }}
                                    >
                                      <Grid container style={{ padding: "5px 0" }}>
                                        <Grid item xs={11}>
                                          <p style={{
                                            padding: 0,
                                            margin: 0,
                                            marginLeft: 10,
                                            fontSize: 14,
                                            marginTop: 5
                                          }}>
                                            {suggestedCheck}
                                          </p>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <span
                                            onClick={() => {
                                              setSelectedGeneralChecks([suggestedCheck, ...selectedGeneralChecks]);
                                              setSuggestedGeneralChecks(suggestedGeneralChecks.filter(check => check !== suggestedCheck));
                                            }}
                                            style={{ color: "white" }}
                                          >
                                            <AddIcon
                                              style={{
                                                marginTop: 5,
                                                marginLeft: 10,
                                                backgroundColor: "var(--theme-accent)",
                                                cursor: "pointer",
                                                fontSize: 16,
                                                borderRadius: 2
                                              }}
                                            />
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">Selected Checks</Typography>
                          <Box
                            style={{
                              maxHeight: "80vh",
                              overflow: "auto"
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              style={{
                                minHeight: "75vh",
                                marginTop: 10
                              }}
                            >
                              {selectedGeneralChecks.map((selectedCheck: string, idx: number) => {
                                return (
                                  <Grid item xs={12} key={idx}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        margin: "5px",
                                        marginLeft: "16px",
                                        marginRight: "16px"
                                      }}
                                    >
                                      <Grid container style={{ padding: "5px 0" }}>
                                        <Grid item xs={11}>
                                          <p style={{
                                            padding: 0,
                                            margin: 0,
                                            marginLeft: 10,
                                            fontSize: 14,
                                            marginTop: 5
                                          }}>
                                            {selectedCheck}
                                          </p>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <span
                                            onClick={() => {
                                              const existsInSuggestedGeneralChecks = defalutSuggestedGeneralChecks.includes(selectedCheck);
                                              setSelectedGeneralChecks(selectedGeneralChecks.filter((check => check !== selectedCheck)));
                                              if (existsInSuggestedGeneralChecks) {
                                                setSuggestedGeneralChecks([selectedCheck, ...suggestedGeneralChecks]);
                                              }
                                            }}
                                            style={{ color: "white" }}
                                          >
                                            <RemoveIcon
                                              style={{
                                                marginTop: 5,
                                                marginLeft: 10,
                                                backgroundColor: "var(--theme-accent)",
                                                cursor: "pointer",
                                                fontSize: 16,
                                                borderRadius: 2
                                              }}
                                            />
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} spacing={3}>
                        <Grid item xs={9} style={{ paddingBottom: 0 }}>
                          <Typography variant="subtitle1">Vehicle Accessories Checks</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container justifyContent="flex-end" style={{ height: 30 }}>
                            <Typography
                              variant="subtitle2"
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold"
                              }}
                              onClick={() => {
                                setOpenDialog(true);
                                setCheckType("AccessoriesCheck")
                              }}
                            >
                              + Add Custom Accessories Checks
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">Suggested Accessories Checks</Typography>
                          <Box
                            style={{
                              maxHeight: "80vh",
                              overflow: "auto"
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              style={{
                                minHeight: "75vh",
                                marginTop: 10
                              }}
                            >
                              {suggestedAccessoriesChecks.map((suggestedAccessoryCheck: string, idx: number) => {
                                return (
                                  <Grid item xs={12} key={idx}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        margin: "5px",
                                        marginLeft: "16px",
                                        marginRight: "16px"
                                      }}
                                    >
                                      <Grid container style={{ padding: "5px 0" }}>
                                        <Grid item xs={11}>
                                          <p style={{
                                            padding: 0,
                                            margin: 0,
                                            marginLeft: 10,
                                            fontSize: 14,
                                            marginTop: 5
                                          }}>
                                            {suggestedAccessoryCheck}
                                          </p>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <span
                                            onClick={() => {
                                              setSelectedAccessoriesChecks([suggestedAccessoryCheck, ...selectedAccessoriesChecks]);
                                              setSuggestedAccessoresChecks(suggestedAccessoriesChecks.filter(check => check !== suggestedAccessoryCheck));
                                            }}
                                            style={{ color: "white" }}
                                          >
                                            <AddIcon
                                              style={{
                                                marginTop: 5,
                                                marginLeft: 10,
                                                backgroundColor: "var(--theme-accent)",
                                                cursor: "pointer",
                                                fontSize: 16,
                                                borderRadius: 2
                                              }}
                                            />
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">Selected Accessories Checks</Typography>
                          <Box
                            style={{
                              maxHeight: "80vh",
                              overflow: "auto"
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              style={{
                                minHeight: "75vh",
                                marginTop: 10
                              }}
                            >
                              {selectedAccessoriesChecks.map((selectedAccessoryCheck: string, idx: number) => {
                                return (
                                  <Grid item xs={12} key={idx}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        margin: "5px",
                                        marginLeft: "16px",
                                        marginRight: "16px"
                                      }}
                                    >
                                      <Grid container style={{ padding: "5px 0" }}>
                                        <Grid item xs={11}>
                                          <p style={{
                                            padding: 0,
                                            margin: 0,
                                            marginLeft: 10,
                                            fontSize: 14,
                                            marginTop: 5
                                          }}>
                                            {selectedAccessoryCheck}
                                          </p>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <span
                                            onClick={() => {
                                              const existsInSuggestedAccessores = defalutSuggestedAccessoriesChecks.includes(selectedAccessoryCheck);
                                              setSelectedAccessoriesChecks(selectedAccessoriesChecks.filter((check => check !== selectedAccessoryCheck)));
                                              if (existsInSuggestedAccessores) {
                                                setSuggestedAccessoresChecks([selectedAccessoryCheck, ...suggestedAccessoriesChecks]);
                                              }
                                            }}
                                            style={{ color: "white" }}
                                          >
                                            <RemoveIcon
                                              style={{
                                                marginTop: 5,
                                                marginLeft: 10,
                                                backgroundColor: "var(--theme-accent)",
                                                cursor: "pointer",
                                                fontSize: 16,
                                                borderRadius: 2
                                              }}
                                            />
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} justifyContent="flex-start">
                        <Fab
                          variant="extended"
                          size="medium"
                          type="submit"
                          aria-label="add"
                          disabled={createVehicleCheckListLoading}
                        >
                          {createVehicleCheckListLoading && (
                            <CircularProgress
                              size={14}
                              style={{ color: "white", marginRight: "10px" }}
                            />
                          )}
                          SAVE
                        </Fab>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
          <AddCustomCheckDialog
            open={openDialog}
            handleClose={() => { setOpenDialog(false) }}
            handleSubmit={handleAddCustomCheck}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}
