import {
  CircularProgress,
  CssBaseline,
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import _ from "lodash";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { IAppState } from "../../../../../store";
import { useSelector } from "react-redux";
import { Connections, useHotglue } from '@hotglue/widget';
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { UPDATE_TENANCY, UPDATE_TENANCY_INTEGRATION } from "../../../../../graphql/tenancy/updateTenancyMutation";
import { GET_ACCOUNTING_CODE_MAPPING, GET_ORGANISATIONS_FOR_XERO_CODES_MAPPING, GET_ORGANISATIONS_XERO_ACCOUNTS_CODES } from "../../../../../graphql/organisation/getOrganisationQuery";
import { FieldArray, Form, Formik } from "formik";
import { IOrganisation } from "../../../../../reducers/user/types";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CREATE_ACCOUNTING_CODE_MAPPING } from "../../../../../graphql/organisation/createOrganisationMutation";
import { ApolloError } from "@apollo/client";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { UPDATE_ACCOUNTING_CODE_MAPPING } from "../../../../../graphql/organisation/updateOrganisationMutation";
import { UserRoles } from "../../../../hoc/Authorization";
interface IAccountCodeMapping {
  id?: string;
  tenancyId: string;
  organisationId: string;
  accountingName: string;
  codeMapping: ICodeMapping[]
}

interface ICodeMapping {
  line_item: string;
  code: string;
}

interface IAccountCodes {
  accountId: string;
  class: string;
  code: string;
  name: string;
  description: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      width: "100%"
    }
  })
);

const defaultCodeMapping = [
  {
    line_item: "RENTAL_AMOUNT",
    code: ""
  },
  {
    line_item: "DEPOSIT",
    code: ""
  },
  {
    line_item: "INSURANCE",
    code: ""
  },
  {
    line_item: "ADDON",
    code: ""
  },
  {
    line_item: "LOCATION_SURCHARGE",
    code: ""
  },
  {
    line_item: "ONE_WAY_RENTAL_FEE",
    code: ""
  },
  {
    line_item: "OTHER_CHARGE",
    code: ""
  }
]

export const MarketPlace: FC = () => {
  const snackbar = useSnackBar();
  const classes = useStyles();
  const { setListener, link } = useHotglue();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [organisations, setOrganisations] = useState<Partial<IOrganisation>[]>([]);
  const [accountCodes, setAccountCodes] = useState<IAccountCodes[]>([]);
  const [values, setValues] = useState<IAccountCodeMapping>({
    tenancyId: userState.currentTenancy.id,
    organisationId: "",
    accountingName: "XERO",
    codeMapping: defaultCodeMapping
  });

  const line_items = [
    {
      label: "Rental amount",
      value: "RENTAL_AMOUNT"
    },
    {
      label: "Insurance amount",
      value: "INSURANCE"
    },
    {
      label: "Addon amount",
      value: "ADDON"
    },
    {
      label: "Location Surcharge",
      value: "LOCATION_SURCHARGE"
    },
    {
      label: "One Way Fee",
      value: "ONE_WAY_RENTAL_FEE"
    },
    {
      label: "Other Charge",
      value: "OTHER_CHARGE"
    }
  ]

  const [getOrganisations, { loading: organisationLoading, data: organisationsData }] = useLazyQuery(GET_ORGANISATIONS_FOR_XERO_CODES_MAPPING, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [getOrganisationXeroAccountCodes, { loading: loadingAccountCodes, data: accountCodeData }] = useLazyQuery(GET_ORGANISATIONS_XERO_ACCOUNTS_CODES, {
    fetchPolicy: "network-only",
    onCompleted: ({ getXeroAccounts }) => {
      if (!getXeroAccounts) {
        snackbar({
          message: "Selected organisation not connected to XERO",
          variant: SnackBarVariant.ERROR
        })
        setAccountCodes([]);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [getAccountingCodeMapping, { loading: loadingCodeMapping, data: accountCodeMappingData }] = useLazyQuery(GET_ACCOUNTING_CODE_MAPPING, {
    fetchPolicy: "network-only",
    onCompleted: ({ getAccountcodesMapping }) => {
      if (getAccountcodesMapping) {
        setValues({
          ...values,
          id: getAccountcodesMapping.id,
          codeMapping: [...getAccountcodesMapping.codeMapping]
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [createAccountingCodeMapping] = useMutation(CREATE_ACCOUNTING_CODE_MAPPING, {
    onCompleted: ({ createAccountCodeMapping }) => {
      setValues({
        ...createAccountCodeMapping
      });
      snackbar({
        message: "Account code mapping created.",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [updateAccountingCodeMapping] = useMutation(UPDATE_ACCOUNTING_CODE_MAPPING, {
    onCompleted: ({ createAccountCodeMapping }) => {
      snackbar({
        message: "Account code mapping updated.",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [updateTenancyIntegrations] = useMutation(UPDATE_TENANCY_INTEGRATION, {
    onCompleted: ({ updateTenancyIntegration }) => {
      snackbar({
        message: updateTenancyIntegration,
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: () =>
      snackbar({
        message: "Error while updating tenancy",
        variant: SnackBarVariant.ERROR
      })
  });

  useEffect(() => {
    if (userState.currentTenancy.id) {
      getOrganisations()
    }
  }, [userState]);

  useEffect(() => {
    if (organisationsData && organisationsData.organisations) {
      setOrganisations(_.sortBy(organisationsData.organisations, organisation => organisation.name.toLowerCase()))
    }
  }, [organisationsData]);

  useEffect(() => {
    if (accountCodeData && accountCodeData.getXeroAccounts &&
      accountCodeData.getXeroAccounts.accounts &&
      accountCodeData.getXeroAccounts.accounts.length) {
      const _accountCode: IAccountCodes[] = accountCodeData.getXeroAccounts.accounts.map((item: any) => {
        return {
          accountId: item.AccountID,
          class: item.Class,
          code: item.Code,
          name: item.Name,
          description: item.Description
        }
      })
      setAccountCodes(_accountCode);
    }
  }, [accountCodeData])

  setListener({
    // @ts-ignore
    onConnectorLinked: (connector, flowId, tenantId) => {
      if (tenantId) {
        updateTenancyIntegrations({
          variables: {
            tenancyId: userState.currentTenancy.id,
            organisationId: userState.currentOrganisation.id,
            integrationName: connector.id,
            value: true
          }
        })
      }
    },
    // @ts-ignore
    onConnectorUnlinked: (connector, flowId) => {
      if (connector) {
        updateTenancyIntegrations({
          variables: {
            tenancyId: userState.currentTenancy.id,
            organisationId: userState.currentOrganisation.id,
            integrationName: connector,
            value: false
          }
        })
      }
    }
  })

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          &nbsp;Marketplace
        </Typography>
      </Grid>
      {userState.role === UserRoles.SUPER_ADMIN ||
        userState.role === UserRoles.ADMIN ||
        userState.role === UserRoles.ORGANISATION_OWNER ? (
        <>
          <Grid container item xs={12}>
            <Paper className={classes.root}>
              <Grid item container xs={12}>
                <Grid item xs={3}>
                  <Connections
                    tenant={`${userState.currentOrganisation.id}`}
                    multipleConnectors={true}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container item xs={12}>
            <Formik
              enableReinitialize
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                const _foundCodeMapping = values.codeMapping.find((cm) => !cm.code || !cm.line_item);
                if (_foundCodeMapping) {
                  return snackbar({
                    message: "please add correct mapping with each line item",
                    variant: SnackBarVariant.ERROR
                  })
                }
                const { id, ...rest } = values;
                if (values.id) {
                  updateAccountingCodeMapping({
                    variables: {
                      id,
                      accountCodeMapping: {
                        tenancyId: userState.currentTenancy.id,
                        organisationId: rest.organisationId,
                        accountingName: rest.accountingName,
                        codeMapping: rest.codeMapping
                      }
                    }
                  })
                } else {
                  createAccountingCodeMapping({
                    variables: {
                      accountCodeMapping: {
                        ...rest,
                      }
                    }
                  })
                }
                setSubmitting(false);
              }}
            >
              {(props) => (
                <Form noValidate style={{ width: "100%" }}>
                  <Paper className={classes.root}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h3">Xero Account Code Mapping</Typography>
                      </Grid>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              name="organisationId"
                              fullWidth
                              select
                              label="Select Organisation"
                              value={props.values.organisationId}
                              InputProps={{
                                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                  setValues({
                                    ...props.values,
                                    id: "",
                                    organisationId: event.target.value,
                                    codeMapping: defaultCodeMapping
                                  });
                                  getOrganisationXeroAccountCodes({
                                    variables: {
                                      tenancyId: userState.currentTenancy.id,
                                      organisationId: event.target.value
                                    }
                                  });
                                  getAccountingCodeMapping({
                                    variables: {
                                      tenancyId: userState.currentTenancy.id,
                                      organisationId: event.target.value
                                    }
                                  })
                                }
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              required
                            >
                              {organisations && organisations.length > 0 &&
                                organisations.map((item: Partial<IOrganisation>, index: number) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {values.organisationId && (
                        <FieldArray name="codeMapping">
                          {({ remove, push }) => {
                            return (
                              <>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <hr
                                    style={{
                                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                                      color: "rgba(0, 0, 0, 0.1)",
                                      height: 1
                                    }}
                                  />
                                </Grid>
                                {accountCodes.length > 0 && (
                                  <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="body1">
                                      Map your xero account code aganist each invoice line item
                                    </Typography>
                                  </Grid>
                                )}
                                {!loadingAccountCodes && !accountCodes.length ? (
                                  <div>
                                    <p style={{ fontSize: 14, color: "red", marginLeft: 10 }}>Selected organisation not connected to XERO</p>
                                  </div>
                                ) : (
                                  <>
                                    {loadingCodeMapping ? <CircularProgress size={14} /> : (
                                      <>
                                        {
                                          props.values.codeMapping.length > 0 &&
                                          props.values.codeMapping.map((codeMap, index) => {
                                            return (
                                              <>
                                                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <TextField
                                                      name={`codeMapping.${index}.line_item`}
                                                      fullWidth
                                                      select
                                                      label="Select Line item"
                                                      value={codeMap && codeMap.line_item ? codeMap.line_item : ""}
                                                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                        const matchedItem = props.values.codeMapping.find((cm) => cm.line_item === event.target.value);
                                                        if (matchedItem) {
                                                          snackbar({
                                                            message: "line item has been selected already",
                                                            variant: SnackBarVariant.ERROR
                                                          })
                                                        } else {
                                                          const _codeMapping = [...props.values.codeMapping];
                                                          const _updateCodeMapping = [
                                                            ..._codeMapping.slice(0, index),
                                                            {
                                                              ..._codeMapping[index],
                                                              line_item: event.target.value
                                                            },
                                                            ..._codeMapping.slice(index + 1)
                                                          ]
                                                          setValues({
                                                            ...props.values,
                                                            codeMapping: _updateCodeMapping
                                                          });
                                                        }
                                                      }}
                                                      required
                                                    >
                                                      {line_items && line_items.length > 0 &&
                                                        line_items.map((item: any, index: number) => (
                                                          <MenuItem key={index} value={item.value}>
                                                            {item.label}
                                                          </MenuItem>
                                                        ))}
                                                    </TextField>
                                                  </FormControl>
                                                </Grid>
                                                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                                                  <FormControl variant="outlined" fullWidth>
                                                    <TextField
                                                      name={`codeMapping.${index}.code`}
                                                      fullWidth
                                                      select
                                                      label="Select Code"
                                                      required
                                                      value={codeMap && codeMap.code ? codeMap.code : ""}
                                                      onChange={(event: any) => {
                                                        const _codeMapping = [...props.values.codeMapping];
                                                        const _updateCodeMapping = [
                                                          ..._codeMapping.slice(0, index),
                                                          {
                                                            ..._codeMapping[index],
                                                            code: event.target.value
                                                          },
                                                          ..._codeMapping.slice(index + 1)
                                                        ]
                                                        setValues({
                                                          ...props.values,
                                                          codeMapping: _updateCodeMapping
                                                        })
                                                      }}
                                                    >
                                                      {accountCodes && accountCodes.length > 0 &&
                                                        accountCodes.map((item: any, index: number) => (
                                                          <MenuItem key={index} value={item.code}>
                                                            {`${item.name} (${item.code})`}
                                                          </MenuItem>
                                                        ))}
                                                    </TextField>
                                                  </FormControl>
                                                </Grid>
                                                <Grid item container xs={6} sm={6} md={2} lg={2} xl={2}>
                                                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    {props.values.codeMapping.length > 1 && (
                                                      <span
                                                        onClick={() => remove(index)}
                                                        style={{ color: "white" }}>
                                                        <RemoveIcon style={{
                                                          marginLeft: 20,
                                                          fontSize: 18,
                                                          backgroundColor: "var(--theme-primary)",
                                                          cursor: "pointer",
                                                          borderRadius: 2
                                                        }}
                                                        />
                                                      </span>
                                                    )}
                                                    {props.values.codeMapping.length < 6 && index === (props.values.codeMapping.length - 1) && (
                                                      <span
                                                        onClick={() => push({ line_item: "", code: "" })}
                                                        style={{ color: "white" }}>
                                                        <AddIcon style={{
                                                          marginLeft: 20,
                                                          fontSize: 18,
                                                          backgroundColor: "var(--theme-primary)",
                                                          cursor: "pointer",
                                                          borderRadius: 2
                                                        }}
                                                        />
                                                      </span>
                                                    )}
                                                  </div>
                                                </Grid>
                                              </>
                                            )
                                          })
                                        }
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )
                          }}
                        </FieldArray>
                      )}
                      <Grid item container xs={12}>
                        <Fab
                          variant="extended"
                          size="medium"
                          type="submit"
                          disabled={!values.organisationId || !accountCodes.length}
                        >
                          Save
                        </Fab>
                      </Grid>
                    </Grid>
                  </Paper>
                </Form>
              )}
            </Formik>
          </Grid>
        </>
      ) : (
        <Grid container xs={12} md={12} lg={12}>
          <Paper className={classes.root}>
            <Typography variant="body2" style={{ color: "red" }}>
              Access Denied
            </Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}