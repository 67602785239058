import { ApolloError, useMutation } from "@apollo/client";
import { CircularProgress, Fab, Grid, Typography } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useSelector } from "react-redux";

import { CREATE_PAYMENT_FOR_EXTENSiON } from "../../../../../graphql/invoices/createPaymentForExtensionMutation";
import {
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage, PaymentGateway } from "../../../../common/utils";

interface IProps {
  bookingId: string;
  extensionId: string;
  paymentMethodId: string;
  amount: number;
  bookingAutoChargeSetup: boolean;
  bookingAutoChargeMode: PaymentMode;
  handleClose(type?: boolean): void;
}

const AutoChargeForExtension: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { currency } = userState.currentOrganisation;
  const snackbar = useSnackBar();
  const {
    amount,
    bookingId,
    extensionId,
    paymentMethodId,
    bookingAutoChargeMode,
    bookingAutoChargeSetup
  } = props;

  const [createPaymentForExtension, { loading: sessionLoading }] = useMutation(
    CREATE_PAYMENT_FOR_EXTENSiON,
    {
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      },
      onCompleted: async (data) => {
        if (data.createPaymentForExtension) {
          props.handleClose();
        }
      }
    }
  );

  const chargeCard = () => {
    const payment: IPaymentInput = {
      amount,
      booking: bookingId,
      bookingExtension: extensionId,
      stripePaymentMethodId: paymentMethodId,
      currency,
      description: "",
      expireBy: "",
      invoice: "",
      paymentMode: bookingAutoChargeMode,
      paymentType: "INWARD",
      paymentGateway: PaymentGateway.STRIPE,
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=failure`
    };
    createPaymentForExtension({
      variables: {
        payment,
        autoCharge: true
      }
    });
  };

  return (
    <Grid container style={{ padding: 20 }} spacing={2}>
      {!props.bookingAutoChargeSetup ? (
        <Grid item xs={12} spacing={2}>
          <Typography variant="h4">
            This payment mode can only be used with booking that have been
            set-up with Auto Charge feature.
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid container item xs={12} spacing={2}>
            <Grid item>
              <Typography variant="body1">
                Use saved payment method to proceed.
              </Typography>
            </Grid>
            <Grid item container>
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                onClick={chargeCard}
                disabled={sessionLoading}
              >
                {sessionLoading && (
                  <CircularProgress
                    size={14}
                    style={{ color: "white", marginRight: "10px" }}
                  />
                )}
                Pay via Saved Card
              </Fab>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AutoChargeForExtension;
