import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FilePicker } from "../../../../common/FilePicker/FilePicker";
import { CsvUploadStatusType, DATE_TYPE, ICsvUploadError, formatGraphQLErrorMessage } from "../../../../common/utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { v4 as uuidv4 } from "uuid";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { MUIDataTableOptions } from "mui-datatables";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { GET_ADDON_RATE_CARDS } from "../../../../../graphql/addonRateCards/getAddonRateCardsQuery";
import { UPLOAD_ADDON_RATE_CARDS } from "../../../../../graphql/addonRateCards/uploadAddonRateCard";
import { useNavigate } from "react-router-dom";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import * as XLSX from 'xlsx';

interface IFileUpload {
  name: string
  url: string
}

export interface IRateCardAddonInput {
  name: string
  pricing: IRateCardPricing[]
  hasFixedRate: boolean;
  rateTypeName: string
  rateTypeDuration: number
}

export interface IRateCardPricing {
  rangeStart: number
  rangeEnd: number
  unitPrice: number
}

export interface IRateCardAddon extends IRateCardAddonInput {
  addon?: string;
}

export interface IAddonRateCard {
  id: string;
  name: string;
  displayName: string;
  isActive: boolean;
  businessCustomers: string[]
  addons: IRateCardAddon[]
  rateCardType: string;
  isDefault: boolean;
  url: string; // Url of ratecard excel file
  dateCreated: string;
  dateUpdated: string;
  branchId: string;
  organisationId: string;
  tenantId: string;
  lastUpdatedBy: string;
}

const expectedAddonRateCardHeaders = {
  Instructions: [],
  fixed: ['Addon', 'Price'],
  hourly: ['Addon'],
  daily: ['Addon'],
  weekly: ['Addon'],
  monthly: ['Addon']
};

const AddonRateCards: React.FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false)
  const [addonRateCards, setAddonRateCards] = useState<IAddonRateCard[]>([]);
  const [files, setFiles] = useState<IFileUpload[]>([])
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [uploading, setUploading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  const [
    getAddonRateCards,
    { loading: rateCardsLoading, data: rateCardsData }
  ] = useLazyQuery(GET_ADDON_RATE_CARDS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [uploadAddonRateCards, { loading: rateCardUploading }] = useMutation(
    UPLOAD_ADDON_RATE_CARDS,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ uploadAddonRateCards }) => {
        snackbar({
          message: uploadAddonRateCards.message,
          variant: SnackBarVariant.SUCCESS
        })
        setFiles([])
        setUploadModalOpen(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        viewColumns: false
      }
    },
    {
      label: "Rate Card Name",
      name: "displayName"
    },
    {
      label: "Business Customers",
      name: "businessCustomers",
      options: {
        customBodyRender: (data: any) => data.map((item: any) => item.businessName).join(", ")
      }
    },
    {
      label: "Last Updated By",
      name: "lastUpdatedBy",
      options: {
        customBodyRender: (data: any) => data.firstName + " " + data.lastName
      }
    },
    {
      label: "Last Updated At",
      name: "dateUpdated",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED) : "-"
      }
    }
  ];

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (rowData: any, rowMeta: { dataIndex: number, rowIndex: number }) => {
      if (rowData && rowData.length) {
        const row = addonRateCards[rowMeta.dataIndex];
        const rowId = row.id
        if (rowId) {
          navigate(`/update-addon-rate-card?id=${rowId}`)
        }
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: rateCardsLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    }
  }

  useEffect(() => {
    if (userState) {
      getAddonRateCards()
    }
  }, [userState])

  useEffect(() => {
    if (rateCardsData?.addonRateCards) {
      setAddonRateCards(rateCardsData.addonRateCards)
    }
  }, [rateCardsData])

  const verifyAddonRateCards = async (addonRateCardFile: any) => {
    try {
      let file = await addonRateCardFile.arrayBuffer();
      let workbook = XLSX.read(file);
      let bookHeaders: any = {};
      let keys = workbook.SheetNames;
      for (let i = 0; i < keys.length; i++) {
        bookHeaders[keys[i]] = [];
        if (keys[i] === "Instructions") {
          continue;
        }
        bookHeaders[keys[i]].push(workbook.Sheets[keys[i]].A1.w);
        if (keys[i] == "fixed") {
          bookHeaders[keys[i]].push(workbook.Sheets[keys[i]].B1.w);
        }

      }
      bookHeaders = JSON.stringify(bookHeaders);
      if (bookHeaders === JSON.stringify(expectedAddonRateCardHeaders)) {
        return true;
      }
    }
    catch {
      snackbar({
        message: "Selected Addon Rate card is invalid",
        variant: SnackBarVariant.ERROR
      })
    }
    return false
  }

  const handleFileSelect = async (e: any) => {
    setUploading(true);
    const fileList: any[] = []
    const selectedFiles = e.target.files;
    if (selectedFiles?.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (await verifyAddonRateCards(file)) {
          const name = file.name.replace(/((\s\([0-9]\))+)?\.[^\.]+$/, "");
          const uniqueId = uuidv4();
          const key = `${uniqueId}.xlsx`;
          if (_e_) {
            await _e_.add({
              name: key,
              file: file,
              complete: async () => {
                fileList.push({
                  name,
                  url: key
                })
              }
            });
          }
        }
        else {
          snackbar({
            message: "Selected Addon Rate card is invalid",
            variant: SnackBarVariant.ERROR
          })
        }
      }
    }
    setUploading(false)
    setFiles(fileList);
  }

  const uploadFiles = () => {
    if (files.length) {
      uploadAddonRateCards({
        variables: {
          rateCards: files
        }
      })
    } else {
      snackbar({
        message: "Rate Cards not selected",
        variant: SnackBarVariant.ERROR
      })
    }
  }

  return (
    <Grid container>
      <Grid container xs={12}>
        <Grid item xs={4}>
          <Typography variant="h1" color={"primary"}>Addon Rate Cards</Typography>
        </Grid>
        <Grid container xs={8} justifyContent="flex-end">
          <Fab
            variant="extended"
            size="medium"
            aria-label="Upload"
            className="createButton"
            onClick={() => setUploadModalOpen(true)}
          >
            Upload</Fab>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ marginTop: "1rem" }}>
        <NuvvenTable
          title={""}
          rows={addonRateCards}
          columns={columns}
          setSelection={() => ""}
          options={options}
        />
      </Grid>
      {uploadModalOpen ? <Dialog
        fullWidth={true}
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Grid item xs={4}>
              Upload Addon Rate Card(s)
            </Grid>
            <Grid container xs={8} justifyContent="flex-end">
              <Typography variant="body1">
                Download rate card template .xlsx file:
                <a
                  style={{ color: "#000" }}
                  href={"https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Addon_Rate_Card.xlsx"}
                >
                  {" "}
                  {"Addon Rate Card"}{" "}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container xs={12}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Select Addon Rate Cards to Upload
                {uploading && <CircularProgress size={14} style={{ color: "white", marginLeft: "10px" }} />}

              </Typography>
            </Grid>
            <Grid container xs={6}>
              <FilePicker
                onFilePick={(e: any) => {
                  handleFileSelect(e);
                }}
                accept=".xlsx"
                multiple={true}
              />
            </Grid>
          </Grid>
          {files.length > 0 && <Grid container xs={12} style={{ marginTop: "1rem" }}>
            <Typography variant="body1" style={{ marginBottom: "1rem" }}>{`${files.length} Rate Card(s) selected: `}</Typography>
            <Grid item xs={12}>
              {files.map(file => {
                return (<Typography variant="h4">{file.name}</Typography>)
              })}
            </Grid>

          </Grid>}
          <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
            <Grid container item xs={6}>
              {uploadStatus === CsvUploadStatusType.IN_PROGRESS ? (
                <CircularProgress />
              ) : (
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="uploadCsv"
                  onClick={() => uploadFiles()}
                  disabled={rateCardUploading || !files.length || uploading}
                >
                  {rateCardUploading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                  Upload
                </Fab>
              )}
            </Grid>
            {uploadErrors.length > 0 && (
              <Grid item container xs={12}>
                <div>
                  {uploadErrors.map((upldError: ICsvUploadError, idx: number) => {
                    return (
                      <div
                        key={idx}
                      >{`${""} can\'t be uploaded at row ${upldError.index +
                        1} because ${upldError.message}`}</div>
                    );
                  })}
                </div>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog> : <></>}
    </Grid>
  )
}

export default AddonRateCards;