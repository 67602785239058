import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Checkbox,
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Theme,
  Tooltip,
  Typography,
  FormControl,
  MenuItem,
  Paper,
  Box,
  Hidden,
  Button,
  AppBar,
  alpha,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  InputLabel,
  Select
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { MUIDataTableOptions } from "mui-datatables";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { updateVehicle } from "../../../../../../actions/fleet/vehicle/actions";
import { ADD_VEHICLE_NOTE } from "../../../../../../graphql/fleet/addVehicleNoteMutation";
import { DEACTIVATE_VEHICLE } from "../../../../../../graphql/fleet/deactivateVehicle";
import { GET_VEHICLES_EVENTS } from "../../../../../../graphql/fleet/getVehicleEventsQuery";
import { GET_VEHICLE_FEATURES } from "../../../../../../graphql/fleet/getVehicleFeaturesQuery";
import { VERIFY_TELEMETRY_DEVICE } from "../../../../../../graphql/fleet/verifyTelemetryDevice";
import {
  IVehicle,
  IVehicleNote,
  IVehicleService,
  VehicleTypes,
  VehicleServiceStatus
} from "../../../../../../reducers/fleet/types";
import { IAction, IAppState } from "../../../../../../store";
import InputField from "../../../../../common/InputField";
import { NuvvenAutoCompleteTags } from "../../../../../common/NuvvenAutoCompleteTags";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  getVehicleTypeIcon
} from "../../../../../common/utils";
import { integerRegExp } from "../../../../../common/ValidationRules";
import { noteColumns } from "../../../../ReservationManagement/Summary/const";
import { VehicleInfoSource, VehicleStatus } from "../../../utils";
import { frogDiagramTypes } from "./constants";
import { getFrogDiagram, IFrogDiagram } from "../../../../../common/VehicleDamagePreview/frogDiagram";
import { DamageTypes } from "../../../../../../reducers/damageVehicle/types";
import { svgHeight } from "../../../Issues&Damages/NewDamage";
import { ConfirmationDialog } from "../../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../../../utils/getSignedUrl';
import { SelectableRows } from "../../../../../common/NuvvenTable/types";
import { IServiceOptionsMenu, serviceAnniversary } from "../../../ServicesAndRepair/constants";
import FlatPickerBar from "../../../../../common/FlatPicker"
import { DateTime as d } from "luxon"
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import DragDropImages from "../../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../../../utils/sentry";
import { IDateTime } from "../../../../../../reducers/jobSchedular/types";
import { SimpleDateTimePicker } from "../../../../../common/SimpleDateTimePicker";
import _ from "lodash";

interface IDetailsProps {
  vehicle: IVehicle;
  updateFields(payload: any): void;
  updateVehicle(payload: any): void;
  updateVirtualVehicle(payload: any): void;
  showUpgradeErrorOpen: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    },
    active: {
      fontWeight: "bold",
      fontSize: "initial !important"
    },
    content: {
      padding: "30px"
    },
    img: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "200px"
    },
    imgContainer: {
      alignContent: "center",
      display: "flex",
      width: "100%"
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100
    },
    value: {
      fontSize: 16,
      fontWeight: 100
    },
    saveButton: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    saveButtonDisabled: {
      backgroundColor: "#d3d3d3",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    text: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
  })
);

export const Details = (props: IDetailsProps) => {
  const styles = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [values, setValues] = useState<IVehicle>({
    ...props.vehicle,
    vehicleType: props.vehicle.vehicleType || VehicleTypes.DEFAULT_VEHICLE,
  });
  const vehicleLicencePlateRef = useRef<HTMLInputElement>(null);
  const [cursorPosition, setCursorPosition] = React.useState(0);
  const [isImageUploadOpen, setImageUploadOpen] = useState(false);
  const [vehicleImage, setVehicleImage] = useState<any>("");
  const [imageUploadInProgress, setImageUploadInProgress] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [isNewVehicle, setIsNewVehicle] = useState<boolean>(
    props.vehicle.id === "" ? true : false
  );
  const [deactivate, setDeactivate] = useState<boolean>();
  const [isTelemetryDeviceExists, setIsTelemetryDeviceExists] = useState<
    boolean
  >(true);
  const [deviceInputValue, setDeviceInputValue] = useState<string | null>(null);
  const [holdsExist, setHoldsExist] = useState<boolean>();
  const [servicesdExist, setServicesExist] = useState<boolean>();
  const [note, setNote] = useState<IVehicleNote>({
    description: "",
    createdBy: "",
    createdDate: ""
  });
  const [rows, setRows] = useState<IVehicleNote[]>([]);
  const [svg, setSvg] = useState<IFrogDiagram>();
  const [diagramType, setDiagramType] = useState<string>(DamageTypes.EXTERIOR);
  const [oldDiagram, setOldDiagram] = useState<string>("");
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [openActivationDialog, setOpenActivationDialog] = useState<boolean>(false);
  const prevBranchRef = useRef(userState.currentBranch);
  const [startDate, setStartDate] = useState<string>("");
  const [isFourWheeler, setIsFourWheeler] = useState<boolean>(false);
  const [deviceManufecturer, setDeviceManufecturer] = useState<string>("");

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    elevation: 0,
    onRowClick: () => { }
  };

  const { data: vehicleFeatures } = useQuery(GET_VEHICLE_FEATURES, {
    fetchPolicy: "network-only"
  });

  const [
    getVehicleEvents,
    { loading: vehicleSchedulesLoading, data: vehiclesEevents }
  ] = useLazyQuery(GET_VEHICLES_EVENTS, {
    fetchPolicy: "network-only"
  });

  const [deactivateVehicle] = useMutation(DEACTIVATE_VEHICLE, {
    onError: (error: ApolloError) => {
      if (error.message.includes("Upgrade your plan to add more vehicles")) {
        props.showUpgradeErrorOpen(true);
      }
      else {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
      }
    },
    onCompleted: () => {
      if (deactivate) {
        snackbar({
          message: formatGraphQLErrorMessage("Vehicle deactivated"),
          variant: SnackBarVariant.SUCCESS
        });
      } else {
        snackbar({
          message: formatGraphQLErrorMessage("Vehicle activated"),
          variant: SnackBarVariant.SUCCESS
        });
      }
    }
  });

  const [addVehicleNote] = useMutation(ADD_VEHICLE_NOTE, {
    onCompleted: (data: any) => {
      snackbar({
        message: "Note added successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setRows([...rows, { ...data.addVehicleNote }]);
      setNote({
        description: "",
        createdBy: "",
        createdDate: ""
      });
    }
  });

  const [
    verifyTelemetryDevice,
    { data: deviceData, error: deviceError }
  ] = useLazyQuery(VERIFY_TELEMETRY_DEVICE);

  useEffect(() => {
    if (prevBranchRef.current !== userState.currentBranch) {
      navigate("/vehicle-inventory");
      prevBranchRef.current = userState.currentBranch;
    }
  }, [userState.currentBranch]);

  useEffect(() => {
    if (deviceData && deviceData.deviceExist.exists) {
      // enable submit button
      props.updateFields({
        telemetryDeviceId: values.telemetryDeviceId
      });
      if (deviceData.deviceExist.details.manufacturer === "TELTONIKA") {
        setDeviceManufecturer(deviceData.deviceExist.details.manufacturer)
        props.updateFields({
          ...values,
          dtcAlertSupported: false
        });
      } else if (deviceData.deviceExist.details.manufacturer === "CALAMP") {
        props.updateFields({
          ...values,
          dtcAlertSupported: true
        });
      }
    }
  }, [deviceData, deviceError]);

  useEffect(() => {
    setIsNewVehicle(props.vehicle.id === "" ? true : false);
    setOldDiagram((props.vehicle.id && props.vehicle.frogDiagram) ? props.vehicle.frogDiagram : "")
    setValues({ ...props.vehicle });
    if (props.vehicle && props.vehicle.notes) {
      setRows(props.vehicle.notes);
    }
    setDeactivate(
      props.vehicle.status === VehicleStatus.DEACTIVATED ? true : false
    );
    if (props.vehicle.frogDiagram) {
      setSvg(getFrogDiagram(props.vehicle.frogDiagram))
      setOldDiagram(props.vehicle.frogDiagram);
    }
    // FIXME change limit and offset values
    getVehicleEvents({
      variables: {
        startDate: d.now().toUTC().toISO(),
        endDate: d.now()
          .plus({ months: 6 })
          .toUTC().toISO(),
        limit: 1000,
        offset: 0,
        status: props.vehicle.status
      }
    });
  }, [props.vehicle]);

  useEffect(() => {
    if (values.vehicleType) {
      setIsFourWheeler(![VehicleTypes.TWO_WHEELER, VehicleTypes.ATV].includes(values.vehicleType))
    }
  }, [values.vehicleType])

  useEffect(() => {
    if (props.vehicle.imageUrl) {
      getVehicleImage(props.vehicle.imageUrl);
    }
  }, [props.vehicle.imageUrl]);

  useEffect(() => {
    if (vehiclesEevents && vehiclesEevents.vehicleEvents) {
      const currentTime = d.now().toUTC().toISO();
      const activeactVehicle = vehiclesEevents.vehicleEvents.find(
        (vehicle: IVehicle) => {
          return vehicle.id === props.vehicle.id;
        }
      );
      if (activeactVehicle) {
        if (activeactVehicle.holds.length > 0) {
          setHoldsExist(true);
        } else if (activeactVehicle.services.length > 0) {
          activeactVehicle.services.forEach((service: IVehicleService) => {
            if (service.status !== VehicleServiceStatus.COMPLETED && service.endDate >= currentTime) {
              setServicesExist(true);
            }
          });
        }
      }
    }
  }, [vehiclesEevents]);

  useEffect(() => {
    if (props.vehicle.isGhostVehicle && props.vehicle.startDate) {
      setStartDate(props.vehicle.startDate)
    }
  }, [props.vehicle.isGhostVehicle])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const start = event.target.selectionStart;
    if (start) {
      setCursorPosition(start);
    }
    let val: any = event.target.value;
    if (
      event.target.name === "year" ||
      event.target.name === "numberOfDoors" ||
      event.target.name === "numberOfSeats"
    ) {
      val = parseInt(event.target.value);
      if (event.target.value === "" || isNaN(val)) {
        val = 0;
      }
    }
    if (event.target.name === "frogDiagram") {
      setSvg(getFrogDiagram(val))
    }
    setValues({ ...values, [event.target.name]: val });
    props.updateFields({
      [event.target.name]: val
    });
  }

  useEffect(() => {
    if (!vehicleLicencePlateRef.current) return;
    vehicleLicencePlateRef.current.setSelectionRange(cursorPosition, cursorPosition);
  }, [cursorPosition]);

  function onTelemetryDeviceChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.updateFields({
      telemetryDeviceId: event.target.value
    })
    setValues({ ...values, [event.target.name]: event.target.value });
    setDeviceInputValue(event.target.value);
    if (event.target.value) {
      verifyTelemetryDevice({
        variables: { device: event.target.value, vehicleId: values.id }
      });
    }
  }

  const detailsFormSchema = Yup.object().shape({
    licencePlate: Yup.string()
      .min(1)
      .required("Registration number is required."),
    make: Yup.string()
      .min(1)
      .required("Make is required."),
    model: Yup.string()
      .min(1)
      .required("Model is required."),
    year: Yup.string()
      .min(4)
      .max(4)
      .required("Year of manufacture is required.")
      .matches(integerRegExp, "Invalid"),
    transmission: Yup.string()
      .min(1)
      .required("Transmission is required."),
    ...(!props.vehicle.isGhostVehicle
      ? {
        colour: Yup.string()
          .min(1)
          .required("Colour is required."),
        telemetryDeviceId: Yup.string().nullable(),
        vin: Yup.string()
          .min(1)
          .required("Vin is required."),
        assetTag: Yup.string().required("Asset tag is required."),
      }
      : {})
  });

  async function uploadVehicleImage(files: File[]) {
    try {
      if (imageUploadInProgress) {
        return snackbar({
          message: "Image upload is in progress",
          variant: SnackBarVariant.ERROR
        });
      }
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 5MB limit exceeded
        if (file.size > 5242880) {
          throw new Error("File size exceeded limit of 5MB");
        }
        setImageUploadInProgress(true);
        let key: string = "";
        const uniqueId = uuidv4();
        if (file.type === "image/jpeg") {
          key = `${userState.tenancy.id}/${uniqueId}-vehicle.jpeg`;
        } else if (file.type === "image/png") {
          key = `${userState.tenancy.id}/${uniqueId}-vehicle.png`;
        }
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                props.updateFields({
                  imageUrl: key
                });
                setValues({
                  ...values,
                  imageUrl: key
                });
                snackbar({
                  message: "Image Uploaded Successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                getVehicleImage(key);
                setImageUploadOpen(false);
                setImageUploadInProgress(false)
                if (props.vehicle.id) {
                  props.updateVehicle({
                    ...values,
                    imageUrl: key
                  });
                }
              }
            });
        }
      }
    } catch (err: any) {
      setImageUploadInProgress(false);
      snackbar({
        message: formatGraphQLErrorMessage(err?.message),
        variant: SnackBarVariant.ERROR
      });
    }
  }

  const getVehicleImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url);
        setVehicleImage(file);
      } catch (error: any) {
        captureErrorException(error)
        snackbar({
          message: error,
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  const handleFormSubmit = (values: any) => {
    if (!values.cylinderCapacity || !values.fuelType || !values.bodyType) {
      snackbar({
        message: 'Few mandatory specifications are missing',
        variant: SnackBarVariant.ERROR
      });
      return;
    }
    if (values.pcoNumber && !values.pcoExpiry) {
      snackbar({
        message: 'PCO Expiry Date is required when PCO Number is provided',
        variant: SnackBarVariant.ERROR
      });
      return;
    }
    if (values.regularServiceSchedule && !values.regularServiceSchedule.alertBefore) {
      return snackbar({
        message: 'Service alert before parameter is missing.',
        variant: SnackBarVariant.ERROR
      });
    }
    props.updateVehicle(values);

  };
  const handleActivation = () => {
    deactivateVehicle({
      variables: {
        id: values.id,
        deactivate: deactivate
      }
    });
    setOpenActivationDialog(false);
  }


  function setToolTip() {
    const currentTime = d.now().toUTC().toISO();
    if (servicesdExist) {
      return "Vehicle is in service";
    }
    if (holdsExist) {
      return "Vehicle is on hold";
    }
    if (props.vehicle.status === VehicleStatus.OUT_ON_HIRE) {
      return "Vehicle is out on hire";
    }
    if (
      props.vehicle.bookingSchedules &&
      props.vehicle.bookingSchedules.filter((schedule) => schedule.status === 0 && schedule.endDate >= currentTime)
        .length > 0
    ) {
      return "Vehicle has active bookings";
    }
    if (!props.vehicle.canTransfer) {
      return "Vehicle has in transfer process"
    }

    return ''
  }

  return (
    <>
      <Grid className={styles.root}>
        <Formik
          enableReinitialize
          validationSchema={detailsFormSchema}
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values);
            setSubmitting(false);
          }}
        >
          {(formProps) => (
            <React.Fragment>
              <Form>
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid container item xs={4} alignItems="center" sm={undefined}>
                      <Typography variant={"h3"}>
                        VEHICLE DESCRIPTION:&nbsp;
                      </Typography>
                      <Typography variant="body1">
                        {formProps.values.description}
                      </Typography>
                      <Grid container item xs={12} alignItems="center" sm={undefined}>
                        <Typography variant="h3">
                          SOURCE:&nbsp;
                        </Typography>
                        <Typography variant="body1">
                          {formProps.values.infoSource}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} justifyContent="center">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="vehicle-type-select">Vehicle Type</InputLabel>
                        <Select
                          labelId="vehicle-type-label"
                          id="vehicle-type-select"
                          label="Vehicle Type"
                          value={values.vehicleType || VehicleTypes.DEFAULT_VEHICLE}
                          onChange={(event) => {
                            const vehicleType = event.target.value as VehicleTypes
                            setValues({
                              ...values,
                              vehicleType,
                            });
                            props.updateFields({ vehicleType });
                          }}

                        >
                          <MenuItem value={VehicleTypes.TWO_WHEELER}>2 Wheeler</MenuItem>
                          <MenuItem value={VehicleTypes.ATV}>ATV</MenuItem>
                          <MenuItem value={VehicleTypes.DEFAULT_VEHICLE}>4 Wheeler</MenuItem>
                          <MenuItem value={VehicleTypes.TRAILER}>Trailer</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {
                      !isNewVehicle &&
                      (<Grid container item xs={12} sm={4} md={4} lg={4} justifyContent="flex-end">
                        {!vehicleSchedulesLoading ? (
                          <>
                            <Hidden smDown>
                              <Tooltip title={setToolTip()}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={!deactivate}
                                      onChange={(event: any) => {
                                        setDeactivate(!event.target.checked);
                                        setOpenActivationDialog(true);
                                      }}
                                      name="deactivate"
                                      color="primary"
                                      disabled={
                                        holdsExist ||
                                        servicesdExist ||
                                        !props.vehicle.canTransfer
                                      }
                                    />
                                  }
                                  label={deactivate ? "Activate" : "Active"}
                                />
                              </Tooltip>
                              {openActivationDialog && (
                                <ConfirmationDialog
                                  isOpen={openActivationDialog}
                                  onCancel={() => { setOpenActivationDialog(false); setDeactivate(!deactivate); }}
                                  onConfirm={() => {
                                    setOpenActivationDialog(false);
                                    handleActivation();
                                  }}
                                  cancelText="NO"
                                  confirmText="YES"
                                  title=""
                                  description={deactivate ? "Are you sure do you want to deactivate?" : "Are you sure do you want to activate?"}
                                />
                              )}
                            </Hidden>
                            <Hidden mdUp>
                              <Tooltip title={setToolTip()}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={!deactivate}
                                      onChange={(event: any) => {
                                        // deactivate/activate
                                        setDeactivate(!event.target.checked);
                                        setOpenActivationDialog(true);
                                      }}
                                      name="deactivate"
                                      color="primary"
                                      disabled={
                                        holdsExist ||
                                        servicesdExist ||
                                        !props.vehicle.canTransfer
                                      }
                                    />
                                  }
                                  labelPlacement='bottom'
                                  label={deactivate ? "Activate" : "Active"}
                                />
                              </Tooltip>
                            </Hidden>
                          </>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>)
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h2">BASE DETAILS</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container xs={12} spacing={2}>
                      {!props.vehicle.isGhostVehicle &&
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. KIA-023"
                            label="Asset Tag"
                            name={"assetTag"}
                            required
                            InputProps={{
                              onChange: handleChange,
                              value: values.assetTag
                            }}
                            inputProps={{ maxLength: 20 }}
                            fullWidth
                          ></Field>
                        </Grid>
                      }
                      <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="eg. AJ12OFM"
                          label="Registration Number"
                          name={"licencePlate"}
                          inputRef={vehicleLicencePlateRef}
                          InputProps={{
                            onChange: handleChange,
                            value: values.licencePlate.toUpperCase()
                          }}
                          inputProps={{
                            minLength: 7,
                            maxLength: 30
                          }}
                          fullWidth
                          required
                          disabled={
                            props.vehicle.isGhostVehicle ||
                            (props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                              props.vehicle.licencePlate !== "")
                          }
                        ></Field>
                      </Grid>
                      {!props.vehicle.isGhostVehicle &&
                        <><Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <FlatPickerBar
                            enableTime={false}
                            handleDateChange={(value: Date) => {
                              if (value) {
                                setValues({
                                  ...values,
                                  registrationDate: d.fromJSDate(value).toUTC().toISO()
                                })
                                props.updateFields({
                                  registrationDate: d.fromJSDate(value).toUTC().toISO()
                                });
                              }
                            }}
                            label={"Registration Date"}
                            name={"registrationDate"}
                            identifier={"registrationDate"}
                            placeholderValue={"Registration Date"}
                            value={getLocalizedDateFormat(country, values.registrationDate || "", DATE_TYPE.CONDENSED)}
                            maxDate={getLocalizedDateFormat(country, d.now().endOf("day").toUTC().toISO(), DATE_TYPE.CONDENSED)}
                            country={country}
                          />
                        </Grid>
                          <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                            <FlatPickerBar
                              enableTime={false}
                              handleDateChange={(value: Date) => {
                                if (value) {
                                  setValues({
                                    ...values,
                                    v5c: d.fromJSDate(value).toUTC().toISO()
                                  })
                                  props.updateFields({
                                    v5c: d.fromJSDate(value).toUTC().toISO()
                                  });
                                }
                              }}
                              label={"V5C Date"}
                              name={"v5c"}
                              identifier={"v5c"}
                              placeholderValue={"V5C Date"}
                              value={getLocalizedDateFormat(country, values.v5c || "", DATE_TYPE.CONDENSED)}
                              country={country}
                            />
                          </Grid>
                        </>
                      }
                      <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="eg. KIA"
                          label="Make"
                          name={"make"}
                          fullWidth
                          InputProps={{
                            onChange: handleChange,
                            value: values.make
                          }}
                          inputProps={{
                            maxLength: 30
                          }}
                          disabled={
                            props.vehicle.isGhostVehicle ||
                            (props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                              props.vehicle.make !== "")
                          }
                          required
                        ></Field>
                      </Grid>
                      <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="eg. CEED"
                          label="Model"
                          name={"model"}
                          fullWidth
                          InputProps={{
                            onChange: handleChange,
                            value: values.model
                          }}
                          inputProps={{
                            maxLength: 30
                          }}
                          disabled={
                            props.vehicle.isGhostVehicle ||
                            (props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                              props.vehicle.model !== "")
                          }
                          required
                        ></Field>
                      </Grid>
                      {!props.vehicle.isGhostVehicle &&
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. RED"
                            label="Colour"
                            name={"colour"}
                            fullWidth
                            InputProps={{
                              onChange: handleChange,
                              value: values.colour
                            }}
                            disabled={
                              props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                              props.vehicle.colour !== ""
                            }
                            inputProps={{ maxLength: 10 }}
                            required
                          ></Field>
                        </Grid>
                      }
                      <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="eg. 2003"
                          label="Year"
                          name={"year"}
                          fullWidth
                          InputProps={{
                            onChange: handleChange,
                            value: values.year ? values.year : ""
                          }}
                          inputProps={{
                            maxLength: 4
                          }}
                          disabled={props.vehicle.isGhostVehicle}
                          required
                        ></Field>
                      </Grid>
                      {!props.vehicle.isGhostVehicle &&
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. 12345678"
                            label="Vin"
                            name={"vin"}
                            fullWidth
                            InputProps={{
                              onChange: handleChange,
                              value: values.vin
                            }}
                            required
                            inputProps={{ maxLength: 20 }}
                          ></Field>
                        </Grid>
                      }
                      {isFourWheeler &&
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. 4"
                            label="Number Of Doors"
                            name={"numberOfDoors"}
                            fullWidth
                            InputProps={{
                              onChange: handleChange,
                              value: values.numberOfDoors
                                ? values.numberOfDoors
                                : ""
                            }}
                            disabled={
                              props.vehicle.isGhostVehicle ||
                              (props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                                props.vehicle.numberOfDoors !== 0)
                            }
                          ></Field>
                        </Grid>}
                      <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="eg. 5"
                          label="Number Of Seats"
                          name={"numberOfSeats"}
                          fullWidth
                          InputProps={{
                            onChange: handleChange,
                            value: values.numberOfSeats
                              ? values.numberOfSeats
                              : ""
                          }}
                          disabled={
                            props.vehicle.isGhostVehicle ||
                            (props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                              props.vehicle.numberOfSeats !== 0)
                          }
                        ></Field>
                      </Grid>
                      <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="eg. MANUAL"
                          label="Transmission Type"
                          name={"transmission"}
                          fullWidth
                          InputProps={{
                            onChange: handleChange,
                            value: values.transmission
                          }}
                          inputProps={{ maxLength: 15 }}
                          disabled={
                            props.vehicle.isGhostVehicle ||
                            (props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                              props.vehicle.transmission !== "")
                          }
                          required
                        ></Field>
                      </Grid>
                      {props.vehicle?.isGhostVehicle &&
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <SimpleDateTimePicker
                            date={startDate}
                            handleChange={(date: IDateTime) => {
                              setStartDate(d.fromISO(date.date).toUTC().toISO())
                            }}
                            required={true}
                            disabled={false}
                            name={"startDate"}
                            dateTitle={"Commence Date"}
                            timeTitle={"Commence Time"}
                            minDate={d.now().toUTC().toISO()}
                          />
                        </Grid>}
                      {!props.vehicle.isGhostVehicle &&
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <InputField
                            label="PCO Number"
                            handleInput={handleChange}
                            name={"pcoNumber"}
                            value={values.pcoNumber}
                          />
                        </Grid>
                      }
                      {!props.vehicle.isGhostVehicle &&
                        <>
                          <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                            <FlatPickerBar
                              enableTime={false}
                              handleDateChange={(value: Date) => {
                                if (!isNaN(value.getTime())) {
                                  setValues({
                                    ...values,
                                    pcoExpiry: d.fromJSDate(value).toISO()
                                  })
                                  props.updateFields({
                                    pcoExpiry: d.fromJSDate(value).toISO()
                                  });
                                } else {
                                  setValues({
                                    ...values,
                                    pcoExpiry: ''
                                  })
                                  props.updateFields({
                                    pcoExpiry: ''
                                  });
                                }
                              }}
                              label={"PCO Expiry Date"}
                              name={"pcoExpiry"}
                              identifier={"pcoExpiry"}
                              placeholderValue={"PCO Expiry Date"}
                              value={getLocalizedDateFormat(country, values.pcoExpiry || "", DATE_TYPE.CONDENSED)}
                              minDate={"today"}
                              country={country}
                              required={values.pcoNumber ? true : false}
                            />
                          </Grid>
                          {props.vehicle.vehicleType === VehicleTypes.TRAILER &&
                            <>
                              <Grid item container xs={12} sm={12} md={4} justifyContent="flex-start">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={props.vehicle.isRefrigerator}
                                      inputProps={{
                                        onChange: (
                                          event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          setValues({
                                            ...values,
                                            isRefrigerator: event.target.checked
                                          })
                                          props.updateFields({
                                            isRefrigerator: event.target.checked
                                          })
                                        }
                                      }}
                                      value={values.isRefrigerator}
                                      color="secondary"
                                      name={"isRefrigerator"}
                                    />
                                  }
                                  label={
                                    <Typography variant="h4">
                                      {"Electric Refrigerator"}
                                    </Typography>
                                  }
                                  labelPlacement="end"
                                />
                              </Grid>
                              {props.vehicle.isRefrigerator &&
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <Field
                                    component={TextField}
                                    placeholder="Refrigerator Hours"
                                    label="Refrigerator Hours"
                                    name={"refrigeratorHours"}
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                      value: values.refrigeratorHours,
                                      onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        const value = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
                                        setValues({
                                          ...values,
                                          refrigeratorHours: value
                                        });
                                        props.updateFields({
                                          refrigeratorHours: value
                                        })
                                      }
                                    }}
                                  ></Field>
                                </Grid>}
                            </>
                          }
                        </>
                      }
                      <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <FlatPickerBar
                            enableTime={false}
                            handleDateChange={(value: Date) => {
                              if (value) {
                                setValues({
                                  ...values,
                                  nextPMIDate: d.fromJSDate(value).toISO()
                                })
                                props.updateFields({
                                  nextPMIDate: d.fromJSDate(value).toISO()
                                });
                              }
                            }}
                            label={"Next PMI Date"}
                            name={"nextPMIDate"}
                            identifier={"nextPMIDate"}
                            placeholderValue={"Next PMI Date"}
                            value={getLocalizedDateFormat(country, values.nextPMIDate || "", DATE_TYPE.CONDENSED)}
                            minDate={"today"}
                            country={country}
                          />
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <FlatPickerBar
                            enableTime={false}
                            handleDateChange={(value: Date) => {
                              if (value) {
                                setValues({
                                  ...values,
                                  nextBRTDate: d.fromJSDate(value).toISO()
                                })
                                props.updateFields({
                                  nextBRTDate: d.fromJSDate(value).toISO()
                                });
                              }
                            }}
                            label={"Next BRT Date"}
                            name={"nextBRTDate"}
                            identifier={"nextBRTDate"}
                            placeholderValue={"Next BRT Date"}
                            value={getLocalizedDateFormat(country, values.nextBRTDate || "", DATE_TYPE.CONDENSED)}
                            minDate={"today"}
                            country={country}
                          />
                        </Grid>
                      {!props.vehicle.isGhostVehicle &&
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. 12345678"
                            label="Telemetry Device Id"
                            name={"telemetryDeviceId"}
                            fullWidth
                            InputProps={
                              {
                                onChange: onTelemetryDeviceChange,
                                value: values.telemetryDeviceId
                              }
                              // TODO : Show Device Id error here
                            }
                            helperText={!isTelemetryDeviceExists ? <span style={{ color: "red", padding: 0, margin: 0 }}>Telemetry device id not found</span> : ""}
                          ></Field>
                        </Grid>
                      }
                      {!props.vehicle.isGhostVehicle && (values.immobiliser || deviceManufecturer === "TELTONIKA") &&
                        <Grid item container xs={12} sm={12} md={4} justifyContent="flex-start">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.immobiliser}
                                inputProps={{
                                  onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setValues({
                                      ...values,
                                      immobiliser: event.target.checked
                                    });
                                    props.updateFields({ immobiliser: event.target.checked })
                                  }
                                }}
                                value={values.immobiliser}
                                color="secondary"
                                name={"immobiliser"}
                              />
                            }
                            label={
                              <Typography variant="h4">
                                {"IMMOBILISATION"}
                              </Typography>
                            }
                            labelPlacement="end"
                          />
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  {props.vehicle.vehicleType && props.vehicle.vehicleType === VehicleTypes.TRAILER && (
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">TRAILER DETAILS</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="Body Code"
                          label="Body Code"
                          name={"bodyCode"}
                          fullWidth
                          value={values.bodyCode}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValues({
                              ...values,
                              bodyCode: e.target.value
                            })
                            props.updateFields({
                              bodyCode: e.target.value
                            });
                          }}
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="Axles"
                          label="Axles"
                          name={"axles"}
                          fullWidth
                          type="number"
                          InputProps={{
                            value: values.axles,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
                              setValues({
                                ...values,
                                axles: value
                              });
                              props.updateFields({
                                axles: value
                              })
                            }
                          }}
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="Length"
                          label="Length"
                          name={"length"}
                          fullWidth
                          type="number"
                          InputProps={{
                            value: values.length,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
                              setValues({
                                ...values,
                                length: value
                              });
                              props.updateFields({
                                length: value
                              })
                            }
                          }}
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="Height"
                          label="Height"
                          name={"height"}
                          fullWidth
                          value={values.height}
                          type="number"
                          InputProps={{
                            value: values.height,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
                              setValues({
                                ...values,
                                height: value
                              });
                              props.updateFields({
                                height: value
                              })
                            }
                          }}
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="Pallet Capacity"
                          label="Pallet Capacity"
                          name={"palletCapacity"}
                          fullWidth
                          type="number"
                          InputProps={{
                            value: values.palletCapacity,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
                              setValues({
                                ...values,
                                palletCapacity: value
                              });
                              props.updateFields({
                                palletCapacity: value
                              })
                            }
                          }}
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="PMI Cycle"
                          label="PMI Cycle"
                          name={"pmiCycle"}
                          fullWidth
                          type="number"
                          InputProps={{
                            value: values.pmiCycle,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
                              setValues({
                                ...values,
                                pmiCycle: value
                              });
                              props.updateFields({
                                pmiCycle: value
                              })
                            }
                          }}
                        ></Field>
                      </Grid>
                    </Grid>
                  )}
                  {!props.vehicle.isGhostVehicle && (<><Grid item xs={12}>
                    <Typography variant="h2">
                      De-Fleet Parameters
                      <IconButton style={{
                        color: "black",
                        padding: 0,
                        marginLeft: 6
                      }}>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <p>Capturing vehicle de-fleet parameters is crucial for vehicle rental operators. These parameters include the vehicle's age, mileage, maintenance history, and overall condition when it returned. It helps in maintenance, repair, and predicting when repairs are needed. It also impacts resale value, improves customer satisfaction, and reduces costs by making informed decisions about when to retire or replace vehicles.</p>
                            </React.Fragment>
                          }
                          arrow
                          placement={"right"}
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </IconButton>
                    </Typography>

                  </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="eg. 4"
                        label={`By Distance Driven (In ${userState.currentBranch.distanceUnit})`}
                        name={"deFleetParameters.byDistanceDriven"}
                        fullWidth
                        type={"number"}
                        InputProps={{ inputProps: { min: 0 } }}
                        required
                        value={values.deFleetParameters && values.deFleetParameters.byDistanceDriven ? values.deFleetParameters.byDistanceDriven : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            deFleetParameters: {
                              ...values.deFleetParameters,
                              byDistanceDriven: parseInt(e.target.value)
                            }
                          })
                          props.updateFields({
                            deFleetParameters: {
                              ...values.deFleetParameters,
                              byDistanceDriven: parseInt(e.target.value)
                            }
                          });
                        }}
                      ></Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...values,
                            deFleetParameters: {
                              ...values.deFleetParameters,
                              byDate: d.fromJSDate(value).toUTC().toISO()
                            }
                          })
                          props.updateFields({
                            deFleetParameters: {
                              ...values.deFleetParameters,
                              byDate: d.fromJSDate(value).toUTC().toISO()
                            }
                          })
                        }}
                        required={true}
                        label={"By Date"}
                        identifier={`startDate`}
                        placeholderValue={"select date..."}
                        value={values.deFleetParameters && values.deFleetParameters.byDate ?
                          getLocalizedDateFormat(country, values.deFleetParameters.byDate, DATE_TYPE.CONDENSED) : ""}
                        minDate={"today"}
                        country={country}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="eg. 4"
                        label={`Defleet Alert By Distance(In ${userState.currentBranch.distanceUnit})`}
                        name={"deFleetParameters.alertBeforeDistance"}
                        fullWidth
                        type={"number"}
                        InputProps={{ inputProps: { min: 0 } }}
                        required
                        value={values.deFleetParameters && values.deFleetParameters.alertBeforeDistance ? values.deFleetParameters.alertBeforeDistance : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            deFleetParameters: {
                              ...values.deFleetParameters,
                              alertBeforeDistance: parseInt(e.target.value)
                            }
                          })
                          props.updateFields({
                            deFleetParameters: {
                              ...values.deFleetParameters,
                              alertBeforeDistance: parseInt(e.target.value)
                            }
                          });
                        }}
                      ></Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="eg. 4"
                        label={`Defleet Alert By Day(s)`}
                        name={"deFleetParameters.alertBeforeDays"}
                        fullWidth
                        type={"number"}
                        InputProps={{ inputProps: { min: 0 } }}
                        required
                        value={values.deFleetParameters && values.deFleetParameters.alertBeforeDays ? values.deFleetParameters.alertBeforeDays : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            deFleetParameters: {
                              ...values.deFleetParameters,
                              alertBeforeDays: parseInt(e.target.value)
                            }
                          })
                          props.updateFields({
                            deFleetParameters: {
                              ...values.deFleetParameters,
                              alertBeforeDays: parseInt(e.target.value)
                            }
                          });
                        }}
                      ></Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h2">
                        Regular Service Schedule
                        <IconButton style={{
                          color: "black",
                          padding: 0,
                          marginLeft: 6
                        }}>
                          <Tooltip
                            title={
                              <React.Fragment>
                                <p>Capturing regular service schedule is critical as it provides the rental operator an opportunity to create automatic recurring services for the vehicle based on OEM recommendations. This data is required to prepare reports related to vehicle servicing costs and others. Once added, the rental operator will be able to update the details later at any time.</p>
                              </React.Fragment>
                            }
                            arrow
                            placement={"right"}
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </IconButton>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="eg. 4"
                        label={`By Distance Driven (In ${userState.currentBranch.distanceUnit})`}
                        name={"regularServiceSchedule.byDistance"}
                        fullWidth
                        required
                        value={values.regularServiceSchedule && values.regularServiceSchedule.byDistance ? values.regularServiceSchedule.byDistance : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            regularServiceSchedule: {
                              ...values.regularServiceSchedule,
                              byDistance: parseInt(e.target.value)
                            }
                          })
                          props.updateFields({
                            regularServiceSchedule: {
                              ...values.regularServiceSchedule,
                              byDistance: parseInt(e.target.value)
                            }
                          })
                        }}
                      >
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name={"byMonth"}
                          fullWidth
                          type="text"
                          select
                          required
                          label="By Duration"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={values.regularServiceSchedule && values.regularServiceSchedule.byMonth ? values.regularServiceSchedule.byMonth : ""}
                          InputProps={{
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setValues({
                                ...values,
                                regularServiceSchedule: {
                                  ...values.regularServiceSchedule,
                                  byMonth: parseInt(event.target.value)
                                }
                              });
                              props.updateFields({
                                regularServiceSchedule: {
                                  ...values.regularServiceSchedule,
                                  byMonth: parseInt(event.target.value)
                                }
                              });
                            },
                          }}
                        >
                          {serviceAnniversary.map(
                            (
                              item: IServiceOptionsMenu,
                              index: number
                            ) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            }
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="eg. 4"
                        label={`Service Alert Before(Mileage in ${userState.currentBranch.distanceUnit})`}
                        name={"regularServiceSchedule.alertBefore"}
                        fullWidth
                        required
                        value={values.regularServiceSchedule && values.regularServiceSchedule.alertBefore ? values.regularServiceSchedule.alertBefore : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            regularServiceSchedule: {
                              ...values.regularServiceSchedule,
                              alertBefore: parseInt(e.target.value)
                            }
                          })
                          props.updateFields({
                            regularServiceSchedule: {
                              ...values.regularServiceSchedule,
                              alertBefore: parseInt(e.target.value)
                            }
                          })
                        }}
                      >
                      </Field>
                    </Grid>
                  </>)}
                  {!props.vehicle.isGhostVehicle && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h2">ADDITIONAL DETAILS</Typography>
                      </Grid>

                      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.premium}
                                    inputProps={{
                                      onChange: (
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setValues({
                                          ...values,
                                          premium: event.target.checked
                                        });
                                      }
                                    }}
                                    value={values.premium}
                                    color="secondary"
                                    name={"premium"}
                                  />
                                }
                                label={
                                  <Typography variant="h4">
                                    {"Premium Vehicle"}
                                  </Typography>
                                }
                              />
                              {(userState?.currentOrganisation?.allowCarSharing && props?.vehicle?.smartCarVehicleID) && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.isCarShare}
                                      inputProps={{
                                        onChange: (
                                          event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          setValues({
                                            ...values,
                                            isCarShare: event.target.checked
                                          });
                                        }
                                      }}
                                      value={values.isCarShare}
                                      color="secondary"
                                      name={"isCarShare"}
                                    />
                                  }
                                  label={
                                    <Typography variant="h4">
                                      {"Enable Car Sharing"}
                                    </Typography>
                                  }
                                />
                              )}

                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <Box mt={1}></Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h2">MISCELLANEOUS FEATURES</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box mt={1.5}></Box>
                          </Grid>
                          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <NuvvenAutoCompleteTags
                              label="description"
                              handleTags={(tags: string[]) => {
                                setValues({
                                  ...formProps.values,
                                  features: tags
                                });
                                props.updateFields({
                                  features: tags
                                });
                              }}
                              customTags={true}
                              values={formProps.values.features}
                              features={
                                vehicleFeatures && vehicleFeatures.vehicleFeatures
                                  ? vehicleFeatures.vehicleFeatures
                                  : []
                              }
                              columnLarge={12}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Box mt={1.5}></Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h2">UPLOAD VEHICLE IMAGE</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box mt={1.5}></Box>
                          </Grid>
                          <Grid item xs={12} container>
                            <Grid item xs={3}>
                              <Fab
                                className={
                                  `${styles.saveButton} imageUploadButton`  
                                  // TODO : Show save button only when props.vehicle and values are different
                                }
                                variant="extended"
                                size="medium"
                                aria-label="Upload Vehicle Image"
                                type="button"
                                onClick={() => setImageUploadOpen(true)}
                              >
                                Upload Image
                              </Fab>
                            </Grid>
                            {vehicleImage ? (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={9}
                                lg={9}
                                xl={9}
                              >
                                <img
                                  src={vehicleImage}
                                  alt="Vehicle-img"
                                  style={{ width: "85%", height: "auto", borderRadius: 4 }}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                <img
                                  className={styles.img}
                                  src={getVehicleTypeIcon(formProps.values.bodyType)}
                                  alt="vehicle body type"
                                />
                              </Grid>
                            )}
                          </Grid>

                          {isImageUploadOpen && (
                            <DragDropImages
                              open={isImageUploadOpen}
                              handleCloseDragDrop={() => setImageUploadOpen(!isImageUploadOpen)}
                              handleSave={uploadVehicleImage}
                              filesLimit={1}
                            />
                          )}
                        </Grid>

                        <Grid container item xs={12} sm={4} md={4} lg={4} xl={4}>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <Field
                                component={TextField}
                                name={"frogDiagram"}
                                fullWidth
                                type="text"
                                select
                                required
                                label="Frog Diagram"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  onChange: handleChange,
                                  value: formProps.values.frogDiagram
                                }}
                              >
                                {frogDiagramTypes.map((item: any, index: number) => {
                                  return (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            {
                              formProps.values.frogDiagram && (
                                <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
                                  {diagramType === DamageTypes.EXTERIOR && svg?.exterior.svg &&
                                    (<svg xmlns="http://www.w3.org/2000/svg"
                                      style={{ maxWidth: 'calc(100vw - 160px)', maxHeight: `${svgHeight}px`, marginBottom: "20px" }}
                                      viewBox={`0 0 ${svg?.exterior.width} ${svg?.exterior.height}`}>
                                      {svg?.exterior.svg}
                                    </svg>)}
                                  {diagramType === DamageTypes.INTERIOR && svg?.interior.svg &&
                                    (<svg xmlns="http://www.w3.org/2000/svg"
                                      style={{ maxWidth: 'calc(100vw - 160px)', maxHeight: `${svgHeight}px`, marginBottom: "20px" }}
                                      viewBox={`0 0 ${svg?.interior.width} ${svg?.interior.height}`}>
                                      {svg?.interior.svg}
                                    </svg>)}
                                  <Typography variant="h5" ></Typography>
                                  <Typography variant="h5" className={diagramType === DamageTypes.EXTERIOR ? styles.active : ""} style={{ cursor: "pointer", display: "inline", marginRight: "10px", textTransform: "uppercase" }} onClick={() => { setDiagramType(DamageTypes.EXTERIOR) }}>{"exterior"}</Typography>
                                  {isFourWheeler && <Typography variant="h5" className={diagramType === DamageTypes.INTERIOR ? styles.active : ""} style={{ cursor: "pointer", display: "inline", textTransform: "uppercase" }} onClick={() => { setDiagramType(DamageTypes.INTERIOR) }}>{"interior"}</Typography>}
                                </Paper>
                              )
                            }
                          </Grid>
                        </Grid>
                      </Grid>

                      <Hidden smDown>
                        <Grid
                          item
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ marginTop: "20px" }}
                        >
                          <Grid item container xs={12}>
                            <Typography variant="h3" gutterBottom>
                              NOTES
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              placeholder="Note"
                              label="Note"
                              name={"note"}
                              fullWidth
                              multiline
                              row={3}
                              InputProps={{
                                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                  setNote({
                                    ...note,
                                    description: event.target.value,
                                    createdBy:
                                      userState && userState.id ? userState.id : "",
                                    createdDate: new Date().toISOString()
                                  });
                                },
                                value: note.description
                              }}
                              inputProps={{ maxLength: 300 }}
                              disabled={false}
                            ></Field>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Fab
                            variant="extended"
                            size="medium"
                            onClick={() => {
                              if (values.id) {
                                addVehicleNote({
                                  variables: {
                                    vehicleId: values.id,
                                    note
                                  }
                                });
                              } else {
                                setValues({
                                  ...values,
                                  notes: (values.notes ? [...values.notes, { ...note }] : [{ ...note }])
                                });
                                props.updateFields({
                                  notes: (values.notes ? [...values.notes, { ...note }] : [{ ...note }])
                                });
                                setRows((values.notes ? [...values.notes, { ...note }] : [{ ...note }]));
                              }
                            }}
                            disabled={!note?.description}
                          >
                            Add
                          </Fab>
                        </Grid>
                        <Grid container item xs={12}>
                          <Paper variant="outlined" style={{ flexGrow: 1, padding: 20 }} >
                            <NuvvenTable
                              title=""
                              rows={rows}
                              columns={noteColumns(country)}
                              options={options}
                              setSelection={() => { }}
                            />
                          </Paper>
                        </Grid>
                      </Hidden>

                      <Hidden smUp>
                        <Grid item xs={12} >
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography variant="h3">NOTES</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container xs={12} spacing={1}>
                                <Grid item xs={12} style={{ marginTop: 10, fontSize: "14px", fontFamily: "sans-serif" }}>
                                  {rows && rows.length > 0 && rows.map((note: IVehicleNote) => (
                                    <div style={{ marginTop: 16 }}>
                                      <p style={{ margin: 0 }}>
                                        <span style={{ fontSize: 12, marginLeft: 4 }}>{getLocalizedDateFormat(country, note.createdDate, DATE_TYPE.EXPANDED)}</span>
                                      </p>
                                      <span style={{ margin: 0, marginBottom: 5 }}>{note.description}</span>
                                    </div>
                                  ))}
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    component={TextField}
                                    placeholder="Note"
                                    label="Note"
                                    name={"note"}
                                    fullWidth
                                    multiline
                                    row={3}
                                    InputProps={{
                                      onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                        setNote({
                                          ...note,
                                          description: event.target.value,
                                          createdBy:
                                            userState && userState.id ? userState.id : "",
                                          createdDate: new Date().toISOString()
                                        });
                                      },
                                      value: note.description
                                    }}
                                    inputProps={{ maxLength: 300 }}
                                    disabled={false}
                                  ></Field>
                                </Grid>
                                <Grid item xs={12}>
                                  <Fab
                                    variant="extended"
                                    size="medium"
                                    onClick={() => {
                                      if (values.id) {
                                        addVehicleNote({
                                          variables: {
                                            vehicleId: values.id,
                                            note
                                          }
                                        });
                                      } else {
                                        setValues({
                                          ...values,
                                          notes: (values.notes ? [...values.notes, { ...note }] : [{ ...note }])
                                        });
                                        props.updateFields({
                                          notes: (values.notes ? [...values.notes, { ...note }] : [{ ...note }])
                                        });
                                        setRows((values.notes ? [...values.notes, { ...note }] : [{ ...note }]));
                                      }
                                    }}
                                    disabled={!note?.description}
                                  >
                                    Add
                                  </Fab>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Hidden>

                      {openConfirmDialog && (
                        <ConfirmationDialog
                          isOpen={openConfirmDialog}
                          onCancel={() => setOpenConfirmDialog(false)}
                          onConfirm={() => {
                            setOpenConfirmDialog(false);
                            formProps.submitForm();
                          }}
                          confirmText="YES"
                          cancelText="NO"
                          title=""
                          description={"Are you sure you want to change the frog diagram for this vehicle? Please note any existing damages on the vehicle will be copied over to the new frog diagram and might need to be modified if you select yes."}
                        />
                      )}
                    </>)}
                  {!isNewVehicle && !props.vehicle.isGhostVehicle ? (
                    <Hidden xsDown>
                      <Grid item container xs={12} spacing={1}>
                        <Fab
                          className={
                             styles.saveButton
                            // TODO : Show save button only when props.vehicle and values are different
                          }
                          variant="extended"
                          size="medium"
                          aria-label="add"
                          type="button"
                          onClick={() => {
                            if (values.id && values.frogDiagram !== oldDiagram) {
                              setOpenConfirmDialog(true);
                            } else {
                              formProps.submitForm();
                            }
                          }}
                        >
                          Save
                        </Fab>
                      </Grid>
                    </Hidden>
                  ) : (
                    <br />
                  )}
                  {!isNewVehicle && props.vehicle.isGhostVehicle &&
                    <Grid item container xs={12} spacing={1}>
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="button"
                        onClick={() => {
                          if (!startDate) {
                            return snackbar({
                              message: 'Commence date is required.',
                              variant: SnackBarVariant.ERROR
                            });
                          }
                          props.updateVirtualVehicle(startDate)
                        }}
                      >
                        Save
                      </Fab>
                    </Grid>
                  }

                </Grid>
              </Form>
            </React.Fragment>
          )
          }
        </Formik >
      </Grid >
      {!isNewVehicle &&
        (
          <Hidden smUp>
            <AppBar position="fixed" className={styles.appBar}>
              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <Button
                    className={styles.bottomButton}
                    variant="contained"
                    aria-label="Add"
                    type="button"
                    onClick={() => {
                      if (values.id && values.frogDiagram !== oldDiagram) {
                        setOpenConfirmDialog(true);
                      } else {
                        handleFormSubmit(values);
                      }
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </AppBar>
          </Hidden>
        )
      }


    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<IAction>) => {
  return {
    handleSubmitForm: (payload: IVehicle) => {
      dispatch(updateVehicle(payload));
    }
  };
};

export default connect(null, mapDispatchToProps)(Details);