import { gql } from "@apollo/client";

export const CREATE_PAYMENT_FOR_EXTENSiON = gql`
  mutation createPaymentForExtension($payment: CreatePaymentInput!, $autoCharge: Boolean) {
    createPaymentForExtension(payment: $payment, autoCharge: $autoCharge) {
      success
      message
      payment {
        id
        stripeCheckoutSessionId
        convergePaymentSessionUrl
        convergePaymentLinkUrl
        bookingReference
      }
    }
  }
`;