import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  Theme,
  Typography,
  TextField as InputField,
  FormControlLabel,
  Switch,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Fab,
  Autocomplete
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { IRateCard, IRateCardVehicleGroup } from "./RateCards";
import { GET_RATE_CARD_BY_ID } from "../../../../../graphql/rateCards/getRateCardQuery";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { IBusinessCustomer } from "../../../../../reducers/customer/types";
import { GET_BUSINESS_CUSTOMER_PRICE } from "../../../../../graphql/priceRules/getBusinessCustomersQuery";
import { Field, Form, Formik } from "formik";
import { DEACTIVATE_RATE_CARD } from "../../../../../graphql/rateCards/deactivateRateCardMutation";
import { UPDATE_RATE_CARD } from "../../../../../graphql/rateCards/updateRateCardMutation";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { TextField } from "formik-mui";

const useStyles = makeStyles((theme: Theme) =>
({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1
  },
  ruleCardMain: {
    position: "relative"
  },
  addButton: {
    position: "fixed",
    [theme.breakpoints.up('md')]: {
      right: "6rem",
      bottom: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      right: "2rem",
      bottom: '10%',
    },
  },
  iconStyle: {
    padding: 2
  },
  tableContainer: {
    maxHeight: 300,
    border: "solid 1px rgba(224, 224, 224, 1)"
  },
  avatarStyle: {
    background: "var(--theme-accent)",
    height: 25,
    width: 25
  },
  ruleAvatar: {
    background: "var(--theme-accent)",
    height: 25,
    width: 25
  },
  expansionPanel: {
    marginTop: 8,
    width: `calc(100vw - 105px)`,
  },
})
);

const RateCard: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [values, setValues] = useState<IRateCard>();
  const [businessCustomers, setBusinessCustomers] = useState<IBusinessCustomer[]>([]);
  const [selectedBusinessCustomers, setSelectedBusinessCustomers] = useState<IBusinessCustomer[]>([])
  const [hourlyRates, setHourlyRates] = useState<IRateCardVehicleGroup[]>([])
  const [dailyRates, setDailyRates] = useState<IRateCardVehicleGroup[]>([])
  const [weeklyRates, setWeeklyRates] = useState<IRateCardVehicleGroup[]>([])
  const [monthlyRates, setMonthlyRates] = useState<IRateCardVehicleGroup[]>([])
  const userState = useSelector((state: IAppState) => state.userReducer);
  const prevBranchRef = useRef(userState.currentBranch);

  const [
    loadRateCard,
    { loading: rateCardLoading, data: rateCardData }
  ] = useLazyQuery(GET_RATE_CARD_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.getRateCardById) {
        navigate("/rate-cards");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [loadBusinessCustomers, { loading: businessCustomersLoading, data: businessCustomersData }
  ] = useLazyQuery(
    GET_BUSINESS_CUSTOMER_PRICE, {
    fetchPolicy: "network-only"
  })

  const [deactivateRateCard, { loading: deactivateLoading, data: deactivateData }] = useMutation(DEACTIVATE_RATE_CARD, {
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      snackbar({
        message: `Rate Card ${data.deactivateRateCard.isActive ? "activated" : "deactivated"} successfully!`,
        variant: SnackBarVariant.SUCCESS
      })
    }
  })

  const [updateRateCard, { loading: updateRateCardLoading, data: updateRateCardData }] = useMutation(UPDATE_RATE_CARD, {
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      snackbar({
        message: `Rate Card updated successfully!`,
        variant: SnackBarVariant.SUCCESS
      })
    }
  })

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const rateCardId = params.get("id");
      if (rateCardId) {
        loadRateCard({
          variables: {
            id: rateCardId
          }
        })
        loadBusinessCustomers();
      }
    }
  }, [location]);

  useEffect(() => {
    if (rateCardData && rateCardData.getRateCardById) {
      setValues(rateCardData.getRateCardById);
      setSelectedBusinessCustomers(rateCardData.getRateCardById.businessCustomers)
      // reshapeRateCardsData(rateCardData.getRateCardById)
    }
  }, [rateCardData]);

  useEffect(() => {
    if (updateRateCardData && updateRateCardData.updateRateCard) {
      setValues(updateRateCardData.updateRateCard);
      setSelectedBusinessCustomers(updateRateCardData.updateRateCard.businessCustomers)
      // reshapeRateCardsData(updateRateCardData.updateRateCard)
    }
  }, [updateRateCardData]);

  useEffect(() => {
    if (deactivateData) {
      setValues(deactivateData.deactivateRateCard)
    }
  }, [deactivateData])

  useEffect(() => {
    if (businessCustomersData && businessCustomersData.businessCustomers) {
      setBusinessCustomers(businessCustomersData.businessCustomers)
    }
  }, [businessCustomersData]);

  const reshapeRateCardsData = (rateCard: IRateCard) => {
    const hourly: IRateCardVehicleGroup[] = [];
    const daily: IRateCardVehicleGroup[] = [];
    const weekly: IRateCardVehicleGroup[] = [];
    const monthly: IRateCardVehicleGroup[] = [];
    rateCard.vehicleGroups.forEach((vg) => {
      switch (vg.rateTypeName) {
        case "hourly":
          hourly.push(vg)
          break;
        case "daily":
          daily.push(vg)
          break;
        case "weekly":
          weekly.push(vg)
          break;
        case "monthly":
          monthly.push(vg)
          break;
        default:
          break;
      }
    })
    setHourlyRates(hourly)
    setDailyRates(daily)
    setWeeklyRates(weekly)
    setMonthlyRates(monthly)

  }

  const changeRateCardStatus = (checked: boolean) => {
    deactivateRateCard({
      variables: {
        id: values?.id,
        activate: checked
      }
    })
  }

  const updateRateCardSchema = Yup.object().shape({})

  if (rateCardLoading || !values) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid item container xs={12}>
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h1" color="primary">
            {" "}
            Rate Cards{"  "}
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          <Typography variant="h1" color="primary">
            Update Rate Card
          </Typography>
        </Grid>

      </Grid>
      <Paper className={classes.root}>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={updateRateCardSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            updateRateCard({
              variables: {
                id: values.id,
                businessCustomers: values.businessCustomers.map((item) => {
                  if (typeof item !== "string") {
                    return item.id
                  }
                  return item
                })
              }
            })
          }}
        >
          {(props) => (
            <Form>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6} md={3}>
                  <Field
                    component={TextField}
                    name="name"
                    label="Rule Name"
                    fullWidth
                    required
                    inputProps={{
                      value: values.displayName,
                    }}
                    disabled
                  ></Field>
                </Grid>
                <Grid container xs={6} md={9} justifyContent="flex-end">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isActive ? true : false}
                        onChange={(event: any) => {
                          // deactivate/activate
                          changeRateCardStatus(event.target.checked)
                        }}
                        name="deactivate"
                        color="primary"
                        disabled={deactivateLoading}
                      />
                    }
                    labelPlacement='bottom'
                    label={<>
                      {deactivateLoading && <CircularProgress size={10} />}
                      <Typography style={{ display: "inline", marginLeft: "0.5rem" }}>{!values.isActive ? "Activate" : "Active"}</Typography>
                    </>}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="free-solo-customer-search"
                    disableClearable
                    options={businessCustomers}
                    getOptionLabel={(option: any) =>
                      option.businessName
                    }
                    filterSelectedOptions={true}
                    onChange={(_: any, newValues: any, reason) => {
                      if (reason === "selectOption") {
                        setValues({
                          ...props.values,
                          businessCustomers: [
                            ...props.values.businessCustomers,
                            newValues[newValues.length - 1].id
                          ]
                        })
                      }
                      if (reason === "removeOption") {
                        const updatedCustomers = newValues.map((value: IBusinessCustomer) => value.id)
                        setValues({
                          ...props.values,
                          businessCustomers: updatedCustomers
                        })
                      }
                      setSelectedBusinessCustomers(newValues)
                    }}
                    value={selectedBusinessCustomers}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        label="Select Business Customer(s)"
                        variant="outlined"
                        placeholder={"Select Business Customer(s)"}
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="submit"
                    type="submit"
                    disabled={updateRateCardLoading}
                  >
                    {updateRateCardLoading && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}
                    Save
                  </Fab>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        {/* <Grid container xs={12}>
          {hourlyRates.length > 0 && <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            </Table>}
        </Grid> */}

      </Paper>
    </Grid>
  )
}

export default RateCard;