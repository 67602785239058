
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { CHARGE_CARD_AGAINST_INVOICE } from "../../../graphql/invoices/chargeCardAgainstInvoiceMutation";
import { CREATE_PAYMENT } from "../../../graphql/invoices/createPaymentMutation";
import { IPaymentInput, PaymentMode } from "../../../reducers/invoices/types";
import { IAppState } from "../../../store";
import { CREATE_PAYMENT_MESSAGE } from "../../views/Invoices/UpdateInvoice/messages";
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";
import {
  PaymentGateway,
  formatGraphQLErrorMessage,
  toCurrency
} from "../utils";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { ApolloError, useMutation } from "@apollo/client";
import { Button, Card, CardActionArea, CardContent, CircularProgress, Dialog, FormControlLabel, FormHelperText, Grid, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: "relative",
    minWidth: "600px",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "25px",
    paddingRight: "25px",
    overflowX: "hidden"
  },
  dialog: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  summary: {
    display: "flex",
    paddingTop: "10px",
    paddingBottom: "20px"
  },
  title: {
    fontSize: 12
  },
  pos: {
    marginBottom: 12
  },
  loadingGrid: {
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    margin: theme.spacing(1)
  }
}));

interface IProps {
  open: boolean;
  dueAmount: number;
  invoiceId: string;
  invoiceRef: string;
  bookingId: string;
  setOpen: (open: boolean) => void;
  refreshInvoice: (invoiceId: string) => void;
}

export const AutoChargePaymentOptionsDialog: React.FC<IProps> = ({
  open,
  dueAmount,
  invoiceId,
  bookingId,
  invoiceRef,
  setOpen,
  refreshInvoice
}) => {
  const getAvailablePaymentModes = () => {
    if (cardEnabled) {
      return PaymentMode.CARD;
    } else if (bacsEnabled) {
      return PaymentMode.BACS_BANK_DEBIT;
    } else if (sepaEnabled) {
      return PaymentMode.SEPA_BANK_DEBIT;
    } else {
      return undefined;
    }
  };
  const userState = useSelector((state: IAppState) => state.userReducer);
  const {
    locale,
    currency,
    stripeAccountId,
    sepaEnabled,
    bacsEnabled,
    cardEnabled
  } = userState.currentOrganisation;
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [paymentMode, setPaymentMode] = useState<PaymentMode | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showOptions, setOptionsView] = useState<boolean>(true);
  const [showMethods, setMethodsView] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>(
    "Payment Method for Auto Charge"
  );

  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  const [chargeCardAgainstInvoice] = useMutation(CHARGE_CARD_AGAINST_INVOICE, {
    onCompleted: (data) => {
      if (data?.chargeCardAgainstInvoice) {
        setLoading(false);
        setOpen(false);
        refreshInvoice(invoiceId);
      }
      snackBar({
        message: CREATE_PAYMENT_MESSAGE,
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      setOpen(false);
      snackBar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    onCompleted: async (data) => {
      if (data?.createPayment?.payment?.stripeCheckoutSessionId) {
        // Get Stripe.js instance
        const stripe = await stripePromise;
        // When the customer clicks on the button, redirect them to Checkout.
        if (stripe) {
          setLoading(false);
          const result = await stripe.redirectToCheckout({
            sessionId: data.createPayment?.payment?.stripeCheckoutSessionId
          });
          if (result.error && result.error.message) {
            snackBar({
              message: result.error.message,
              variant: SnackBarVariant.ERROR
            });
          }
        }
      }
      if (data?.createPayment?.payment?.id) {
        // Get Stripe.js instance
        const stripe = await stripePromise;
        // When the customer clicks on the button, redirect them to Checkout.
        if (stripe) {
          navigate(
            `/payment?invoice=${invoiceId}&payment=${data.createPayment.payment.id}`
          );
        }
      }
    },
    onError: (error: ApolloError) =>
      snackBar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const handleDialogClose = () => {
    setLoading(false);
    setPaymentMode(undefined);
    setOptionsView(true);
    setMethodsView(false);
    setOpen(false);
  };

  const handleSavedCardMethod = () => {
    setLoading(true);
    setOptionsView(false);
    setDialogTitle("Processing Payment");
    chargeCardAgainstInvoice({
      variables: {
        invoiceId: invoiceId
      }
    });
  };

  const handleNewCardMethod = () => {
    setPaymentMode(getAvailablePaymentModes());
    setDialogTitle("Choose Payment Mode");
    setOptionsView(false);
    setMethodsView(true);
  };

  const handleRadioChange = (event: any) => {
    setPaymentMode(event.target.value);
  };

  const handleSubmit = (event: any) => {
    setMethodsView(false);
    setLoading(true);
    if (paymentMode) {
      const payment: IPaymentInput = {
        paymentMode: paymentMode,
        paymentType: "INWARD",
        amount: dueAmount,
        currency: currency,
        description: "",
        expireBy: "",
        invoice: invoiceId,
        note: "",
        booking: bookingId,
        paymentGateway: PaymentGateway.STRIPE,
        successUrl: `${window.location.protocol}//${window.location.host}/update-billing?invoice=${invoiceId}&status=success`,
        cancelUrl: `${window.location.protocol}//${window.location.host}/update-billing?invoice=${invoiceId}&status=failure`
      };
      createPayment({
        variables: {
          payment,
          saveDetails: true
        }
      });
    }
    event.preventDefault();
  };

  return (
    <Dialog
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="auto-charge-payment-method-dialog"
    >
      <div className={`${classes.root} mobile`}>
        <div className={classes.dialog}>
          <Typography variant="h2" className="bold">
            {dialogTitle}
          </Typography>

          <IconButton
            onClick={() => handleDialogClose()}
            aria-label="auto-charge-payments-dialog-close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.summary}>
          <Grid container direction="row" spacing={3} alignItems="flex-start">
            <Grid item>
              <Typography variant="h4" style={{ fontWeight: "initial" }}>
                Total payable amount
              </Typography>
              <Typography variant="h2" className="bold text-accent">
                {toCurrency(dueAmount, currency, locale)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {loading && (
          <div className={classes.loadingGrid}>
            <CircularProgress />
          </div>
        )}
        {showMethods && (
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h3" component="h3">
                Select Payment Mode
              </Typography>
            </Grid>
            <Grid item spacing={2}>
              <form onSubmit={handleSubmit}>
                <RadioGroup
                  row
                  aria-label="auto-charge-payment-mode"
                  name="auto-charge-payment-mode"
                  onChange={handleRadioChange}
                  value={paymentMode}
                >
                  {cardEnabled && (
                    <FormControlLabel
                      value={PaymentMode.CARD}
                      control={<Radio />}
                      label="Card"
                    />
                  )}
                  {bacsEnabled && currency === "GBP" && (
                    <FormControlLabel
                      value={PaymentMode.BACS_BANK_DEBIT}
                      control={<Radio />}
                      label="BACS Bank Debit"
                    />
                  )}
                  {sepaEnabled && currency === "EUR" && (
                    <FormControlLabel
                      value={PaymentMode.SEPA_BANK_DEBIT}
                      control={<Radio />}
                      label="SEPA"
                    />
                  )}
                </RadioGroup>
                <FormHelperText>
                  Selected Payment mode will be used for future auto charges.
                </FormHelperText>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={paymentMode === undefined}
                  endIcon={<ArrowForwardIcon style={{ fontSize: 24 }} />}
                >
                  Proceed with Payment
                </Button>
              </form>
            </Grid>
          </Grid>
        )}
        {showOptions && (
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
          >
            <Grid item style={{ width: "100%" }}>
              <Card onClick={handleSavedCardMethod}>
                <CardActionArea>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography variant="h2" component="h2">
                          Charge against Saved Card
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowForwardIcon style={{ fontSize: 24 }} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item style={{ width: "100%" }}>
              <Card onClick={handleNewCardMethod}>
                <CardActionArea>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography variant="h2" component="h2">
                          Charge against New Card
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          New card will be saved for future auto charges.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowForwardIcon style={{ fontSize: 24 }} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </Dialog>
  );
};
