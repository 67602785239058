import { gql } from "@apollo/client";

export const GET_BOOKINGS = gql`
query bookings($limit: Int!, $offset: Int!, $filters: [FilterInput], $bookingIds: [String]) {
    bookings(limit: $limit, offset: $offset, filters: $filters, bookingIds: $bookingIds) {
      id
      referenceNumber
      source
      tba
      createdBy {
        id
        firstName
        lastName
      }
      isSubscription
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      pickupOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      pickupDateTime
      dropoffDateTime
      customerType
      dateUpdated
      status
      customer {
        id
        firstName
        lastName
      }
      currentBookingSchedules {
        id
        startDate
        endDate
        status
        vehicleStatus
        vehicle {
          id
          licencePlate
          branchId
          imageUrl
          bodyType
        }
      }
      businessCustomer {
        id
        businessName
      }
      authorizedSignatory
    }
  }
`;