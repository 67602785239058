import {
  Dialog,
  DialogContent,
  Typography,
  Theme,
  Grid,
  Fab,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { createStyles, withStyles, WithStyles } from "@mui/styles";

interface IDialogProps {
  open: boolean,
  handleClose: () => void;
  handleSubmit: (customCheck: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const AddCustomCheckDialog: React.FC<IDialogProps> = (props) => {
  const { open, handleClose, handleSubmit } = props;
  const [customCheck, setCustomCheck] = useState<string>("");

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "var(--theme-accent)", padding: 0, margin: 0 }}>
            Add Custom Check
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={8} style={{ marginTop: 10 }}>
              <TextField
                name="customCheck"
                label="Custom Check"
                placeholder="Custom Check"
                fullWidth
                required
                inputProps={{
                  value: customCheck,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setCustomCheck(e.target.value)
                  }
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Fab
                variant="extended"
                size="medium"
                aria-label="submit"
                onClick={() => {
                  handleSubmit(customCheck)
                  setCustomCheck("");
                }}
                disabled={!customCheck}
              >
                Add
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}
