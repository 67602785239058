import { gql } from "@apollo/client";

export const CREATE_PAYMENT_FOR_DEPOSIT = gql`
  mutation createPaymentForDeposit($payment: CreateDepositPaymentInput!) {
    createPaymentForDeposit(payment: $payment) {
      success
      message
      payment {
        id
        stripeCheckoutSessionId
        convergePaymentSessionUrl
        convergePaymentLinkId
      }
    }
  }
`;