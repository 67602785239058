import { useMutation } from "@apollo/client";
import {
  Box,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import {  TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CREATE_PAYMENT_FOR_DEPOSIT } from "../../../../../graphql/bookings/createPaymentForDepositMutation";
import { IBooking } from "../../../../../reducers/bookings/types";
import {
  IDepositPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  PaymentGateway,
  formatGraphQLErrorMessage
} from "../../../../common/utils";

interface IProps {
  amount: number;
  booking: IBooking;
  handleClose: () => void;
}

const CardTransactionDeposit: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency, stripeAccountId, cardEnabled, autoChargeEnabled, convergeCredentials } = userState.currentOrganisation;
  const snackbar = useSnackBar();
  const [amount, setAmount] = useState<number>(props.amount);
  const [transactionId, setTransactionId] = useState<string>("");
  const [captureLater, setCaptureLater] = useState<boolean>(false);
  const [paymentGateway, setPaymentGateway] = useState<string>(
    PaymentGateway.OFFLINE
  );

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  const [createPaymentForDeposit, { loading: createDepositPaymentLoading, data: createDepositPaymentData }] = useMutation(
    CREATE_PAYMENT_FOR_DEPOSIT,
    {
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        }),
      onCompleted: async (data) => {
        if (paymentGateway === PaymentGateway.OFFLINE) {
          if (data && data.createPaymentForDeposit) {
            props.handleClose()
          }
        } else if (paymentGateway === PaymentGateway.STRIPE) {
          if (
            data?.createPaymentForDeposit?.payment?.id
          ) {
            // Get Stripe.js instance
            const stripe = await stripePromise;
            // When the customer clicks on the button, redirect them to Checkout.
            if (stripe) {
              navigate(`/payment?booking=${props.booking.id}&payment=${data.createPaymentForDeposit.payment.id}`)
            }
          }
        } else if (paymentGateway === PaymentGateway.CONVERGE) {
          window.location = data.createPaymentForDeposit.payment.convergePaymentSessionUrl;
        }
      }
    });

  useEffect(() => {
    setAmount(props.amount)
  }, [props.amount])

  const submitStripeForm = () => {
    const payment: IDepositPaymentInput = {
      amount: amount,
      booking: props.booking.id,
      currency,
      paymentMode: PaymentMode.CARD,
      paymentGateway,
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.booking.id}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.booking.id}`,
      captureLater,
      saveDetails: autoChargeEnabled && paymentGateway === PaymentGateway.STRIPE && !props.booking.autoChargeLater && !captureLater
    };
    createPaymentForDeposit({
      variables: {
        payment
      }
    })
  };

  const submitOfflineForm = () => {
    const payment: IDepositPaymentInput = {
      amount: amount,
      booking: props.booking.id,
      currency,
      transactionId: transactionId,
      paymentMode: PaymentMode.CARD,
      paymentGateway: PaymentGateway.OFFLINE,
    };
    createPaymentForDeposit({
      variables: {
        payment
      }
    })
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(props.amount / 100)}`;

  const stripeFormSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(0)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  const offlineFormSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(0)
      .max(props.amount, AMOUNT_TOO_HIGH),
    transactionId: Yup.string().required("Reference Number is required.")
  });

  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!cardEnabled ?
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable card payments in Payment and Integration Settings.
          </Typography>
        </Grid> :
        <>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                name="paymentGateway"
                value={paymentGateway}
                onChange={(event) => {
                  setPaymentGateway(event.target.value);
                }}
              >
                <FormControlLabel
                  value={PaymentGateway.OFFLINE}
                  control={<Radio />}
                  label={<Typography variant="body1">OTHER</Typography>}
                />
                <FormControlLabel
                  value={PaymentGateway.STRIPE}
                  control={<Radio />}
                  label={<Typography variant="body1">STRIPE</Typography>}
                />
                <FormControlLabel
                  value={PaymentGateway.CONVERGE}
                  control={<Radio />}
                  label={<Typography variant="body1">CONVERGE</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {paymentGateway === PaymentGateway.OFFLINE && (
            <>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  validationSchema={offlineFormSchema}
                  initialValues={{
                    amount: amount, transactionId: transactionId
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    submitOfflineForm();
                    setSubmitting(false);
                  }}
                >
                  {(formikProps) => (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12} md={5} lg={5} xl={5}>
                          <Field
                            component={TextField}
                            placeholder="Reference Number"
                            label="Reference Number"
                            name={"transactionId"}
                            fullWidth
                            required
                            InputProps={{
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setTransactionId(e.target.value)
                              },
                              value: transactionId
                            }}
                          />
                          <Typography variant={"body2"} color="textSecondary">
                            This could be anything like Receipt Number etc., and is
                            only for internal use.
                          </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12} md={5} lg={5} xl={5}>
                          <Field
                            component={TextField}
                            placeholder="Enter Amount"
                            label="Enter Amount"
                            name={"amount"}
                            disabled
                            fullWidth
                            required
                            InputProps={{
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setAmount(parseInt(e.target.value))
                              },
                              value: amount,
                              inputComponent: FloatInput as any
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-start">
                          <Fab
                            variant="extended"
                            size="medium"
                            aria-label="add"
                            type="submit"
                            disabled={createDepositPaymentLoading}
                          >
                            {createDepositPaymentLoading && (
                              <CircularProgress
                                size={14}
                                style={{ color: "white", marginRight: "10px" }}
                              />
                            )}
                            Save
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </>
          )}
          {paymentGateway === PaymentGateway.STRIPE &&
            (stripeAccountId ? (
              <>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      name="captureLater"
                      value={captureLater}
                      onChange={(event) => {
                        if (typeof event.target.value === "string") {
                          if (event.target.value.toLowerCase() === "true") setCaptureLater(true);
                          if (event.target.value.toLowerCase() === "false") setCaptureLater(false);
                        }
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={
                          <Typography variant="body1">
                            Pay Now
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={
                          <Typography variant="body1">
                            Pre Authorise Card
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item container xs={12}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      amount: amount,
                      transactionId: transactionId
                    }}
                    validationSchema={stripeFormSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      submitStripeForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formikProps) => (
                      <Form>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            placeholder="Enter Amount"
                            label="Enter Amount"
                            name={"amount"}
                            InputProps={{
                              inputComponent: FloatInput as any
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                            disabled
                            fullWidth
                            required
                          />
                        </Grid>
                        {!props.booking.autoChargeLater && (
                          autoChargeEnabled && !captureLater && 
                          <Grid item xs={12} style={{marginTop: "1rem"}}>
                            <Typography>
                              Future charges related to the ongoing booking will be charged to this payment method
                            </Typography> 
                          </Grid>
                          )}
                        <Grid item xs={12}>
                          <Grid item container>
                            <Box mt={2}></Box>
                          </Grid>
                          <Grid item container>
                            <Fab
                              variant="extended"
                              size="medium"
                              aria-label="add"
                              type="submit"
                              disabled={createDepositPaymentLoading}
                            >
                              {createDepositPaymentLoading && (
                                <CircularProgress
                                  size={14}
                                  style={{ color: "white", marginRight: "10px" }}
                                />
                              )}
                              Proceed To Pay
                            </Fab>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </>
            ) : (
              <Grid container xs={12}>
                <Typography style={{ margin: "1rem" }}>
                  Please add Stripe Account Id in organisation settings to use this
                  feature.
                </Typography>
              </Grid>
            ))}
          {paymentGateway === PaymentGateway.CONVERGE &&
            (convergeCredentials ? (
              <>
                <Grid item container xs={12}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      amount: amount,
                      transactionId: transactionId
                    }}
                    validationSchema={stripeFormSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      submitStripeForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formikProps) => (
                      <Form>
                        <Grid item xs={12}>
                          <FormControl>
                            <RadioGroup
                              row
                              name="captureLater"
                              value={captureLater}
                              onChange={(event) => {
                                if (typeof event.target.value === "string") {
                                  if (event.target.value.toLowerCase() === "true") setCaptureLater(true);
                                  if (event.target.value.toLowerCase() === "false") setCaptureLater(false);
                                }
                              }}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={
                                  <Typography variant="body1">
                                    Pay Now
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={
                                  <Typography variant="body1">
                                    Pre Authorise Card
                                  </Typography>
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            placeholder="Enter Amount"
                            label="Enter Amount"
                            name={"amount"}
                            InputProps={{
                              inputComponent: FloatInput as any
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                            disabled
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item container>
                            <Box mt={2}></Box>
                          </Grid>
                          <Grid item container>
                            <Fab
                              variant="extended"
                              size="medium"
                              aria-label="add"
                              type="submit"
                              disabled={createDepositPaymentLoading}
                            >
                              {createDepositPaymentLoading && (
                                <CircularProgress
                                  size={14}
                                  style={{ color: "white", marginRight: "10px" }}
                                />
                              )}
                              Proceed To Pay
                            </Fab>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </>
            ) : (
              <Grid container xs={12}>
                <Typography style={{ margin: "1rem" }}>
                    Please add Converge credentials in Payments & Integrations settings to use this
                    feature.
                </Typography>
              </Grid>
            ))}
        </>}
    </Grid>
  );
};

export default CardTransactionDeposit;