import { Grid, MenuItem, Typography, TextField as InputField, } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import FlatPickerBar from "../FlatPicker";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { DateTime as d } from 'luxon';
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { DATE_TYPE } from "../utils";
interface ITimeValues {
  index: number,
  formatted: string
}
interface IProps {
  handleChange(fromDateTime: string, toDateTime: string): void;
  labels: string[];
  fromDateTime: string;
  toDateTime: string;
  required?: boolean;
  gridSpan: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  gridSpanSm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  gridSpanMd?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  gridSpanLg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  minDateTime?: string;
  maxDateTime?: string;
  disableEndDate?: boolean;
  type?: string;
  toDateChange?(isDateSelected: boolean): void
}

export const DateTimePicker: React.FC<IProps> = (props) => {
  const { handleChange, labels, fromDateTime, toDateTime, gridSpan, gridSpanSm, gridSpanMd, gridSpanLg, minDateTime, maxDateTime, toDateChange } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const timeRange = useRef<{ fromTime: number[], toTime: number[] }>({ fromTime: [0, 23.45], toTime: [0, 23.45] });
  const [manualSelection, setManualSelection] = useState<boolean>(false);
  useEffect(() => {
    if (fromDateTime && toDateTime) {
      setDateTime(d.fromISO(fromDateTime), d.fromISO(toDateTime), true);
    }
  }, []);

  const setTimeOnDate = (date: d, hour: number) => {
    const formattedDate = date.toFormat("dd/MM/yyyy");
    return d.fromFormat(`${formattedDate} ${numberToHour(hour)}`, "dd/MM/yyyy h:m")
  }

  const numberToHour = (opHr: number) => {
    const minute = parseInt((Math.round(opHr * 100)).toString().substr(-2));
    const hour = Math.floor(opHr);
    return `${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`}`
  }

  const hourToNumber = (hour: string) => {
    const splitted = hour.split(":");
    return parseInt(splitted[0]) + (parseInt(splitted[1]) / 100)
  }

  const roundOffToNextQuarter = (time: string) => {
    const nextQuarter = Math.floor(parseInt(time.split(":")[1]) / 15 + 1) * 15;
    const hour = parseInt(time.split(":")[0])
    return nextQuarter < 60 ? Math.floor(hour) + nextQuarter / 100 : Math.floor(hour) + 1
  }

  const getTimesArray = (range: number[]) => {
    let arr = [];
    for (let i = range[0]; i <= range[1]; i) {
      arr.push(numberToHour(i));
      const minute = parseInt((Math.round(i * 100)).toString().substr(-2));
      if (minute >= 45)
        i = Math.floor(i) + 1;
      else
        i += 0.15
    }
    return arr
  }

  const setDateTime = (from: d, to: d, manualSelection: boolean) => {
    const isFromSameAsMin = minDateTime ? from.hasSame(d.fromISO(minDateTime), "day") : false;
    const isToSameAsFrom = to.hasSame(from, "day");
    let fromHour = manualSelection ? hourToNumber(from.toFormat("HH:mm")) : 8;
    let toHour = manualSelection ? hourToNumber(to.toFormat("HH:mm")) : 8;
    let allowedFromHour = 0;
    let allowedToHour = 0;
    if (minDateTime && isFromSameAsMin) {
      allowedFromHour = roundOffToNextQuarter(d.fromISO(minDateTime).toFormat("HH:mm"));
    }
    fromHour = fromHour < allowedFromHour ? allowedFromHour : fromHour;
    if (isToSameAsFrom) {
      allowedToHour = allowedFromHour; //fixme
    }
    timeRange.current = { fromTime: [allowedFromHour, 23.45], toTime: [allowedToHour, 23.45] };
    toHour = toHour < allowedToHour ? allowedToHour : toHour;
    const _fromDateTime = setTimeOnDate(from, fromHour).toUTC().toISO();
    const _toDateTime = setTimeOnDate(to, toHour).toUTC().toISO();
    handleChange(_fromDateTime, _toDateTime);
    setManualSelection(true);
  }

  const handleFromDateChange = (value: Date) => {
    const selected = d.fromJSDate(value)
    if (selected.isValid) {
      let _fromDateTime = fromDateTime ? setTimeOnDate(selected, hourToNumber(d.fromISO(fromDateTime).toFormat("HH:mm"))) : selected;
      let defaultToDateTime = d.fromISO(toDateTime);
      let nextAvailableDay = _fromDateTime.plus({ days: 1 });
      if (maxDateTime && d.fromISO(maxDateTime) <= nextAvailableDay) {
        nextAvailableDay = _fromDateTime;
      }
      if (!defaultToDateTime.isValid || defaultToDateTime < nextAvailableDay) {
        const disabledDates = getDisabledDates();
        while (disabledDates.includes(nextAvailableDay.weekday) || disabledDates.includes(nextAvailableDay.toFormat("dd/MM/yyyy"))) {
          nextAvailableDay = nextAvailableDay.plus({ days: 1 });
        }
        defaultToDateTime = nextAvailableDay;
      }
      setDateTime(_fromDateTime, defaultToDateTime, manualSelection);
      if (getDisabledDates() && getDisabledDates().length && toDateChange) {
        toDateChange(false);
      }
    }
  };

  const handleFromTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let _fromDateTime = setTimeOnDate(d.fromISO(fromDateTime), hourToNumber(event.target.value));
    let _toDateTime = d.fromISO(toDateTime)
    setDateTime(_fromDateTime, _toDateTime, true);
  };

  const handleToDateChange = (value: Date) => {
    const selectionD = d.fromJSDate(value)
    if (selectionD.isValid) {
      let _fromDateTime = fromDateTime ? d.fromISO(fromDateTime) : minDateTime ? d.fromISO(minDateTime) : d.now().startOf("day");
      const _toDateTime = toDateTime ? setTimeOnDate(selectionD, hourToNumber(d.fromISO(toDateTime).toFormat("HH:mm"))) : selectionD;
      setDateTime(_fromDateTime, _toDateTime, manualSelection);
    }
    if (toDateChange) {
      toDateChange(true);
    }
  };

  const handleToTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(fromDateTime, setTimeOnDate(d.fromISO(toDateTime), hourToNumber(event.target.value)).toUTC().toISO());
  };

  const getValue = (value: string) => {
    if (d.fromISO(value).isValid) {
      return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
    };
  }

  const minDate = getLocalizedDateFormat(country, minDateTime || "", DATE_TYPE.CONDENSED);
  const maxDate = getLocalizedDateFormat(country, maxDateTime || "", DATE_TYPE.CONDENSED);

  function getHolidays(startDate: d, endDate: d) {
    const datesArray = [];
    let dateIterator = startDate;
    while (dateIterator < endDate) {
      datesArray.push(dateIterator.toFormat("dd/MM/yyyy"));
      dateIterator = dateIterator.plus({ days: 1 });
    }
    datesArray.push(endDate.toFormat("dd/MM/yyyy"));
    return datesArray;
  }

  const getDisabledDates = () => {
    let disabledDatesArray: (number | string)[] = []
    if (userState && userState.currentBranch?.operatingHours && userState && userState.currentBranch.operatingHours.length) {
      for (const opHr of userState && userState.currentBranch.operatingHours) {
        if (opHr.closed) {
          disabledDatesArray.push(opHr.dayIndex)
        }
      }
    }
    if (userState.currentBranch && userState.currentBranch.holidays && userState.currentBranch.holidays.length) {
      for (const holiday of userState.currentBranch.holidays) {
        const dates = getHolidays(d.fromFormat(holiday.startDate, "dd/MM/yyyy"), d.fromFormat(holiday.endDate, "dd/MM/yyyy"));
        disabledDatesArray = [...disabledDatesArray, ...dates];
      }
    }
    return disabledDatesArray
  }
  return (
    <>
      <Grid item xs={gridSpan || 2} sm={gridSpanSm ? gridSpanSm : (gridSpan || 2)} md={gridSpanMd ? gridSpanMd : (gridSpan || 2)} lg={gridSpanLg ? gridSpanLg : (gridSpan || 2)}>
        <FlatPickerBar
          handleDateChange={handleFromDateChange}
          label={labels[0]}
          required={props.required}
          placeholderValue={"Select Date.."}
          value={getValue(fromDateTime)}
          minDate={minDateTime && minDate}
          maxDate={maxDateTime && maxDate}
          enableTime={false}
          disabledDates={getDisabledDates()}
          type={props.type ? props.type : "date-time-picker"}
          country={country}
        />
      </Grid>
      <Grid item xs={gridSpan || 2} sm={gridSpanSm ? gridSpanSm : (gridSpan || 2)} md={gridSpanMd ? gridSpanMd : (gridSpan || 2)} lg={gridSpanLg ? gridSpanLg : (gridSpan || 2)}>
        <InputField
          select
          required
          label={labels[1]}
          disabled={!props.fromDateTime}
          value={props.fromDateTime ? d.fromISO(fromDateTime).toFormat("HH:mm") : "08:00"}
          InputProps={{
            onChange: handleFromTimeChange
          }}
          fullWidth
        >
          {getTimesArray(timeRange.current.fromTime).map((hour, index) => {
            return (
              <MenuItem value={hour}>
                {d.fromFormat(hour, "HH:mm").toFormat("hh:mm a")}
              </MenuItem>
            );
          })}
        </InputField>
      </Grid>
      <Grid item xs={gridSpan || 2} sm={gridSpanSm ? gridSpanSm : (gridSpan || 2)} md={gridSpanMd ? gridSpanMd : (gridSpan || 2)} lg={gridSpanLg ? gridSpanLg : (gridSpan || 2)}>
        <FlatPickerBar
          handleDateChange={handleToDateChange}
          label={labels[2]}
          placeholderValue={"Select Date.."}
          value={getValue(props.toDateTime)}
          required={props.required}
          minDate={(props.fromDateTime ? getLocalizedDateFormat(country, props.fromDateTime, DATE_TYPE.CONDENSED) : minDateTime && minDate)}
          maxDate={maxDateTime && maxDate}
          enableTime={false}
          disabledDates={getDisabledDates()}
          type={props.type ? props.type : "date-time-picker"}
          disabled={props.disableEndDate}
          country={country}
        />
      </Grid>
      <Grid item xs={gridSpan || 2} sm={gridSpanSm ? gridSpanSm : (gridSpan || 2)} md={gridSpanMd ? gridSpanMd : (gridSpan || 2)} lg={gridSpanLg ? gridSpanLg : (gridSpan || 2)}>
        <InputField
          required
          select
          label={labels[3]}
          value={props.toDateTime ? d.fromISO(props.toDateTime).toFormat("HH:mm") : "08:00"}
          InputProps={{
            onChange: handleToTimeChange
          }}
          disabled={!props.toDateTime || props.disableEndDate}
          fullWidth
        >
          {getTimesArray(timeRange.current.toTime).map((hour, index) => {
            return (
              <MenuItem value={hour}>
                {d.fromFormat(hour, "HH:mm").toFormat("hh:mm a")}
              </MenuItem>
            );
          })}
        </InputField>
      </Grid>
    </>
  );
};
