import { gql } from "@apollo/client";

export const GET_JOBS = gql`
  query userJobs($limit: Int!, $offset: Int!) {
    userJobs(limit: $limit, offset: $offset) {
      id			
      subCategory
      jobDate
      status
      dateCreated
      description
      assignedTo 
      {
        id
        firstName
        lastName
      }      
      otherTaskItems
      vehicle {
        licencePlate
        id
      }
      booking {
        id
        referenceNumber          
      }
    }
  }
`;

export const SEARCH_USER_JOBS = gql`
  query searchUserJobs($q: String!) {
    searchUserJobs(q: $q) {
      id			
      subCategory
      jobDate
      status
      dateCreated
      description
      assignedTo 
      {
        id
        firstName
        lastName
      }      
      otherTaskItems
      vehicle {
        licencePlate
        id
      }
      booking {
        id
        referenceNumber          
      }
    }
  }
`;

export const GET_JOB = gql`
  query userJobById($id : String!) {
    userJobById(id: $id) {
      id
      subCategory
      jobDate
      status      
      description
      assignedTo {
        id       
      }
      actionsLinked 
      {
        id
      }
      otherTaskItems
      notes {
        note
        createdAt        
        createdBy {
          id
          firstName
          lastName
        }
      }
      vehicle {
        id
        licencePlate
      }
      booking {
        id
        referenceNumber
      }
    }

  }
`;


export const GET_USERS_IN_BRANCH = gql`
  query getUsersInBranch {
    getUsersInBranch {
      id
      firstName
      lastName
    }
  }
`

export const GET_ACTION_BY_ID = gql`
  query getActionById($id: String!) {
    getActionById(id: $id) {
      id
      category
      subCategory
      status
      dateCreated
      actionDate
      dateCompleted
      contentId
      description
    }
  }
`

export const GET_JOB_COUNT = gql`
  {
    jobCount
  }
`

export const GET_ASSIGNED_USER_JOBS = gql`
  query userJobDashboardData {
    userJobDashboardData {
      id			
      subCategory
      jobDate
      status
      dateCreated
      description
      assignedTo 
      {
        id
        firstName
        lastName
      }      
      otherTaskItems
      vehicle {
        licencePlate
        id
      }
    }
  }
`