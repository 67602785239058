import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { CHECK_BOOKING_CONFIRMATION_ELIGIBILITY } from "../../../../../graphql/bookings/checkBookingConfirmationEligibility";
import { CHECK_FOR_TBA_WARNINGS } from "../../../../../graphql/bookings/CheckForTbaWarnings";
import { CREATE_PAYMENT } from "../../../../../graphql/invoices/createPaymentMutation";
import {
  IInvoice,
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  PaymentGateway,
  formatGraphQLErrorMessage
} from "../../../../common/utils";
import { ICreditNotesToAdjust } from "./PaymentView";

interface IProps {
  bookingId: string;
  invoice: string;
  businessCustomer?: string;
  amount: number;
  creditAmount: number;
  longTermBooking?: boolean;
  redirectToInvoice?: boolean;
  invoiceData?: IInvoice;
  closeDialog?(): void;
  collectDeposit?: boolean;
  depositAmount?: number;
  tba?: boolean;
  creditNotesToAdjust: ICreditNotesToAdjust[];
  validAmount?: number;
  isConsolidated?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    basicButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      width: "calc(100vw - 90px)"
    }
  })
);

const PayByLink: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [amount, setAmount] = useState<number>(props.amount);
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("fullAmount");
  const [tbaWarning, setTbaWarning] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const {
    locale,
    currency,
    payByLinkEnabled,
    stripeAccountId,
    convergeCredentials
  } = userState.currentOrganisation;
  const [minimumPayableAmount, setMinimumPayableAmount] = useState<number>(0);
  const [paymentGateway, setPaymentGateway] = useState<string>(
    PaymentGateway.STRIPE
  );

  const [createPayment, { loading: sessionLoading }] = useMutation(
    CREATE_PAYMENT,
    {
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      },
      onCompleted: async (data) => {
        if (
          data?.createPayment?.success &&
          (data.createPayment.payment?.stripePaymentLinkUrl ||
            data.createPayment.payment.convergePaymentLinkUrl)
        ) {
          if (props.redirectToInvoice && props.closeDialog) {
            props.closeDialog();
            navigate(`/update-billing?invoice=${props.invoice}`);
          } else {
            navigate(`/view-booking?booking=${props.bookingId}`);
          }
        }
      }
    }
  );

  const [checkForTbaWarnings] = useLazyQuery(CHECK_FOR_TBA_WARNINGS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      navigate(`/update-booking?booking=${props.bookingId}&step=3`);
    },
    onCompleted: (data) => {
      if (data.checkForTbaWarnings) {
        setTbaWarning(true);
      } else {
        if (paymentInput) {
          createPayment({
            variables: {
              payment: {
                ...paymentInput,
                booking: props.bookingId
              },
              collectDeposit: props.collectDeposit || false,
              creditNotesToAdjust: props.creditNotesToAdjust
            }
          });
        }
      }
    }
  });

  const [checkBookingConfirmationEligibility] = useLazyQuery(
    CHECK_BOOKING_CONFIRMATION_ELIGIBILITY,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        navigate(`/update-booking?booking=${props.bookingId}&step=3`);
      },
      onCompleted: () => {
        if (paymentInput) {
          if (props.tba) {
            checkForTbaWarnings({
              variables: {
                bookingId: props.bookingId
              }
            });
          } else {
            createPayment({
              variables: {
                payment: {
                  ...paymentInput,
                  booking: props.bookingId
                },
                collectDeposit: props.collectDeposit || false,
                creditNotesToAdjust: props.creditNotesToAdjust
              }
            });
          }
        }
      }
    }
  );

  useEffect(() => {
    if (props.collectDeposit && props.depositAmount) {
      setMinimumPayableAmount(props.depositAmount);
    }
  }, [props.collectDeposit, props.depositAmount]);

  useEffect(() => {
    setAmount(props.amount);
  }, [props.amount]);

  const handleSubmit = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: amount,
      booking: props.bookingId || "",
      businessCustomer: props.businessCustomer,
      currency,
      description: PaymentMode.PAY_BY_LINK,
      expireBy: "",
      invoice: props.invoice,
      paymentMode: PaymentMode.PAY_BY_LINK,
      paymentType: "INWARD",
      paymentGateway,
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/update-booking?booking=${props.bookingId}&step=3`
    };
    if (props.redirectToInvoice) {
      payment.successUrl = `${window.location.protocol}//${window.location.host}/update-billing?invoice=${props.invoice}&status=success`;
      payment.cancelUrl = `${window.location.protocol}//${window.location.host}/update-billing?invoice=${props.invoice}&status=failure`;
    }
    setPaymentInput(payment);
    if (!props.redirectToInvoice) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: props.bookingId
        }
      });
    } else {
      createPayment({
        variables: {
          payment: {
            ...payment,
            booking: props.bookingId || ""
          },
          creditNotesToAdjust: props.creditNotesToAdjust,
          isConsolidated: props.isConsolidated
        }
      });
    }
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format((props.amount ? props.amount : props.validAmount || 0) / 100)}`;

  const AMOUNT_TOO_LOW = `Amount must be more than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(minimumPayableAmount / 100)}`;

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(minimumPayableAmount - 1, AMOUNT_TOO_LOW)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!payByLinkEnabled ? (
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable Pay By Link in Payment and
            Integration Settings.
          </Typography>
        </Grid>
      ) : (
        <Grid item container spacing={1}>
          <Grid item container>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  row
                  name="paymentGateway"
                  value={paymentGateway}
                  onChange={(event) => {
                    setPaymentGateway(event.target.value);
                    setAmount(props.amount);
                  }}
                >
                  <FormControlLabel
                    value={PaymentGateway.STRIPE}
                    control={<Radio />}
                    disabled={loading}
                    label={<Typography variant="body1">STRIPE</Typography>}
                  />
                  <FormControlLabel
                    value={PaymentGateway.CONVERGE}
                    control={<Radio />}
                    disabled={loading}
                    label={<Typography variant="body1">CONVERGE</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {paymentGateway === PaymentGateway.STRIPE &&
              (stripeAccountId ? (
                <Grid item container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl>
                      <RadioGroup
                        row
                        name="isPayingFull"
                        value={paymentType}
                        onChange={(event: any) => {
                          if (event.target.value === "fullAmount") {
                            setPaymentType("fullAmount");
                            setAmount(props.amount);
                          } else {
                            setPaymentType("customAmount");
                          }
                        }}
                      >
                        <FormControlLabel
                          value="fullAmount"
                          control={<Radio />}
                          disabled={loading}
                          label={
                            <Typography variant="body1">
                              Pay Total Due Amount
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="customAmount"
                          control={<Radio />}
                          disabled={loading}
                          label={
                            <Typography variant="body1">
                              Pay Custom Amount
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item container xs={12}>
                    <Formik
                      enableReinitialize
                      initialValues={{ amount: amount }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        handleSubmit();
                        setSubmitting(false);
                      }}
                    >
                      {(formikProps) => (
                        <Form>
                          <Grid item container spacing={1}>
                            <Grid item xs={6}>
                              {paymentType === "customAmount" && (
                                <Grid item xs={12}>
                                  <Field
                                    component={TextField}
                                    placeholder="Enter Amount to pay"
                                    label="Custom Amount"
                                    name={"amount"}
                                    fullWidth
                                    required
                                    InputProps={{
                                      onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setAmount(parseInt(e.target.value));
                                      },
                                      value: amount,
                                      inputComponent: FloatInput as any
                                    }}
                                    inputProps={{
                                      hasCurrencyPrefix: true,
                                      allowNegative: false
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Grid item container>
                                <Fab
                                  variant="extended"
                                  size="medium"
                                  aria-label="add"
                                  onClick={() => {
                                    formikProps.setSubmitting(false);
                                    formikProps.handleSubmit();
                                  }}
                                  disabled={sessionLoading}
                                >
                                  {sessionLoading && (
                                    <CircularProgress
                                      size={14}
                                      style={{
                                        color: "white",
                                        marginRight: "10px"
                                      }}
                                    />
                                  )}
                                  Send Payment Link
                                </Fab>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              ) : (
                <Grid container xs={12}>
                  <Typography style={{ margin: "1rem" }}>
                    Please add Stripe Account Id in organisation settings to use
                    this feature.
                  </Typography>
                </Grid>
              ))}
            {paymentGateway === PaymentGateway.CONVERGE &&
              (convergeCredentials?.merchantAccountId ? (
                <Grid item container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl>
                      <RadioGroup
                        row
                        name="isPayingFull"
                        value={paymentType}
                        onChange={(event: any) => {
                          if (event.target.value === "fullAmount") {
                            setPaymentType("fullAmount");
                            setAmount(props.amount);
                          } else {
                            setPaymentType("customAmount");
                          }
                        }}
                      >
                        <FormControlLabel
                          value="fullAmount"
                          control={<Radio />}
                          disabled={loading}
                          label={
                            <Typography variant="body1">
                              Pay Total Due Amount
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="customAmount"
                          control={<Radio />}
                          disabled={loading}
                          label={
                            <Typography variant="body1">
                              Pay Custom Amount
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item container xs={12}>
                    <Formik
                      enableReinitialize
                      initialValues={{ amount: amount }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        handleSubmit();
                        setSubmitting(false);
                      }}
                    >
                      {(formikProps) => (
                        <Form>
                          <Grid item container spacing={1}>
                            <Grid item xs={6}>
                              {paymentType === "customAmount" && (
                                <Grid item xs={12}>
                                  <Field
                                    component={TextField}
                                    placeholder="Enter Amount to pay"
                                    label="Custom Amount"
                                    name={"amount"}
                                    fullWidth
                                    required
                                    InputProps={{
                                      onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setAmount(parseInt(e.target.value));
                                      },
                                      value: amount,
                                      inputComponent: FloatInput as any
                                    }}
                                    inputProps={{
                                      hasCurrencyPrefix: true,
                                      allowNegative: false
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Grid item container>
                                <Fab
                                  variant="extended"
                                  size="medium"
                                  aria-label="add"
                                  onClick={() => {
                                    formikProps.setSubmitting(false);
                                    formikProps.handleSubmit();
                                  }}
                                  disabled={sessionLoading}
                                >
                                  {sessionLoading && (
                                    <CircularProgress
                                      size={14}
                                      style={{
                                        color: "white",
                                        marginRight: "10px"
                                      }}
                                    />
                                  )}
                                  Send Payment Link
                                </Fab>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              ) : (
                <Grid container xs={12}>
                  <Typography style={{ margin: "1rem" }}>
                    Please add Converge Credentials in Payments & Integrations
                    settings to use this feature.
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
      )}
      {tbaWarning && (
        <ConfirmationDialog
          isOpen={tbaWarning}
          onCancel={() => {
            setTbaWarning(false);
            navigate(`/update-booking?booking=${props.bookingId}&step=3`);
          }}
          onConfirm={() => {
            if (paymentInput) {
              setTbaWarning(false);
              createPayment({
                variables: {
                  payment: {
                    ...paymentInput,
                    booking: props.bookingId
                  },
                  collectDeposit: props.collectDeposit || false,
                  creditNotesToAdjust: props.creditNotesToAdjust
                }
              });
            }
          }}
          title=""
          description={
            "The vehicles in this booking have some future bookings. Do you wish to continue to with this TBA booking?"
          }
        />
      )}
    </Grid>
  );
};

export default PayByLink;
