import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import {
  CircularProgress,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DateTime as d } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { GET_ADDITIONAL_DRIVER } from "../../../../../graphql/addOns/getAdditionalDriverQuery";
import {
  ADD_DRIVERS_TO_BOOKING,
  REMOVE_DRIVERS_FROM_BOOKING
} from "../../../../../graphql/bookings/addDriversToBookingMutation";
import { GET_BUSINESS_CUSTOMER } from "../../../../../graphql/businessCustomers/businessCustomer";
import { GET_CUSTOMER } from "../../../../../graphql/customers/getCustomerQuery";
import { DefaultAddons } from "../../../../../reducers/addonType/types";
import {
  BookingStatus,
  IBooking,
  ICustomerDetails
} from "../../../../../reducers/bookings/types";
import { initialState } from "../../../../../reducers/customer/const";
import { IBusinessCustomer } from "../../../../../reducers/customer/types";
import { IAddress, IDriverRow } from "../../../../../reducers/user/types";
import { IAppState } from "../../../../../store";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  getUploadedImageByKey
} from "../../../../common/utils";
import { SearchForm } from "../../NewBooking/PersonalDetails/CustomerView/SearchForm";
import { CustomerType, isValidUrl } from "../../utils";
import AddDrivers from "./AddDrivers";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ADD_DRIVERS } from "../../../../../graphql/customers/createCustomerMutation";
import { captureErrorException } from "../../../../../utils/sentry";


const useStyles = makeStyles(() =>
  createStyles({
    tableCellBorder: {
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    summaryLeftMargin: {
      marginLeft: 5
    },
    tableCellPadding: {
      padding: 8
    },
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    }
  })
);
interface IDriverInformationProps {
  booking: IBooking;
  getUpdatedRentalAgreementUrl: (newRentalAgreementUrl: string) => void;
}

const remainder = 15 - (d.now().minute % 15);
const minDateTime = d
  .now()
  .plus({ minutes: remainder })
  .toJSDate();

minDateTime.setMilliseconds(0);
minDateTime.setSeconds(0);

export const DriversInformation: React.FC<IDriverInformationProps> = (
  props
) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const addDriverRef = useRef<any>(null);
  const [values, setValues] = useState<ICustomerDetails>(initialState.customer);
  const [booking, setBooking] = useState<IBooking>();
  const [addDriver, setAddDriver] = useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = useState<IDriverRow | null>(null);

  const [businessCustomer, setBusinessCustomer] = useState<
    IBusinessCustomer | undefined
  >();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  const [isConfirmDialogOpen, setIsConfirmationDialogeOpen] = useState<boolean>(
    false
  );
  const [removedDriverId, setRemovedDriverId] = useState<string>();
  const [removeInProgress, setRemoveInProgress] = useState<boolean>(false);
  const [isAddDriverClicked, setIsAddDriverClicked] = useState<boolean>(false);
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [isNewDriver, setIsNewDriver] = useState<boolean>(false);
  const [
    existingDriverAddInProgress,
    setExistingDriverAddInProgress
  ] = useState<boolean>(false);
  const [formatedDrivers, setFormatedDrivers] = useState<IDriverRow[]>([]);
  const [newlyAddedDrivers, setNewlyAddedDrivers] = useState<IDriverRow[]>([]);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);

  const [
    loadIndividualCustomer,
    { data: customerData, loading: loadingIndividualCustomer }
  ] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "network-only"
  });
  const [loadAdditionDriver, { data: additionalDriverData }] = useLazyQuery(
    GET_ADDITIONAL_DRIVER,
    {
      fetchPolicy: "network-only"
    }
  );
  const [
    loadBusinessCustomer,
    { data: businessCustomerData, loading: loadingBusinessCustomer }
  ] = useLazyQuery(GET_BUSINESS_CUSTOMER, {
    fetchPolicy: "network-only"
  });
  const [addDrivers, { loading: addDriversLoading }] = useMutation(ADD_DRIVERS, {
    onCompleted: (data) => {
      let newDriversId: string[] = [];
      if (data && data.addDrivers && data.addDrivers.length > 0) {
        data.addDrivers.forEach((driver: IDriverRow) => {
          if (driver && driver.id) {
            newDriversId.push(driver.id);
          }
        });
        if (values.approvedDrivers) {
          setValues({
            ...values,
            approvedDrivers: data.addDrivers
          })
        }
        setUpdateInProgress(true);
        setNewlyAddedDrivers([])
        addDriversToBooking({
          variables: {
            bookingId: props.booking.id,
            drivers: newDriversId
          }
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [addDriversToBooking, { loading: addDriversToBookingLoading }] = useMutation(ADD_DRIVERS_TO_BOOKING, {
    onCompleted: (data) => {
      if (
        data &&
        data.addDriversToBooking &&
        data.addDriversToBooking.approvedDrivers
      ) {
        if (
          props.booking &&
          props.booking.customerType === CustomerType.INDIVIDUAL
        ) {
          _setDrivers([
            ...data.addDriversToBooking.approvedDrivers,
            {
              id: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: {
                phone: values.phoneNumber.phone,
                country: values.phoneNumber.country
              },
              license: {
                authority: values.license?.authority,
                dateOfExpiry: values.license?.dateOfExpiry,
                licenseNumber: values.license?.licenseNumber,
                images: values.license?.images
              }
            }
          ]);
        } else {
          _setDrivers(data.addDriversToBooking.approvedDrivers);
        }
      }
      snackbar({
        message: "Drivers saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setIsAddDriverClicked(false);
      resetRow();
      setUpdateInProgress(false);
      setExistingDriverAddInProgress(false);
      props.getUpdatedRentalAgreementUrl(
        data.addDriversToBooking.rentalAgreementUrl
      );
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setUpdateInProgress(false);
      setExistingDriverAddInProgress(false);
    }
  });

  const [removeDriverFromBooking, { loading: removeDriverFromBookingLoading }] = useMutation(REMOVE_DRIVERS_FROM_BOOKING, {
    onCompleted: (data) => {
      if (
        data &&
        data.removeDriverFromBooking &&
        data.removeDriverFromBooking.approvedDrivers
      ) {
        if (
          props.booking &&
          props.booking.customerType === CustomerType.INDIVIDUAL
        ) {
          setIsAddDriverClicked(false);
          resetRow();
          _setDrivers([
            ...data.removeDriverFromBooking.approvedDrivers,
            {
              id: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: {
                phone: values.phoneNumber.phone,
                country: values.phoneNumber.country
              },
              license: {
                authority: values.license?.authority,
                dateOfExpiry: values.license?.dateOfExpiry,
                licenseNumber: values.license?.licenseNumber,
                images: values.license?.images
              }
            }
          ]);
        } else {
          _setDrivers([...data.removeDriverFromBooking.approvedDrivers]);
          resetRow();
        }
      }
      snackbar({
        message: "Drivers removed successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setIsConfirmationDialogeOpen(false);
      setRemoveInProgress(false);
      props.getUpdatedRentalAgreementUrl(
        data.removeDriverFromBooking.rentalAgreementUrl
      );
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setIsConfirmationDialogeOpen(false);
      setRemoveInProgress(false);
    }
  });
  useEffect(() => {
    loadAdditionDriver();
  }, []);
  useEffect(() => {
    if (additionalDriverData && additionalDriverData.addonTypes) {
      const addon = additionalDriverData.addonTypes.find(
        (item: any) => item.name === DefaultAddons.ADDITIONAL_DRIVER
      );
      if (addon && addon.isActivated) {
        setIsNewDriver(true);
      }
    }
  }, [additionalDriverData]);

  useEffect(() => {
    if (props.booking.businessCustomer) {
      loadBusinessCustomer({
        variables: {
          businessCustomerId: props.booking.businessCustomer.id
        }
      });
    }
    if (props.booking && props.booking.approvedDrivers) {
      _setDrivers(props.booking.approvedDrivers);
    }
  }, [props.booking]);

  useEffect(() => {
    if (businessCustomerData && businessCustomerData.businessCustomer) {
      setBusinessCustomer(businessCustomerData.businessCustomer);
    }
  }, [businessCustomerData]);

  useEffect(() => {
    if (props.booking.customer && customerData) {
      const data = customerData.customer;
      if (data) {
        setValues(data);
      }
      if (
        props.booking &&
        props.booking.customerType === CustomerType.INDIVIDUAL &&
        data
      ) {
        _setDrivers([
          ...props.booking.approvedDrivers,
          {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: {
              phone: data.phoneNumber.phone,
              country: data.phoneNumber.country
            },
            license: {
              authority: data.license?.authority,
              dateOfExpiry: data.license?.dateOfExpiry,
              licenseNumber: data.license?.licenseNumber,
              images: data.license?.images
            }
          }
        ]);
      }
    }
    if (props.booking && props.booking.customerType === CustomerType.BUSINESS) {
      _setDrivers(props.booking.approvedDrivers);
    }
  }, [props.booking, customerData]);

  useEffect(() => {
    setBooking(props.booking);
  }, [props.booking, values]);

  useEffect(() => {
    setBooking(booking);
    if (
      props.booking.customerType === CustomerType.INDIVIDUAL &&
      props.booking?.customer &&
      values.id === ""
    ) {
      loadIndividualCustomer({
        variables: {
          customerId: props.booking?.customer?.id
        }
      });
    }
  }, [props.booking]);

  const handleAddRows = () => {
    if (addDriverRef && addDriverRef.current) {
      addDriverRef.current.addRows();
    }
  };
  const resetRow = () => {
    if (addDriverRef && addDriverRef.current) {
      addDriverRef.current.resetAddRows();
    }
  };
  const removeDriver = () => {
    setRemoveInProgress(true);
    if (removedDriverId) {
      removeDriverFromBooking({
        variables: {
          bookingId: props.booking.id,
          driverId: removedDriverId
        }
      });
    }
  };

  const _setDrivers = async (drivers: IDriverRow[]) => {
    const promises: any[] = [];

    for (let index = 0; index < drivers.length; index++) {
      const driver = drivers[index];

      try {
        if (driver && driver.license && driver.license.images && driver.license.images.length) {
          const images = driver.license.images;

          for (let idx = 0; idx < images.length; idx++) {
            const image = images[idx];

            try {
              if (typeof image === "string") {
                const imageData = isValidUrl(image) ? image : await getUploadedImageByKey(image);
                let driversImageCopy = [...drivers[index].license.images];
                driversImageCopy[idx] = imageData;
                drivers = [
                  ...drivers.slice(0, index),
                  {
                    ...drivers[index],
                    license: {
                      ...drivers[index].license,
                      images: driversImageCopy
                    }
                  },
                  ...drivers.slice(index + 1)
                ];
              }
            } catch (error: any) {
              throw error;
            }
          }
        }
      } catch (error: any) {
        throw error;
      }
    };
    Promise.all(promises)
      .then(() => {
        setFormatedDrivers(drivers);
      })
      .catch((error) => {
        captureErrorException(error)
        throw error;
      });
  };

  const addExistingDriver = (data: IDriverRow) => {
    const existingDriver = formatedDrivers.find(
      (driver: IDriverRow) => driver.id === data.id
    );
    if (existingDriver) {
      return snackbar({
        message: "Selected driver already added.",
        variant: SnackBarVariant.ERROR
      });
    }
    if (data && data.id) {
      setExistingDriverAddInProgress(true);
      addExistingDriverHandler(data);
    }
  };

  const addExistingDriverHandler = (data: IDriverRow) => {
    setUpdateInProgress(true);
    addDriversToBooking({
      variables: {
        bookingId: props.booking.id,
        drivers: [data?.id]
      }
    });
  };

  const handleSubmit = (newDriver: IDriverRow) => {
    const existingDrivers = values.id ? values.approvedDrivers : businessCustomer?.approvedDrivers;
    if (existingDrivers?.length) {
      const isDriverExist = existingDrivers?.some((val: any) => val.email === newDriver.email)
      if (isDriverExist) {
        return snackbar({
          message: `Driver with email ${newDriver.email} already exists. Please select the driver from the 'Search for driver' dropdown.`,
          variant: SnackBarVariant.ERROR
        });
      }
    }
    if (props.booking.customerType === CustomerType.BUSINESS) {
      const driverIndex = newlyAddedDrivers.findIndex(obj => obj.email === newDriver.email)
      if (driverIndex !== -1) {
        newlyAddedDrivers[driverIndex] = newDriver
      } else {
        setNewlyAddedDrivers((prevDrivers) => [...prevDrivers, newDriver]);
      }
    } else {
      newDriver.verificationMethod = "MANUAL";
      const driverIndex = newlyAddedDrivers.findIndex(obj => obj.email === selectedDriver?.email)
      if (driverIndex !== -1) {
        newlyAddedDrivers[driverIndex] = newDriver
      } else {
        setNewlyAddedDrivers((prevDrivers) => [...prevDrivers, newDriver]);
      }
    }
    setSelectedDriver(null)
    setEnableEdit(false)
  };

  const handleEditDriver = (driver: IDriverRow) => {
    const { verificationMethod, ...rest } = driver
    setEnableEdit(true)
    setSelectedDriver(rest);
  };

  const handleRemove = (email: string) => {
    const updatedDrivers = newlyAddedDrivers.filter((driver) => driver.email !== email)
    setNewlyAddedDrivers(updatedDrivers)
  }

  const addAllDrivers = () => {
    if (newlyAddedDrivers.length) {
      setAddDriver(false)
      addDrivers({
        variables: {
          customerId: values.id ? values.id : props.booking.businessCustomer?.id,
          drivers: newlyAddedDrivers
        }
      });
    }
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={10}>
        <Typography variant="h3">Driver Information</Typography>
      </Grid>
      {isNewDriver &&
        props.booking &&
        (props.booking.status === BookingStatus.CONFIRMED ||
          props.booking.status === BookingStatus.IN_PROGRESS) && (
          <Grid container item xs={2} justifyContent="flex-end">
            <Fab
              variant="extended"
              size="medium"
              aria-label="Add New"
              onClick={() => setIsAddDriverClicked(true)}
              disabled={isAddDriverClicked}
            >
              Add New
            </Fab>
          </Grid>
        )}
      {formatedDrivers && formatedDrivers.length > 0 ? (
        <>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <TableContainer>
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography>First Name</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography>Last Name</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography>Email</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography>Licence Number</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography>Expiry Date</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography>Authority</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography>Phone Number</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography>Licence Image</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellPadding}
                    >
                      <Typography></Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formatedDrivers &&
                    formatedDrivers.length &&
                    formatedDrivers.map((item: IDriverRow, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCellPadding}>
                            {item.firstName}
                          </TableCell>
                          <TableCell className={classes.tableCellPadding}>
                            {item.lastName}
                          </TableCell>
                          <TableCell className={classes.tableCellPadding}>
                            <Typography variant="body1">
                              <a
                                onClick={() =>
                                  navigate(
                                    `/customer-details?customer=${item.id
                                    }&editable=${true}`
                                  )
                                }
                                style={{
                                  color: "rgb(24, 117, 210)",
                                  textDecoration: "none",
                                  cursor: "pointer"
                                }}
                              >
                                {item.email}
                              </a>
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableCellPadding}>
                            {item.license && item.license.licenseNumber
                              ? item.license.licenseNumber
                              : ""}
                          </TableCell>
                          <TableCell className={classes.tableCellPadding}>
                            {item.license &&
                              item.license.dateOfExpiry &&
                              item.license.dateOfExpiry
                              ? getLocalizedDateFormat(
                                country,
                                item.license.dateOfExpiry,
                                DATE_TYPE.CONDENSED
                              )
                              : ""}
                          </TableCell>
                          <TableCell className={classes.tableCellPadding}>
                            {item.license && item.license.authority
                              ? item.license.authority
                              : ""}
                          </TableCell>
                          <TableCell className={classes.tableCellPadding}>
                            {item.phoneNumber && item.phoneNumber.phone
                              ? item.phoneNumber.phone
                              : ""}
                          </TableCell>
                          <TableCell className={classes.tableCellPadding}>
                            {item.license &&
                              item.license.images &&
                              item.license.images.length > 0 &&
                              item.license.images.map(
                                (image: any, index: number) => {
                                  return image && (
                                    <a
                                      href={image}
                                      target="_blank"
                                      key={index}
                                    >
                                      <img
                                        alt="Driver's Licence Images"
                                        src={image}
                                        width="40"
                                        height="30"
                                      />
                                    </a>
                                  )
                                }
                              )}
                          </TableCell>
                          <TableCell className={classes.tableCellPadding}>
                            <Grid item container xs={1}>
                              <Typography
                                variant={"subtitle1"}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setIsConfirmationDialogeOpen(true);
                                  if (item && item.id) {
                                    setRemovedDriverId(item.id);
                                  }
                                }}
                              >
                                <button
                                  style={{
                                    border: "none",
                                    background: "none",
                                    padding: 0,
                                    fontSize: "16px"
                                  }}
                                  disabled={values && values.id === item.id}
                                >
                                  X
                                </button>
                              </Typography>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {isConfirmDialogOpen && (
              <ConfirmationDialog
                isOpen={isConfirmDialogOpen}
                onCancel={() => setIsConfirmationDialogeOpen(false)}
                onConfirm={removeDriver}
                description={
                  `Are you sure you want to remove this driver from the ${getLocalizedBookingSyntex(country).toLowerCase()}?`
                }
                title={""}
                confirmText={"Yes"}
                cancelText={"No"}
                isInProgress={removeInProgress}
                isLoading={removeDriverFromBookingLoading}
              />
            )}
          </Grid>
        </>
      ) : (
        <React.Fragment>
          {props.booking.customerType === CustomerType.INDIVIDUAL ? (
            <Grid container xs={12}>
              <CircularProgress />
            </Grid>
          ) : (
            <span> No additional drivers found! </span>
          )}
        </React.Fragment>
      )}

      <Hidden smDown>
        {isAddDriverClicked && (
          <React.Fragment>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography variant="h6">Add Driver</Typography>
            </Grid>
            {(businessCustomer && loadingBusinessCustomer) ||
              (values && loadingIndividualCustomer) ? (
              <CircularProgress />
            ) : (
              <Grid item xs={4} style={{ marginTop: 16 }}>
                {businessCustomer && businessCustomer.approvedDrivers ? (
                  <React.Fragment>
                    <SearchForm
                      updateData={addExistingDriver}
                      dataOptions={businessCustomer.approvedDrivers}
                      isDriver={true}
                      disabled={updateInProgress}
                    />
                  </React.Fragment>
                ) : (
                  values &&
                  values.approvedDrivers && (
                    <React.Fragment>
                      <SearchForm
                        updateData={addExistingDriver}
                        dataOptions={values.approvedDrivers}
                        isDriver={true}
                        disabled={updateInProgress}
                      />
                    </React.Fragment>
                  )
                )}
              </Grid>
            )}
            <Grid item xs={1} style={{ marginTop: 22 }}>
              <Typography variant={"subtitle1"} style={{ cursor: "pointer" }}>
                {existingDriverAddInProgress && (
                  <CircularProgress
                    size={16}
                    style={{ color: "white", marginRight: "10px" }}
                  />
                )}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={7}
              style={{ marginTop: 22 }}
              justifyContent="flex-end"
            >
              {values && values.id &&
                <Typography
                  variant={"subtitle1"}
                  onClick={() => setAddDriver(true)}
                  style={{ cursor: "pointer", textAlign: "end" }}
                >
                  + Add New Driver
                </Typography>
              }
            </Grid>
          </React.Fragment>
        )}
        {addDriver && (
          <Grid item container xs={12}>
            <AddDrivers
              saveDriverLoading={false}
              handleSubmit={handleSubmit}
              openForm={() => setAddDriver(false)}
              selectedDriver={selectedDriver}
            />
          </Grid>
        )}
        {newlyAddedDrivers.length ? (<>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h4">Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4">Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4">Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newlyAddedDrivers.map((driver: IDriverRow) => (
                  <TableRow key={driver.id}>
                    <TableCell>{`${driver.firstName} ${driver.lastName}`}</TableCell>
                    <TableCell>{driver.email}</TableCell>
                    <TableCell>
                      {!driver.id && (
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={() => handleEditDriver(driver)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                      <IconButton
                        color="secondary"
                        aria-label="delete"
                        disabled={enableEdit}
                        onClick={() => handleRemove(driver.email)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item container xs={12}>
            <Fab
              variant="extended"
              size="medium"
              style={{ margin: 10 }}
              onClick={() => {
                addAllDrivers()
              }}
            >
              {(addDriversToBookingLoading || addDriversLoading) && <CircularProgress size={16} style={{ color: "white", marginRight: "10px" }} />}
              Save Drivers
            </Fab>
          </Grid>
        </>) : <></>}
      </Hidden>
    </Grid>
  );
};
