import { gql } from "@apollo/client";

export const CREATE_PAYMENT = gql`
  mutation createPayment($payment: CreatePaymentInput!, $saveDetails: Boolean, $collectDeposit: Boolean, $creditNotesToAdjust: [AdjustCreditNoteInput], $isConsolidated: Boolean) {
    createPayment(payment: $payment, saveDetails: $saveDetails, collectDeposit: $collectDeposit, creditNotesToAdjust: $creditNotesToAdjust, isConsolidated: $isConsolidated) {
      success
      message
      payment {
        id
        stripeCheckoutSessionId
        stripePaymentLinkUrl
        convergePaymentSessionUrl
        convergePaymentLinkUrl
      }
    }
  }
`;
